import { Typography } from "@mui/material";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
import Grid from "../../../layout/grid/Grid";
import style from "./Price.module.css";
import { useRef } from "react";

function Rules() {

    const goKart = useRef(null)
    const laserTag = useRef(null)
    const escapeRoom = useRef(null)
    const vr = useRef(null)
    // const sakica = useRef(null)

    const color = [
        "#f07379",
        "#AF46FA",
        "#2D8CFF",
        "#faa000",
        "#321eff",
        "#2D8CFF",
        "#AF46FA",
        "#faa000",
    ];

    const rules = {
        interest: [
            {
                interest: "HyperDrive",
                ref: goKart
            },
            {
                interest: "Laser Tag",
                ref: laserTag
            },
            {
                interest: "Escape Room",
                ref: escapeRoom
            },
            {
                interest: "Virtual Reality",
                ref: vr
            },
            // {
            //     interest: "Sakica",
            //     ref: sakica
            // },
        ],
        rules: [
            {
                title: "HyperDrive/ Rregullat",
                reference: goKart,
                details: [
                    {
                        title: "Firmos Dokumentin",
                        desc: [
                            "Të gjithë klientat duhet të nëshkruajnë dokumentin që pranon kushtet & rreziqet e lojës dhe mban përgjegjësi mbi vete.",
                        ],

                    },
                    {
                        title: "Mosha",
                        desc: [
                            "Personat nën moshën 16 vjeç nuk mund të garojnë vetë, por duhet të shoqërohen nga një person përgjegjës në GoKart-in me dy ulëse.",
                        ],
                    },
                    {
                        title: "Rezervimet",
                        desc: [
                            "Personat nën moshën +8 vjeç nuk mund të garojnë vetë, por duhet të shoqërohen nga një person përgjegjës në GoKart-in me dy ulëse.",
                        ],
                    },
                    {
                        title: "Gjërat personale",
                        desc: [
                            "Gjërat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.",
                        ],
                    },
                    {
                        title: "Paisjet elektronike dhe ushqimi",
                        desc: [
                            "Ndalohet përdorimi i telefonit gjatë vozitjes.",
                            "Ndalohet pirja e duhanit/cigareve elektronike. ",
                            " Nuk lejohet ushqim apo pije në zonën e kartingut",
                        ],
                    },
                    {
                        title: "Paisjet e Garës",
                        desc: [
                            "Veshja e balaklaves,helmetës dhe jelekut janë të detyruara deri në përfundim të garës.",
                        ],
                    },
                    {
                        title: "Vozitja dhe rregullat e pistës",
                        desc: [
                            "Mos filloni garën derisa të merrni konfirmim nga stafi ynë. Nga përplasjet e qëllimshme dhe të përsërituara, detyroheni të largoheni nga pista. Në rast të aksidentit,ngritni dorën dhe mos dilni nga GoKart..",
                        ],
                    },
                    {
                        title: "Vozitja dhe rregullat e pistës",
                        desc: [
                            "Kushtoni vëmendje udhëzimeve të marshallit (personi pëgjegjës i kompanisë) që mban flamurin. GoKart fillojnë të ngadalsohen automatikisht në fund të garës. Në pistë lejohen 12 GoKarts në të njëjtën kohë dhe sesioni i garës zgjat 8 minuta.",
                        ],
                    },
                ],
            },
            {
                title: "Laser Tag / Rregullat",
                reference: laserTag,
                details: [
                    {
                        title: "Mosha e Lejuar",
                        desc: ["Personat nën moshën 8 vjeç nuk mund të luajnë."],
                    },
                    {
                        title: "Rezervimi",
                        desc: [
                            "Seanca e rezervuar do të skadojë nëse nuk arrini para kohës së fillimit të garës.",
                        ],
                    },
                    {
                        title: "Kush nuk duhet të luaj",
                        desc: [
                            "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
                        ],
                    },
                    {
                        title: "Kush nuk duhet të luaj",
                        desc: [
                            "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
                        ],
                    },
                    {
                        title: "Gjërat personale",
                        desc: [
                            "Gjërat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.",
                        ],
                    },
                    {
                        title: "Paisjet Laset Tag",
                        desc: [
                            "Siguroheni që të keni gjithmonë jelek dhe armët e lojës kur të jeni në Laser Tag arenë. Jelekët dhe armët janë paisje që kërkojnë kujdes maksimal, kujdes të mos i dëmtoni ato.",
                        ],
                    },
                    {
                        title: "Paisjet elektronike dhe ushqimi",
                        desc: [
                            "Nuk lejohet ushqim apo pije në zonën e lojës. Ndalohet pirja e duhanit/cigareve elektronike..",
                        ],
                    },
                    {
                        title: "Rregullat e betejës",
                        desc: [
                            "Asnjëherë mos dilni nga arena pa leje tek personi përgjegjës në Laser Tag arena. Nëse ju duhet të largoheni nga zona e lojës për ndonjë arsye, nuk ndiheni mirë apo keni ndonjë urgjencë ju lutemi të lajmroni personin përgjegjës në arenë.",
                        ],
                    },
                    {
                        title: "Vozitja dhe rregullat e pistës",
                        desc: [
                            "Kushtoni vëmendje udhëzimeve të marshallit (personi pëgjegjës i kompanisë) që mban flamurin. GoKart fillojnë të ngadalsohen automatikisht në fund të garës. Në pistë lejohen 12 GoKarts në të njëjtën kohë dhe sesioni i garës zgjat 8 minuta..",
                        ],
                    },
                ],
            },
            {
                title: "Escape Rooms / Rregullat",
                reference: escapeRoom,
                details: [
                    {
                        title: "Mosha e Lejuar",
                        desc: ["Mosha minimale për të luajtur është 15+, ndërsa klientet nga mosha 10-16 vjeç duhet të jenë të shoqëruar nga një person përgjëgjës."],
                    },
                    {
                        title: "Rezervimi",
                        desc: [
                            "Seanca e rezervuar do të skadojë nëse nuk arrini para kohës së fillimit të lojës.",
                        ],
                    },
                    {
                        title: "Gjërat personale",
                        desc: [
                            "Gjerat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.",
                        ],
                    },
                    {
                        title: "Paisjet elektronike dhe ushqimi",
                        desc: [
                            "Ndalohet përdorimi i telefonit. Ndalohet pirja e duhanit/cigareve elektronike. Nuk lejohet ushqim apo pije brenda dhomës.",
                        ],
                    },
                    {
                        title: "Brenda Dhomave",
                        desc: [
                            "Nuk lejohet të kërceni apo të vraponi brenda hapësirave.",
                        ],
                    },
                    {
                        title: "Paisjet e Escape Rooms",
                        desc: [
                            "Ndalohet prekja e dekoreve dhe gjërave të cilat kanë mbishkrimin “DO NOT TOUCH’’, në rast dëmtimi gjatë lojës, klientet duhet të paguajnë dëmin e shkaktuar. Gjërat që ju përdorni gjatë lojës,sigurohuni që pa dashje të mos i merrni me vete.",
                        ],
                    },
                ],
            },
            {
                title: "Escape Rooms - Haunted Mansion",
                details: [
                    {
                        title: "Mosha e Lejuar",
                        desc: ["Mosha minimale për të luajtur është 15+."],
                    },
                    {
                        title: "Kush nuk duhet të luaj.",
                        desc: [
                            "Personat shtatzënë, personat që kanë klaustrofobi dhe personat që vuajnë nga ndonjë sëmundje e zemrës,epilepsi, apo ndonjë sëmundje tjetër nuk lejohet të luajnë.",
                        ],
                    },
                    {
                        title: "Rezervimi",
                        desc: [
                            "Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të lojës.",
                        ],
                    },
                    {
                        title: "Gjërat personale",
                        desc: [
                            "Mosha minimale për të luajtur është 15+.",
                        ],
                    },
                    {
                        title: "Brenda Dhomave",
                        desc: [
                            "Nuk lejohet të kërceni apo të vraponi brenda hapësirave.",
                        ],
                    },
                    {
                        title: "Paisjet e Escape Rooms",
                        desc: [
                            "Ndalohet prekja e dekoreve dhe gjërave të cilat kanë mbishkrimin “DO NOT TOUCH’’, në rast dëmtimi gjatë lojës, klientet duhet të paguajnë dëmin e shkaktuar. Gjërat që ju përdorni gjatë lojës,sigurohuni që pa dashje të mos i merrni me vete.",
                        ],
                    },
                ],
            },
            {
                title: "Virtual Reality / Rregullat",
                reference: vr,
                details: [
                    {
                        title: "Mosha e Lejuar",
                        desc: ["Personat nën moshën 12 vjeç nuk mund të luajnë.."],
                    },
                    {
                        title: "Kush nuk duhet të luaj.",
                        desc: [
                            "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
                        ],
                    },
                    {
                        title: "Kush nuk duhet të luaj.",
                        desc: [
                            "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
                        ],
                    },
                    {
                        title: "Rezervimi",
                        desc: [
                            "Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të lojës.",
                        ],
                    },
                    {
                        title: "Paisjet elektronike dhe ushqimi",
                        desc: [
                            "Ndalohet përdorimi i telefonit nga personi që është në lojë. Ndalohet pirja e duhanit/cigareve elektronike. Nuk lejohet ushqim apo pije në zonën e lojës.",
                        ],
                    },
                    {
                        title: "Paisjet e Hologate Arena",
                        desc: [
                            "Ndalohet prekja e paisjeve pa udhëzimin e personit përgjegjës në Hologate Arena.",
                        ],
                    },
                ],
            },
            // {
            //     title: "Sakica / Rregullat",
            //     reference: sakica,
            //     details: [
            //         {
            //             title: "Firmos dokumentin",
            //             desc: ["Të gjithë klientët duhet të nëshkruajnë dokumentin që pranon kushtet & rreziqet e lojës dhe mban përgjegjësi mbi vete."],
            //         },
            //         {
            //             title: "Mosha",
            //             desc: [
            //                 "Personat nën moshën 18 vjeç nuk mund të luajnë.",
            //             ],
            //         },
            //         {
            //             title: "Rezervimet",
            //             desc: [
            //                 "Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të lojës.",
            //             ],
            //         },
            //         {
            //             title: "Rregullat e lojës",
            //             desc: [
            //                 "Masteri iu tregon rregullat e gjuajtjes së sigurtë, në rast të thyerjes së rregullave ‘’ Duhet të lironi zonën e hedhjes.’’",
            //             ],
            //         },
            //         {
            //             title: "Kush nuk duhet të luaj",
            //             desc: [
            //                 "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
            //             ],
            //         },
            //         {
            //             title: "Kush nuk duhet të luaj",
            //             desc: [
            //                 "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
            //             ],
            //         },
            //         {
            //             title: "Veshjet dhe paisjet",
            //             desc: [
            //                 "Këpucët e mbyllura janë të detyrueshme për secilin person që hyn në zonën e hedhjes. Veshjet si kapelat, takat, shalli dhe aksesorët mund të krijojnë probleme duke kufizuar lëvizjen gjatë lojës.",
            //             ],
            //         },
            //         {
            //             title: "Rregullat e kabinës",
            //             desc: [
            //                 "Asnjëherë mos hyni në zonën e hedhjes derisa personi pëgjegjës të japë leje. Asnjëherë mos e kaloni vijën e hedhjes derisa sëpatat të jenë në objektiv ose në dysheme.",
            //             ],
            //         },
            //         {
            //             title: "Paisjet elektronike dhe ushqimi",
            //             desc: [
            //                 "Ndalohen ushqimi dhe pijet brenda zonës së hedhjes. Ndalohet pirja e duhanit/cigareve elektronike Brenda zones së hedhjes. Ndalohet përdorimi i telefonit brenda zonës së hedhjes.",
            //             ],
            //         },
            //     ],
            // },
        ],
    };

    return (
        <div className="container">
            <div className="bodyWrapper">
                <ActivitiesInterest
                    interests={rules.interest}
                />
            </div>
            <div className="bodyWrapper">
                {rules.rules.map((i, j) => {
                    return (
                        <div key={j} ref={i.reference}>
                            <h1 style={{ color: color[j] }}>{i.title}</h1>
                            <Grid no={3}>
                                {i.details.map((item, index) => (
                                    <div className={style.ruleBox} key={index}>
                                        <span style={{ color: color[j] }}>{index + 1}</span>
                                        <h1>{item.title}</h1>
                                        <Typography component={'p'} sx={{ whiteSpace: 'pre-line' }}>{item.desc.join("/n")}</Typography>
                                    </div>
                                ))}
                            </Grid>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
export default Rules;
