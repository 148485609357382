import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import style from "./LoginModal.module.css";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../form/Input";
import Alert from "../general/message/AlertMessage";
import lng from "../../utils/language";
import { api } from "../../api/config";
import SelectOptions from "../form/SelectOptions";
// import DateWrapper from "../form/DatePicker";
// import animationData from "../../utils/lotties/data.json";
import Grid from "../../layout/grid/Grid";
import parse from "date-fns/parse";
import DatePickerSeperate from "../form/DatePickerSeperate";
import { Box } from "@mui/material";

export default function RegisterForm(props) {
  const { language } = useSelector((state) => state.language);
  const [msg, setMsg] = useState(null);

   //DOB START 
   const years = new Date().getFullYear();
   const [day, setDay] = useState("01");
   const [month, setMonth] = useState("01");
   const [year, setYear] = useState(years);
   //DOB END

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
    lastname: Yup.string()
      .min(2, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
    email: Yup.string()
      .email(lng[language]["invalidMail"])
      .required(lng[language]["required"]),
    mob: Yup.string()
      .required(lng[language]["required"])
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        lng[language]["invalidPhone"]
      ),
    dob: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        let result = parse(originalValue, "dd.MM.yyyy", new Date());
        if (!value) {
            result = parse(originalValue, "dd.MM.yyyy", day + month + year);
        }
        return result;
      })
      .typeError(lng[language]["invalid"])
      .required(lng[language]["required"])
      .min("1969-11-13", "Date is too early"),
    password: Yup.string()
      .required(lng[language]["required"])
      .min(6, "Fjalëkalimi duhet të ketë të paktën 6 karaktere"),
    passwordConfirmation: Yup.string()
      .required(lng[language]["required"])
      .oneOf([Yup.ref("password"), null], lng[language]["doNotMatch"]),
    terms: Yup.boolean().oneOf([true], lng[language]["acceptTerms"]),
    policy: Yup.bool().oneOf([true], lng[language]["acceptTerms"]),
    gender: Yup.mixed().oneOf(["f", "m"], lng[language]["optionsError"]),
    city: Yup.string()
      .min(4, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
    country: Yup.string()
      .min(4, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
  });

  const options = [
    { value: "f", label: "Female" },
    { value: "m", label: "Male" },
  ];
//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = () => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      );
      if (!element) return;

      // Scroll to first known error into view
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  };

  return (
    <Formik
      initialValues={{
        name: "",
        gender: "f",
        lastname: "",
        fullname: "",
        email: "",
        mob: "",
        dob: `${day}.${month}.${year}`,
        password: "",
        city: "",
        country: "",
        terms: false,
        policy: false,
      }}
      validationSchema={SignupSchema}
      onVa
      onChange={(values) => {}}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        api
          .post("auth/register", values)
          .then((response) => {
            //Check message type
            setMsg({
              type: response.data.type,
              message: response.data.msg,
            });
            if (response.data.type !== "success") {
              setTimeout(() => {
                setMsg(null);
              }, "5000");
              //Scroll to top
            } else {
    
              setTimeout(() => {
                resetForm();
              }, "10000");
            }
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.error(error);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className={style.registerForm}>
          {msg?.type === "error" && (
            <Alert message={msg.message} type="errorMessages" />
          )}
          <ScrollToFieldError />
          <Grid no="2">
            <Input
              placeholder={lng[language]["name"]}
              label={lng[language]["name"]}
              name="name"
              type="text"
              onChange={handleChange}
              errorMessage={errors.name}
              touched={touched.name}
            />
            <Input
              placeholder={lng[language]["lastname"]}
              label={lng[language]["lastname"]}
              name="lastname"
              type="text"
              onChange={handleChange}
              errorMessage={errors.lastname}
              touched={touched.lastname}
            />
            <Input
              placeholder={lng[language]["email"]}
              label={lng[language]["email"]}
              name="email"
              type="text"
              onChange={handleChange}
              errorMessage={errors.email}
              touched={touched.email}
            />
            <Input
              placeholder={lng[language]["mob"]}
              label={lng[language]["mob"]}
              name="mob"
              type="text"
              onChange={handleChange}
              errorMessage={errors.mob}
              touched={touched.mob}
            />
            <Input
              placeholder={lng[language]["password"]}
              label={lng[language]["password"]}
              type="password"
              name="password"
              onChange={handleChange}
              errorMessage={errors.password}
              touched={touched.password}
            />
            <Input
              placeholder={lng[language]["passwordConfirmation"]}
              label={lng[language]["passwordConfirmation"]}
              type="password"
              name="passwordConfirmation"
              onChange={handleChange}
              errorMessage={errors.passwordConfirmation}
              touched={touched.passwordConfirmation}
            />
            <Input
              placeholder={lng[language]["city"]}
              label={lng[language]["city"]}
              type="text"
              name="city"
              onChange={handleChange}
              errorMessage={errors.city}
              touched={touched.city}
            />
            <Input
              placeholder={lng[language]["country"]}
              label={lng[language]["country"]}
              type="text"
              name="country"
              onChange={handleChange}
              errorMessage={errors.country}
              touched={touched.country}
            />
            {/* <DateWrapper
              placeholder={lng[language]["dob"]}
              label={lng[language]["dob"]}
              name="dob"
              type="text"
              onChange={handleChange}
              errorMessage={errors.dob}
              touched={touched.dob}
              setFieldValue={setFieldValue}
            /> */}
             <Box sx={{marginTop: '10px'}}>
                <DatePickerSeperate
                    day={day}
                    month={month}
                    year={year}
                    name="dob"
                    setDay={setDay}
                    setMonth={setMonth}
                    setYear={setYear}
                    fieldValue={setFieldValue}
                />
            </Box>
            <SelectOptions
              placeholder={lng[language]["gender"]}
              label={lng[language]["gender"]}
              options={options}
              setFieldValue={setFieldValue}
              errorMessage={errors.gender}
              touched={touched.gender}
            />
          </Grid>
          <div className={style.termsWrapper}>
            <div>
              <label>
                {lng[language]["terms"]}
                <input type="checkbox" name="terms" onChange={handleChange} />
                <span
                  className={`${errors.terms && style.error} ${
                    style.checkmark
                  }`}
                ></span>
              </label>
            </div>
            <div>
              <label>
                {lng[language]["policy"]}
                <input type="checkbox" name="policy" onChange={handleChange} />
                <span
                  className={`${errors.policy && style.error} ${
                    style.checkmark
                  }`}
                ></span>
              </label>
            </div>
          </div>
          <button type="submit" className="btn">
            {lng[language]["register"]}
          </button>
        </form>
      )}
    </Formik>
  );
}
