import React, { useState } from "react";
import Hamburger from "hamburger-react";
import style from "./Header.module.css";
import { Link } from "react-router-dom";
import { BiUser } from "react-icons/bi";
import Logo from "../../components/general/Logo";
import { useDispatch, useSelector } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";
import { logOutUser } from "../../app/auth/Auth";
import Box from "@mui/material/Box";
import { setLanguage } from "../../app/language/Language";
import lng from "../../utils/language";
// import { removeAll } from "../../app/cart/Cart";

const shoppingCart = {
  marginX: 'auto',
  border: '1px solid #321eff',
  width: '95%',
  left: 0,
  right: 0,
  background: '#321eff',
  height: '45px',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  position: 'absolute',
  bottom: '20%',
  cursor: 'pointer'
}

function HeaderMobile(props) {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);
  const [isOpen, setOpen] = useState(false);
  const { authenticated, user } = useSelector((state) => state?.auth);
  const { items } = useSelector((state) => state.cart);
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const logout = () => {
    dispatch(logOutUser());
    // dispatch(removeAll());
  };

  const changeLang = (val) => {
    dispatch(setLanguage(val));
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
    setOpen(false)
  };

  return (
    <div className={style.headerMobile}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#321eff'
      }}>
        <Box onClick={() => scrollToBottom()} sx={{color: '#FFF', paddingLeft: '20px'}}>
            {language === 'alb' ? 'Kontako' : 'Contact'}        
        </Box>
        <ul className={style.lngWrapper}>
            <li onClick={() => changeLang("alb")}  className={`${language === "alb" && style.active}`}>Alb</li>
            <li onClick={() => changeLang("eng")}  className={`${language === "eng" && style.active}`}>Eng</li>
        </ul>
      </Box>
      <Box className={`${style.headerMobileTop} container`} sx={{display: 'block !important'}}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <Logo styleProp={"mobile"} />
            <ul className={style.flexList}>
            <li onClick={() => toggleMenu()}>
                {authenticated ? (
                <span>
                    {" "}
                    {user?.name.charAt(0)}
                    {user?.lastname.charAt(0)}
                </span>
                ) : (
                <Link to="/login">
                    <BiUser size={25} color="#fff" />
                </Link>
                )}

                {authenticated && openMenu && (
                <div className={style.loginMenuMob}>
                    <Link to="/user/reservation">{lng[language]["reservation"]}</Link>
                    <Link to="/user/profile">{lng[language]["profile"]}</Link>
                    <span onClick={() => logout()}>{lng[language]["logout"]}</span>
                </div>
                )}
            </li>
            <li>
                <Hamburger
                toggled={isOpen}
                toggle={setOpen}
                size={20}
                direction="right"
                />
            </li>
            </ul>
        </Box>
        <Box sx={{
            display: 'flex',
            justifyContent: 'start'
        }}>
            <Box 
                variant="h1" component={Link} sx={{
                    marginRight: 2, 
                    fontSize: '14px', 
                    textDecoration: 'none', 
                    color: '#FFF'
                    }} 
                    to='/activities/bussiness'  onClick={() => setOpen(false)}>
                    HyperBusiness
                </Box>

                <Box component={Link} sx={{
                    marginRight: 2, 
                    fontSize: '14px', 
                    textDecoration: 'none', 
                    color: '#FFF'
                    }} to='/activities/party'  onClick={() => setOpen(false)}>
                    Birthday Party
                </Box>
                <Box component={Link} sx={{
                    marginRight: 2, 
                    fontSize: '14px', 
                    textDecoration: 'none', 
                    color: '#FFF'
                    }} to="/reservation" onClick={() => setOpen(false)}>
                    {lng[language]['rezNow']}
                </Box>
        </Box>
      </Box>

      <div className={`${style.mobileHeader} ${isOpen ? style.openMenu : ""}`}>
        <div className="container">
          <ul>
            {props.menu &&
              props.menu.map((data, index) => (
                <li key={index} className={`${isOpen ? style.fadeIn : ""}`}>
                  <Link to={data.url} onClick={() => setOpen(false)}>
                    {data.title}
                  </Link>
                </li>
                
              ))}
              {/* <li className={`${isOpen ? style.fadeIn : ""}`}> */}
             
              {/* </li> */}
              <Box component={Link} to="/cart" sx={shoppingCart}onClick={() => setOpen(false)}>
                    <FiShoppingCart size={25} color="#fff" />
                    {items.length > 0 && (
                      <span>{items.length}</span>
                    )}
              </Box>
          </ul>
          <div className={style.mobileNav}>
            {/* <Link to="/reservation" className="btn"  onClick={() => setOpen(false)}>
                Kyçu
              </Link>
              <Link to="/user/profile" className="btn"  onClick={() => setOpen(false)}>
                Regjistrohu
              </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobile;
