import axios from "axios";

const url = new URL(window.location.href);

const fullUrl = url.origin || "https://hyper-active.com";
// const fullUrl = url.origin || "https://test.hyper-active.com";
// const fullUrl = "https://test.hyper-active.com";

export const api = axios.create({ baseURL: fullUrl + "/api" });

api.defaults.headers.post['Content-Type'] = "application/json";

// Add a request interceptor
api.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = token;
    return config;
});

api.interceptors.response.use(function (response) {
    if (response?.data?.severity === 'error') {
        if (response?.data?.reason === 'database') {
            window.location.href = "/error";
            localStorage.setItem('error', true)
        }
    }
    else localStorage.removeItem('error');
    return response;
}, function (err) {

    if (err.response?.status === 401) window.location.href = "/error";
    else if (err.response?.status === 404) window.location.href = "/notfound";
    return Promise.reject(err);
});