import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { initiateTempToken, isTokenValid } from "./api/utils";
import Footer from "./layout/footer/Footer";
import HeaderHandler from "./layout/header/HeaderHandler";
import AllRoutes from "./routes";
import "./var.module.css";
import env from "react-dotenv";
// import ReactPixel from "./utils/pixel/reactPixel";
import ReactGA from "react-ga4";
import { validateUser } from "./app/auth/Auth";
import WaitingLoader from "./components/general/loader/WaitingLoader";
import { initializeCart } from "./app/cart/Cart";
import { GoogleOAuthProvider } from '@react-oauth/google';
import MajorError from "./errors/MajorError";
import { Box } from "@mui/material";
import { initFacebookPixel, trackCustomEvent } from "./utils/pixel/facebookPixel";
function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const load = useSelector((state) => state.auth.isLoading);
    const { authenticated } = useSelector((state) => state.auth);

    let error = localStorage.getItem('error');

    useEffect(() => {
        if (error) return
        initFacebookPixel(env?.PIXEL_KEY)
        // ReactPixel.init(env?.PIXEL_KEY);
        ReactGA.initialize("G-5EG2GQ5QD1");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (error) return
        window.scrollTo(0, 0);
        // ReactPixel.pageView();
        trackCustomEvent('PageView', {page_location: window.location.pathname});
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        //eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (error) return
        const isAuth = isTokenValid();
        if (isAuth.isValide) {
            dispatch(validateUser({
                token: isAuth.token,
                user: isAuth.user,
                exp: isAuth.expTime
            }))
        }
        else {
            initiateTempToken();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (error) return
        dispatch(initializeCart())
        // eslint-disable-next-line
    }, [authenticated])

    useEffect(() => {}, [error])

    const hiddenPathsConfig = {
        header: ['/menu', '/menu/:id', '/hyper/register', '/activities/halloween', '/hyper/hypersurvey/:id'],
        bodyWrapper: ['/menu', '/menu/:id', '/hyper/register', '/activities/halloween', '/hyper/hypersurvey/:id'],
        footer: ['/menu', '/menu/:id', '/hyper/register', '/activities/halloween', '/hyper/hypersurvey/:id']
    };
    
    // Function to check if a path matches with dynamic segments
    const pathMatches = (configArray, currentPath) => {
        return configArray.some(path => {
            const regex = new RegExp(path.replace(/:[^/]+/g, '[^/]+'));
            return regex.test(currentPath);
        });
    };
    
    return (
        error ?
            <MajorError></MajorError>
            :
            <GoogleOAuthProvider clientId='487988760-uka6t680fihhl0d1cc2nnq5vl6cg914c.apps.googleusercontent.com'>
            {/* Hide header if location matches array */}
            {pathMatches(hiddenPathsConfig.header, location.pathname) ? null : <HeaderHandler />}
            {
                load
                ?
                    <WaitingLoader open={load} />
                :
                    <Box className={pathMatches(hiddenPathsConfig.bodyWrapper, location.pathname) ? null : 'bodyWrapper'}>
                        <AllRoutes />
                    </Box>
            }
            {/* Hide footer if location matches array */}
            {pathMatches(hiddenPathsConfig.footer, location.pathname) ? null : <Footer />}
            </GoogleOAuthProvider>
    );
}

export default App;