import { useState } from "react";
import style from "./PickPlayers.module.css";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link } from "react-router-dom";
import lng from "../../../../utils/language";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const btnStyle = {
    width: '30px', 
    padding: 0,
    height: '30px', 
    display: 'flex', 
    minWidth: 'unset', 
    position: 'relative', 
    alignItems: 'center', 
    borderRadius: '100%', 
    transition: '.3s all',
    justifyContent: 'center', 
    backgroundColor: '#321eff', 
    '&:hover': {
        'svg' : {
            color: '#321eff'
        },
        backgroundColor: '#FFF',
    },
};

function PickPlayers({ setReservationInfo, reservationInfo, updatePage, reservationParams }) {

    const { language } = useSelector((state) => state.language);
    const [error, setError] = useState({
        error: false,
        msg: ''
    });

    const updateStep = () => {
        //Validate
        if (reservationInfo.adult <= 0 && reservationInfo.children <= 0) {
            setError({
                error: true,
                msg: 'Ju lutem zgjedhni numrin e lojtarëve!'
            });
        } else {
            setError({error: false});
            updatePage();
        }
    };

    const updateAdult = (
        addQuantity, 
        maxAllow, 
        minAllow
    ) => {
        if (addQuantity) {
            if (reservationInfo.adult !== maxAllow) {
                setReservationInfo((prevState) => ({
                    ...prevState,
                    otherParams: {
                        adult: 0,
                        children: 0
                    },
                    children: 0,
                    adult: prevState.adult + 1,
                    players: +1
                }));
            }else{
                setError({
                    error: true,
                    msg: lng[language]['maxError']
                });
            }
        } 
        else {
            if (reservationInfo.adult === 1 || reservationInfo.adult > minAllow) {
                setReservationInfo((prevState) => ({
                    ...prevState,
                    otherParams: {
                        adult: 0,
                        children: 0
                    },
                    children: 0,
                    adult: prevState.adult - 1,
                    players: 0
                }));
            }
        }
    };

    const updateChildren = (addQuantity, maxAllow = 2, minAllow = 0) => {
        if (addQuantity) {
            if (reservationInfo.children !== maxAllow) {
                setReservationInfo((prevState) => ({
                    ...prevState,
                    children: prevState.children + 1,
                    adult: 0,
                    otherParams: {
                        adult: prevState.otherParams.adult + 1,
                        children: prevState.otherParams.children + 1
                    },
                    players: +1
                }));
            }
        } else {
            if (reservationInfo.children === 1 || reservationInfo.children > minAllow) {
                setReservationInfo((prevState) => ({
                    ...prevState,
                    otherParams: {
                        adult: prevState.otherParams.adult - 1,
                        children: prevState.otherParams.children - 1
                    },
                    adult: 0,
                    children: prevState.children - 1,
                    players: 0
                }));
            }
        }
    };

    const updateQty = (index, val, maxAllow, minAllow) => {
        if (index === 0) {
            updateAdult(val, maxAllow, minAllow);
        }
        else {
            updateChildren(val);
        }
    };

    return (
        <Box sx={{color: '#FFF !important'}}>
            <div className={style.border}>
                <div className={style.border}>
                    <Link to="/reservation">
                        <MdOutlineArrowBackIos />
                    </Link>
                    <div>
                        <h2>{lng[language]["playerNo"]}</h2>
                    </div>
                </div>
                <p className={style.priceList}>
                    {lng[language]["priceList"]}
                    <Link to="/activities/price" target="_blank">
                        {lng[language]["click"]}
                    </Link>
                </p>
            </div>
            {error.error && (
                <p className="errorMsg">{error.msg}</p>
            )}
            <div>
                {reservationParams.map((i, index) => {
                    return (
                        <Box key={index} sx={{ borderTop: '1px solid #120e2e', borderBottom: '1px solid #120e2e', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ marginY: 1 }}>
                                <Typography variant="h5" fontWeight={'bold'} sx={{ marginBottom: 1 }}>{lng[language][i.title]}</Typography>
                                <Typography variant="body2">{lng[language][i.desc]}</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '100px'}}>
                                <Button sx={btnStyle} variant="contained" onClick={() => updateQty(index, false, i.maxValue, i.minValue)}>
                                    <RemoveIcon sx={{position: 'absolute'}} />
                                </Button>
                                <Typography variant='h6' fontWeight={'bold'}>
                                    {index === 0 ? reservationInfo.adult : reservationInfo.children}
                                </Typography>
                                <Button sx={btnStyle} variant="contained" onClick={() => updateQty(index, true, i.maxValue, i.minValue)}>
                                    <AddIcon sx={{position: 'absolute'}} />
                                </Button>
                            </Box>
                        </Box>
                    );
                })}
            </div>
            <div>
                <button className="btn mt20" onClick={() => updateStep()}>
                    Vazhdo
                </button>
            </div>
        </Box>
    );
}
export default PickPlayers;
