import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../api/config";
import { useState } from "react";
import Loader from "../../../components/general/loader/Loader";
// import Wrapper from "../../../components/general/Wrapper";
import LottieMessage from "../../../components/general/message/LottieMessage";
import ChangePasswordForm from "../../../layout/general/ForgetPassword/Form";

function ChangePassword() {
  const [changePasswordStep, setChangePasswordStep] = useState(0);
  const [loading, setLoading] = useState(true);
  let { token } = useParams();

  useEffect(() => {
    setLoading(true);
    //Token Validation
    api
      .post("auth/passwordToken", { token: token })
      .then((response) => {
        if (response.data.success) {
          setChangePasswordStep(0);
          setLoading(false);
        } else {
          setLoading(false);
          setChangePasswordStep(2);
        }
      })
      .catch((error) => {
        setLoading(true);
        setChangePasswordStep(2);
      });
      // eslint-disable-next-line
  }, []);

  const pageWrapper = [
    <ChangePasswordForm
      setLoading={setLoading}
      setChangePasswordStep={setChangePasswordStep}
    />,
    <LottieMessage
      text="Fjalëkalimi juaj u ndryshua me sukses!"
      type="success"
      linkText="Kyçuni për të vazhduar"
      linkTo="/login"
    />,
    <LottieMessage text="Oops, linku për ndryshimin e fjalëkalimit ka skaduar!" type="error" linkText="Akoma problem me fjalëkalimin?" linkTo="/password"/>,
  ];

  return !loading ? (
    <>
      {pageWrapper[changePasswordStep]}
    </>
  ) : (
    <Loader />
  );
}
export default ChangePassword;
