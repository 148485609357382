import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import lng from "../../../utils/language";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
import Grid from "../../../layout/grid/Grid";
import SingleBanner from "../../../layout/activities/SingleBanner";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
// import ReserveText from "../../../components/reservation/ReserveText";
import React, { useRef } from "react";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import CarouselGallery from "../../../components/carousel/CarouselGallery";
import RandomCards from "../../../components/card/RandomCards";
// import { useState } from "react";
// import { escape } from "../../../utils/gameLng";
// import { AnimatePresence, motion } from 'framer-motion';
import FetchData from "../../../api/FetchData";
import ImageTextCard from "../../../components/card/ImageTextCard";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
// import Text from "../../../components/text/Text";

function EscapeRoom() {
    const { language } = useSelector((state) => state.language);

    const gallery = useRef(null);
    const escapeRoom1 = useRef(null);
    const escapeRoom2 = useRef(null);
    const escapeRoom3 = useRef(null);
    const escapeRoom4 = useRef(null);
    
    const lostCityOfAtlantis = useRef(null);
    const nightmareHotel = useRef(null);
    const orientExpress = useRef(null);
    const walkingDeath = useRef(null);
    const theOrphanage = useRef(null);
    const horrorCircus = useRef(null);
    // const [show1, setShow1] = useState(false);
    // const [show2, setShow2] = useState(false);
    // const [show3, setShow3] = useState(false);
    // const [show4, setShow4] = useState(false);

    const [gameData, setGameData] = React.useState();
    const [loading, setLoading] = React.useState(true)

    const load = () => {
        FetchData.create('/general/page', {
            id: 6,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setGameData(res.data.data)
                setLoading(false);
            })
            .catch(err => {
            })
    }

    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])

    // const reverse = (state, setstate) => {
    //     setstate(!state);
    // }

    const interest = {
        eng: {
            interest: [
                {
                    interest: 'Gallery',
                    ref: gallery
                },
                {
                    interest: 'La Mision Del Profesor',
                    ref: escapeRoom1
                },
                {
                    interest: 'Haunted Mansion',
                    ref: escapeRoom2
                },
                {
                    interest: 'School of Magic',
                    ref: escapeRoom3
                },
                {
                    interest: '1001 Nights',
                    ref: escapeRoom4
                },
                // NEW ROOMS
                {
                    interest: 'The Orphanage',
                    ref: theOrphanage
                },
                {
                    interest: 'Horror Circus',
                    ref: horrorCircus
                },
                {
                    interest: 'Lost City Of Atlantis',
                    ref: lostCityOfAtlantis
                },
                {
                    interest: 'Nightmare Hotel',
                    ref: nightmareHotel
                },
                {
                    interest: 'Orient Express',
                    ref: orientExpress
                },
                {
                    interest: 'Walking Death',
                    ref: walkingDeath
                }
            ]
        },
        alb: {
            interest: [
                {
                    interest: 'Galeria',
                    ref: gallery
                },
                {
                    interest: 'La Mision Del Profesor',
                    ref: escapeRoom1
                },
                {
                    interest: 'Haunted Mansion',
                    ref: escapeRoom2
                },
                {
                    interest: 'School of Magic',
                    ref: escapeRoom3
                },
                {
                    interest: '1001 Nights',
                    ref: escapeRoom4
                },
                 // NEW ROOMS
                 {
                    interest: 'The Orphanage',
                    ref: theOrphanage
                },
                {
                    interest: 'Horror Circus',
                    ref: horrorCircus
                },
                {
                    interest: 'Lost City Of Atlantis',
                    ref: lostCityOfAtlantis
                },
                {
                    interest: 'Nightmare Hotel',
                    ref: nightmareHotel
                },
                {
                    interest: 'Orient Express',
                    ref: orientExpress
                },
                {
                    interest: 'Walking Death',
                    ref: walkingDeath
                }
            ]
        }
    }

    const bigCard = [
        {
            logo: 'https://hyper-active.com/images/content/nightmare_hotel.svg',
            imgSrc: constants.imgPath + "nightmare_hotel.png",
            title: {
                eng: "NIGHTMARE HOTEL",
                alb: "NIGHTMARE HOTEL",
                referenc: nightmareHotel
            },
            desc: {
                alb: "Hyni në hotelin e pushtuar nga fantazmat, ku çdo dhomë është një makth i ri dhe çdo hap është një udhëtim në thellësitë e tmerrit.",
                eng: "Enter the haunted hotel, where every room is a new nightmare and every step is a journey into the depths of terror.",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "13",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "13",
                },
            },
            longDesc: {
                alb: `Ky hotel është ndërtuar mbi themelet e një tragjedie të vjetër, dhe çdo dhomë ka një temë të veçantë që përfaqëson një aspekt të errësirës që ka mbuluar këtë vend.

N’këtë hotel realiteti është i deformuar, t’duket se diqka po ju ndjek e që e hijet po lëvizin… e a është ky realiteti? Ju mbetet juve me e zbulu!

Zgjidhni enigmat që lidhin këto dhoma dhe zbuloni sekretet e hotelit dhe entiteteve fantazmatike që banojnë aty.

Gjeni zgjidhjen përpara se makthet të bëhen realitet dhe t’bëheni banorët e përjetshëm të “NIGHTMARE HOTEL”

Gjumë t’ëmbël!
`,
                eng: `This hotel is built on the ruins of an ancient tragedy, and each room has a unique theme that represents an aspect of the darkness that has shrouded this place.
In this hotel, reality is distorted; it seems that something is following you, and shadows are moving... but is this reality? It's up to you to discover!
Solve the puzzles connecting these rooms and uncover the secrets of the hotel and the ghostly entities that dwell there.
Find the solution before the nightmares become reality and you become eternal residents of the “NIGHTMARE HOTEL.”
Sweet dreams!
`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/orient_express.svg',
            imgSrc: constants.imgPath + "orient_express.png",
            title: {
                eng: "ORIENT EXPRESS",
                alb: "ORIENT EXPRESS",
                referenc: orientExpress
            },
            desc: {
                alb: "Nisuni për udhëtim me trenin luksoz të stilit klasik, ku çdo detaj të transporton në epokën e artë të udhëtimeve me tren, por ksaj here n’rolin e detektivit.",
                eng: "Embark on a journey aboard the luxurious classic train, where every detail transports you to the golden age of train travel, but this time in the role of a detective.",
            },
            list: {
                eng: {
                    Category: "Mystery",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "14",
                },
                alb: {
                    Kategoria: "Mister",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "14",
                },
            },
            longDesc: {
                alb: `Zgjidhni misterin e vrasjes së çuditshme që ndodhi në këtë tren dhe kape vrasësin para se treni të ndalet.

Në këtë tren, enigma janë të fshehura gjithandej e midis detajeve të vogla.
Ky udhëtim është i mbushur me plot rrezik dhe intriga.. çdo kthesë e trenit mund të zbulojë një sekret të ri. Qëllimi juaj është të zgjidhni misterin dhe të dilni nga treni përpara se udhëtimi të përfundojë.

Udhëtim të këndshëm!`,
               
                eng: `Solve the mystery of the bizarre murder that occurred on this train and catch the killer before the train stops.
In this train, enigmas are hidden everywhere amid small details. This journey is filled with danger and intrigue; every turn of the train could reveal a new secret. Your goal is to solve the mystery and exit the train before the journey ends.
Enjoy your trip!`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/walking_death.svg',
            imgSrc: constants.imgPath + "walking_death.png",
            title: {
                eng: "WALKING DEATH",
                alb: "WALKING DEATH",
                referenc: walkingDeath
            },
            desc: {
                alb: "Në këtë dhomë plotë tension do të kaloni në një ambient që ngjan me një luftë të përfunduar…keq. Ekipi juaj ka mbijetuar ferrin, duke luftuar kundër të vdekurve dhe armiqve të gjallë por a do të arrijë të mbijetoj edhe ksaj here?",
                eng: "In this room full of tension, you will enter an environment that resembles a battle that has ended... badly. Your team has survived hell, fighting against the dead and living enemies, but will you manage to survive this time as well?",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "15",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "15",
                },
            },
            longDesc: {
                alb: `Ambienti është i mbushur me tinguj të frikshëm dhe zhurma të vazhdueshëme të krijesave të padukshme..

Ju duhet të gjeni furnizime, të zgjidhni enigmat për të mbijetuar dhe të gjeni një mënyrë për të shpëtuar. Çdo hap është sfidë, e koha n’frikë kalon shumë shpejtë.
Bashkpunoni me qetësi për të kapur çdo mundësi që ju ofrohet për të shpëtuar, përpara se të ZOMBIET t'ju bllokojnë krejt mundësitë për mbijetesë.`,
               
                eng: `The atmosphere is filled with creepy sounds and constant noises from invisible creatures.
You must find supplies, solve the puzzles to survive, and find a way to escape. Every step is a challenge, and time passes quickly in fear. Work quietly to seize every opportunity presented to you for survival before the ZOMBIES block all chances of escape.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-10.svg',
            imgSrc: constants.imgPath + "Casa.jpg",
            title: {
                eng: "LA MISIÓN DEL PROFESOR",
                alb: "LA MISIÓN DEL PROFESOR",
                referenc: escapeRoom1
            },
            desc: {
                alb: "I frymëzuar nga koncepti i grabitjes së famshme, në këtë dhomë ju do hyni si hajdukët që punojnë nën udhëheqjen e Profesorit të njohur. Plani është i vendosur, por një herë brenda përmendores, a do mbrrini deri te plani ju?",
                eng: "Inspired by the concept of the famous heist, in this room, you will enter as thieves working under the guidance of a renowned Professor. The plan is set, but once inside the monument, will you succeed in your plan?",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "17",
                },
                alb: {
                    Kategoria: "Aventurë",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "17",
                },
            },
            longDesc: {
                alb: `Ka ardhur koha për vjedhjen më të madhe në histori. Hyni brenda dhe tregoni se sa të gjindshëm jeni. Plani është i detajuar dhe paratë ju presin, por pasi dyert e Bankës të mbyllen, ekipi juaj do të testohet si kurrë më parë. A do të qëndroni të bashkuar nën presionin e madh apo do të thyheni dhe të tradhtoni njëri-tjetrin?`,
                eng: `The time has come for the greatest theft in history. Enter and show how resourceful you can be. The plan is detailed, and the money awaits you, but once the Bank doors close, your team will be tested like never before. Will you stay united under great pressure, or will you break and betray each other?`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/lost_city_of_atlantis.svg',
            imgSrc: constants.imgPath + "lost_city_of_atlantis.png",
            title: {
                eng: "LOST CITY OF ATLANTIS",
                alb: "LOST CITY OF ATLANTIS",
                referenc: lostCityOfAtlantis
            },
            desc: {
                alb: "Pas shumë vite kërkimesh, keni arritur këtu! Zbuloni rrënojat e Atlantidës dhe Tridentin e Poseidonit. A jeni gati të rishkruani Historinë e Botës dhe të përballeni me rreziqet e panjohura që fshihen nën ujë?",
                eng: "After many years of searching, you have arrived here! Discover the ruins of Atlantis and Poseidon's Trident. Are you ready to rewrite World History and face the unknown dangers that hide beneath the water?",
            },
            list: {
                eng: {
                    Category: "Mythology",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "15",
                },
                alb: {
                    Kategoria: "Mitologji",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "15",
                },
            },
            longDesc: {
                alb: `Në këtë aventurë nënujore, do të nisni një udhëtim të madh për të zbuluar sekretet e qytetit legjendar të Atlantidës. Ndërsa eksploroni tempujt dhe rrënojat e zhytuna, do të hasni në enigmat të komplikuara që sfidojnë dijen tuaj mbi mitet dhe legjendat e Atlantidës. Ambienti është kriju me tinguj të thellë të detit dhe pamje të arkitekturës së zhytun.

Misioni juaj është të zgjidhni këto enigmat e vjetra për të zbuluar artefakte të fshehta dhe pjesë të historisë së Atlantidës. Koha kalon shpejtë dhe ju duhet të zgjidhni misteret përpara se sekretet e qytetit të humbin përgjithmonë në thellësi.`,
               
                eng: `In this underwater adventure, you will embark on a grand journey to uncover the secrets of the legendary city of Atlantis. As you explore the sunken temples and ruins, you will encounter complicated puzzles that challenge your knowledge of Atlantis's myths and legends. The environment is created with deep ocean sounds and views of submerged architecture.

Your mission is to solve these ancient enigmas to uncover hidden artifacts and pieces of Atlantis's history. Time passes quickly, and you must unravel the mysteries before the secrets of the city are lost forever in the depths.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/the_ophanage.svg',
            imgSrc: constants.imgPath + "the_ophanage.png",
            title: {
                eng: "THE ORPHANAGE",
                alb: "THE ORPHANAGE",
                referenc: theOrphanage
            },
            desc: {
                alb: "Hyni në një jetimore të braktisur ku fantazmat e së kaluarës ende banojnë. Zbuloni misteret dhe shpëtoni përpara se të ngecni aty përgjithmonë.",
                eng: "Enter an abandoned orphanage where the ghosts of the past still reside. Uncover the mysteries and escape before you get trapped there forever.",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "12",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "12",
                },
            },
            longDesc: {
                alb: `Në këtë jetimore e kaluara e errët dhe e trishtuar ka lënë gjurmë të pashlyera.
Çdo detaj brenda tregon diqka nga historia e fëmijëve të braktisur dhe të vetmuar që dikur e quanin këtë vend shtëpi.

Eksploroni histori të frikshme dhe sekrete të errëta. Kjo jetimore mban më shumë se vetëm kujtime. Shpirtrat e mbetur aty brenda kërkojnë t’kujdesen për dikë ashtu si kujdestaret që u kujdesen për ta… dikur.

Misioni juaj është të zgjidhni misteret e së kaluarës dhe të gjeni një rrugëdalje përpara se të bëhet kjo jetimore shtëpia juaj e re.`,
               
                eng: `In this orphanage, the dark and sad past has left indelible marks. Every detail inside tells something from the story of the abandoned and lonely children who once called this place home.
Explore frightening stories and dark secrets. This orphanage holds more than just memories. The spirits remaining inside seek to care for someone, just as the caretakers once cared for them… long ago.
Your mission is to solve the mysteries of the past and find an exit before this orphanage becomes your new home.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-9.svg',
            imgSrc: constants.imgPath + "potter1.jpg",
            title: {
                eng: "SCHOOL OF MAGIC",
                alb: "SCHOOL OF MAGIC",
                referenc: escapeRoom3
            },
            desc: {
                eng: "In this adventure, you become a wizard tasked with defeating the dark forces threatening the magical kingdom. You will need to work together with your team to navigate various enigmas, unlock hidden secrets, and ultimately recover powerful magical artifacts.",
                alb: "Në këtë aventurë, ti bëhesh një magjistar që ka për detyrë të mposhtë forcat e errëta që kërcënojnë mbretërinë magjike. Do të duhet të punosh së bashku me ekipin tënd për të naviguar nëpër enigma të ndryshme, për të çelur sekrete të fshehura dhe, në fund, për të rikuperuar artefakte magjike të fuqishme.",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    // Mistery: "18",
                    Mysteries: "18",
                },
                alb: {
                    Kategoria: "Aventurë",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    // Mister: "18",
                    Misteret: "18",
                },
            },
            longDesc: {
                eng: `Professor Bumblegate has lost control of his magic school and urgently needs your help! An usurper has taken his form and stolen the Broom of Infinite Knowledge. You have 60 minutes to navigate through dark magic, uncover the professor's secret study, and retrieve the Broom of Infinite Knowledge. But be careful, every minute that passes, the usurper grows stronger…`,
                alb: `Profesor Bumblegate ka humbur kontrollin mbi shkollën e tij magjike dhe ka nevojë për ndihmën tuaj urgjente! Një uzurpator ka marrë formën e tij dhe ka vjedhur Fshesën e Diturisë së Pafund.  60 minuta keni kohë për të kaluar përmes magjive të errëta, për të zbuluar studion sekrete të profesorit dhe për të rikthyer Fshesën e Diturisë së Pafund. Por kini kujdes, çdo minutë që kalon, uzurpatori bëhet më i fortë…`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/horror_circus.svg',
            imgSrc: constants.imgPath + "horror_circus.png",
            title: {
                eng: "HORROR CIRCUS",
                alb: "HORROR CIRCUS",
                referenc: horrorCircus
            },
            desc: {
                alb: "Ky cirk nuk është si të tjerët. Kllounë të frikshëm dhe aktorë të çuditshëm ju presin brenda. A mund të arratiseni përpara se të bëheni pjesë e shfaqjes së tyre të tmerrshme?",
                eng: "This circus is unlike any other. Scary clowns and strange performers await you inside. Can you escape before you become part of their terrifying show?",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "3-6",
                    Time: "60min",
                    Mysteries: "16",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "3-6",
                    Kohëzgjata: "60min",
                    Misteret: "16",
                },
            },
            longDesc: {
                alb: `Paramendoni se jeni një grup shoqëror në kërkim të shokut tuaj t’zhdukun.
Pas shumë kërkimesh ku e keni gjet një biletë të cirkut “Wanderlust Circus, 1940.” për të cilin nuk keni ndëgju kurrë më herët. Nga shqetësimi për shokun pa e mendu dy herë niseni për të hetuar cirkun, por sapo futeni brenda e kuptoni se tashmë jeni të ngujuar në një cirk të mallkuar.

Ky cirk është e mbushur me kukulla të çuditshme që duken sikur po ju ndjekin me sytë e tyre të vegjël dhe të frikshëm. Performuesit janë më shumë të frikshëm se sa argëtues. Cirku është i mbushur me klounë, udhëheqës të frikshëm dhe atraksione jo shumë argëtuese.

Qëllimi juaj është të arrini të shpëtoni para se klouni të kthehet dhe cirku t’ju shndërrojë në një atraksion të ri.`,
               
                eng: `Imagine you are a group of friends searching for your missing companion. After much searching, you find a ticket to the “Wanderlust Circus, 1940,” one you’ve never heard of before. Driven by concern for your friend, you rush to investigate the circus, but as soon as you enter, you realize you are now trapped in a cursed circus.
This circus is filled with strange puppets that seem to watch you with their small, eerie eyes. The performers are more frightening than entertaining. The circus is packed with clowns, creepy ringmasters, and not-so-fun attractions.
Your goal is to find a way to escape before the clown returns and the circus turns you into a new attraction.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-11.svg',
            imgSrc: constants.imgPath + "escaperoommansion21.jpg",
            title: {
                eng: "HAUNTED MANSION",
                alb: "HAUNTED MANSION",
                referenc: escapeRoom2
            },
            mainColor: '',
            desc: {
                alb: "Një shtëpi të lashtë, të mbushur me mistere të frikshme dhe një objekt i lashtë që fsheh fuqi të pamatura. Shumë kanë hyrë, por askush nuk është kthyer. A do të jeni ju ata që thyeni mallkimin dhe të arratiseni të gjallë?",
                eng: "An ancient house filled with frightening mysteries and an ancient object that hides unimaginable power. Many have entered, but no one has returned. Will you be the ones to break the curse and escape alive?",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    // Mistery: "17",
                    Mysteries: "16",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    // Mister: "16",
                    Misteret: "16",
                },
            },
            longDesc: {
                alb: `Si ekspertët më të mirë të mbinatyrshmes, ju keni marrë një sfidë të veçantë – të zbuloni një objekt të lashtë brenda një vile të përhumbur. Por ka një problem: të gjithë që kanë provuar më parë nuk janë kthyer kurrë! A do të jeni ju ata që zgjidhin misterin e errët dhe dalin të gjallë?`,
                eng: `As the best experts in the supernatural, you have received a special challenge – to discover an ancient object within a haunted villa. But there’s a problem: everyone who tried before has never returned! Will you be the ones to solve the dark mystery and come out alive?`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-8.svg',
            imgSrc: constants.imgPath + "game_over_1001_nights3.jpg",
            title: {
                eng: "ONE THOUSAND & ONE NIGHTS",
                alb: "ONE THOUSAND & ONE NIGHTS",
                referenc: escapeRoom4
            },
    
            desc: {
                eng: "In this adventure, you and your team are transported to a time of brave heroes and mysterious lands. Your mission is to recover the lost tales of the legendary storyteller, Scheherazade.",
                alb: "Në këtë aventurë, ju dhe ekipi juaj transportoheni në një kohë të heronjve të guximshëm dhe tokave misterioze. Misioni juaj është të rikuperoni përrallat e humbura të tregimtarit legjendar, Scheherazade.",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    // Mistery: "15",
                    Mysteries: "15",
                },
                alb: {
                    Kategoria: "Aventurë",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    // Mister: "15",
                    Misteret: "15",
                },
            },
            longDesc: {
                eng: `Koha ka ardhur që të tregoni besnikërinë tuaj dhe të shpëtoni kalifatin tonë nga Khalifi i Errët. Ai ka pushtuar kalifatet fqinje dhe ka dëbuar sulltanin tonë. Plani i tij i radhës është të shkatërrojë tokën tonë dhe të na sundojë me frikë. Ju jeni të vetmit që mund të ndaloni këtë katastrofë dhe të shpëtoni tokën tonë.`,
                alb: `The time has come to show your loyalty and save our caliphate from the Dark Caliph. He has conquered neighboring caliphates and exiled our sultan. His next plan is to destroy our land and rule us with fear. You are the only ones who can stop this catastrophe and save our land.`,
            },
        },
    ];

    const color = ["#FAA000", "#F03C44", "#FAA000", '#F03C44', '#2D8CFF', '#FAA000', '#321EFF', '#F03C46', '#FAA000', '#AF46FA'];
    const img = [];

    for (let i = 1; i < 20; i++) {
        img.push("/escape/" + i + ".png");
    }

    // const preparation = {
    //     eng: {
    //         preparation: [
    //             {
    //                 title: "Game Type",
    //                 desc: "You have 4 interactive rooms of the latest generation to choose from. Each room takes you to a different theme. Depending on your age and preferences, choose a story that is suitable for you and that you want to explore.",
    //                 diplay: () => reverse(show1, setShow1),
    //                 state: show1
    //             },
    //             {
    //                 title: "The Game",
    //                 desc: "An automated self-help system is available in each room, which provides suggestions to help maintain the room. We recommend that you use the system to increase your chances of being saved.",
    //                 diplay: () => reverse(show2, setShow2),
    //                 state: show2
    //             },
    //             {
    //                 title: "Players",
    //                 desc: "The escape rooms can be visited by adults and children from the age of 10. For children up to the age of 12, the presence of parents is mandatory. Each room can be played by 2 to a maximum of 6 players at a time.",
    //                 diplay: () => reverse(show3, setShow3),
    //                 state: show3
    //             },
    //             {
    //                 title: "Time",
    //                 desc: "You have 60 minutes to escape the room. After the time is up, your game will be over. Will you and your team manage to escape in time?",
    //                 diplay: () => reverse(show4, setShow4),
    //                 state: show4
    //             },
    //         ],
    //     },
    //     alb: {
    //         preparation: [
    //             {
    //                 title: "Lloji I lojës",
    //                 desc: "Ju keni 4 dhoma interaktive të gjeneratës më të re për të zgjedhur. Çdo dhomë ju çon në një temë të ndryshme. Në varësi të moshës dhe preferencave tuaja, zgjidhni një histori që është e përshtatshme për ju dhe që dëshironi të eksploroni.",
    //                 diplay: () => reverse(show1, setShow1),
    //                 state: show1
    //             },
    //             {
    //                 title: "Loja",
    //                 desc: "Një sistem i automatizuar vetë-ndihme është i disponueshëm në çdo dhomë, i cili jep sugjerime për të ndihmuar në ruajtjen e dhomës. Ne ju rekomandojmë që të përdorni sistemin për të rritur shanset tuaja për t'u shpëtuar.",
    //                 diplay: () => reverse(show2, setShow2),
    //                 state: show2
    //             },
    //             {
    //                 title: "Lojëtarët",
    //                 desc: "Dhomat e arratisjes mund të vizitohen nga të rriturit dhe fëmijët nga mosha 10 vjeçare. Për fëmijët deri në moshën 12 vjeç, prania e prindërve është e detyrueshme. Çdo dhomë mund të luhet nga 2 deri në maksimum 6 lojtarë në të njëjtën kohë.",
    //                 diplay: () => reverse(show3, setShow3),
    //                 state: show3
    //             },
    //             {
    //                 title: "Koha",
    //                 desc: "Keni 60 minuta për të ikur nga dhoma. Pas përfundimit të kohës, loja juaj do të përfundojë. A do të arrini ju dhe ekipi juaj të arratiseni në kohë?",
    //                 diplay: () => reverse(show4, setShow4),
    //                 state: show4
    //             },
    //         ],
    //     }
    // }

    const containerSpacing = {
        paddingY: '80px'
    }

    return (
        loading === false ?
            <Box className={`${style.detailsWrapper}`}>
                <Box className="container">
                    {
                        gameData[1] &&
                        <ActivitiesBanner
                            title={gameData[1].body_header}
                            desc={gameData[1].body_desc}
                            image={constants.imgPath + gameData[1].images}
                            linkPrice="karting"
                            vidId="Y2uTFvwpA5g"
                            id={gameData[1].link}
                        />
                    }
                    <Box
                    sx={{
                        marginTop: {
                            xs: "100px",
                            sm: 20,
                            md: 0,
                        },
                    }}
                >
                    <ActivitiesInterest interests={interest[language].interest} />
                </Box>
                    

                    <Box
                        className={'bodyWrapper'}
                        sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }}
                    >
                        <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                        <Videos id={'Y2uTFvwpA5g'} />
                    </Box>
                </Box>
                <Box className={style.wrapper}>
                    {bigCard.map((i, j) => {
                        return (
                            <SingleBanner
                                logo={i.logo}
                                key={j}
                                image={i.imgSrc}
                                title={i.title[language]}
                                desc={i.desc[language]}
                                list={i.list[language]}
                                longDesc={i.longDesc[language]}
                                id={"739733"}
                                color={color[j]}
                                referenc={i.title.referenc}
                            />
                        );
                    })}
                </Box>

                {
                    gameData[6] &&
                    <Box marginTop={10} ref={gallery}>
                        <CarouselGallery gallery={gameData[6][0]['images']} radius={5} />
                    </Box>
                }

                {
                    gameData[9] &&
                    <Box className={["container", style.wrapper]}>
                        <Grid no={"ReverseSingle"}>
                            {gameData[9].map((i, j) => {
                                return <ImageTextCard data={i} key={j} specialStyling={{
                                    backgroundColor: 'transparent'
                                }}
                                    txtContainerStyling={{
                                        paddingTop: {
                                            md: '0 !important',
                                            xs: 'unset'
                                        },
                                        '& h1': {
                                            marginTop: {
                                                md: '0 !important',
                                                xs: 'unset'
                                            }
                                        },
                                        '& p': {
                                            fontSize: '14px'
                                        }
                                    }} />;
                            })}
                        </Grid>
                    </Box>
                }

                <Box className={style.wrapper} sx={{ paddingTop: '50px' }}>
                    <PartyBanner
                        header={lng[language]['hyperBirthdayHeader']}
                        desc={lng[language]['hyperBirthdayDesc']}
                        buttonTxt={lng[language]['hyperBirthdayBtn']}
                        link={"/activities/party"}
                        imageUrl={"https://hyper-active.com/images/escaperoom/banner/birthdayParty.png"}
                        containerStyle={{
                            width: { sm: "454px", xs: '90%' },
                            color: "#FFF",
                            background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                            padding: 3,
                            borderRadius: "40px",
                            display: "grid",
                            gridTemplateRows: "1fr 70px 60px",
                            rowGap: "20px",
                        }}
                    />
                </Box>

                <Box className="container">
                    <Box sx={containerSpacing} marginTop={'100px'}>
                        <AppBanner
                            header={lng[language]['hyperAppHeader']}
                            desc={lng[language]['hyperAppDesc']}
                            imgUrl={'../images/6582cf3c6d165.webp'}
                        />
                    </Box>
                    {/* <ReserveText id={739733} /> */}
                    <Box paddingBottom={5} sx={containerSpacing}>
                        <Box sx={{'h1':{ textAlign: 'center !important'}}}>
                            <TextWrapper title={lng[language]['gameSuggestion']} />
                        </Box>
                        <RandomCards removeGame='Escape Rooms' />
                    </Box>
                </Box>

                {/* <Gallery images={img} /> */}
            </Box>
            :
            <WaitingLoader open={loading} />
    );
}
export default EscapeRoom;
