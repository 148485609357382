import { Link } from "react-router-dom";
import { api } from "../../../api/config";
import lng from "../../../utils/language";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import style from "./Reservation.module.css";
import Grid from "../../../layout/grid/Grid";
import { Box, Typography } from "@mui/material";
import Loader from "../../../components/general/loader/Loader";
import TextWrapper from "../../../components/general/text/TextWrapper";

export default function ReservationActivities() {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reservation, setReservation] = useState([]);
    const { language } = useSelector((state) => state.language);

    useEffect(() => {
        api.get("reservation/activities")
            .then((res) => {
                if (res?.data?.success) setReservation(res?.data?.activities);
                else setError(true)
            })
            .catch((error) => {
                // console.error('test');
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <>
            {!loading ? (
                <Box className={`container ${style.atractions}`}>
                    {
                        error ?
                            <Typography variant="h4" textAlign={'center'}>{lng[language]["outOfSerice"]}</Typography>
                            :
                            reservation.length ?
                                <>
                                    <TextWrapper title={lng[language]["bookNow"]} desc="" />
                                    <Grid no={"Activities"}>
                                        {
                                            reservation?.map(data => (
                                                <Link style={{ backgroundImage: `url(https://hyper-active.com/assets/reservation/${data.img})` }} to={`/${data.route}`} className={`${style.atractionsLink}`} key={data.externalId} state={{ name: data.nameAlb, id: data.externalId }}>
                                                    {data.OnlyLogo === 0 && data.nameAlb}
                                                </Link>
                                            ))
                                        }
                                    </Grid>
                                </>
                                :
                                <Box paddingTop={{ md: 10, xs: 5 }}>
                                    <Typography variant="h5" fontWeight={'bold'} textAlign={'center'} fontSize={{ md: '30px', xs: '20px' }}>Something Went wrong! Please try again later.</Typography>
                                </Box>
                    }
                </Box>
            ) : (
                <Loader />
            )}
        </>
    );
}