import Lottie from 'lottie-react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import FetchData from '../../api/FetchData';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import animationData from './SuccessAnimation.json';
import { useParams } from 'react-router-dom';
import { api } from '../../api/config';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

const emojiContainer = { display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '60px', gridGap: '50px' };
const button = { padding: '5px', textAlign: 'center', transition: '.2s all', borderRadius: '40px', color: '#000' };

export default function HyperSurvery() {
    const [allowed, setAllowed] = useState(false);
    const { id } = useParams();
    const [steps, setSteps] = useState(0);
    const [responseType, setResponseType] = useState(null);
    const [survey, setSurvey] = useState([]);
    const [finish, setFinish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [comment, setComment] = useState('');
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const handleResponseType = (value, reponse) => {
        setSurvey([value])
        setResponseType(reponse)
        moveSteps();
    }

    const [positive, setPositive] = useState([]);
    const [negative, setNegative] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(true); // State to track modal visibility

    const handleGrantPermission = () => {
        const element = document.documentElement; // Target the entire document for full-screen
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(); // Safari
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen(); // IE
        }
        setIsModalOpen(false); // Close the modal
    };

    const handleDenyPermission = () => {
        setIsModalOpen(false); // Close the modal without entering full-screen
    };

    const obtainSurveys = () => {
        api.post('/general/getSurvey', { id: parseInt(id) })
            .then(res => {
                if (res?.data?.success) {
                    const positiveFilter = JSON.parse(res?.data?.data?.surveyContent).filter((item) => item.type === "positive");
                    const negativeFilter = JSON.parse(res?.data?.data?.surveyContent).filter((item) => item.type === "negative");
                    setPositive(positiveFilter);
                    setNegative(negativeFilter);
                    setAllowed(true);
                }
            })
            .catch(() => {
                // console.log('catch');
            })
            .finally(() => {
                // console.log('finally');
            })
    }

    useEffect(() => {
        obtainSurveys();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Modal appears on app load
        setIsModalOpen(true);
        //eslint-disable-next-line
    }, [])

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hour = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minutes}:00`;
    };

    const handleMultiChoice = (value) => {
        setSurvey([...survey, value])
        if (survey.some(selectedItem => selectedItem.id === value.id)) {
            const updatedItems = survey.filter(selectedItem => selectedItem.id !== value.id);
            setSurvey(updatedItems);
        } else {
            setSurvey([...survey, value]);
        }
    }
    const handleComment = (event) => {
        let val = event.target.value;
        setComment(val);
    }

    const moveSteps = () => {
        setLoading(true)
        setSteps(steps + 1);
        setLoading(false)
    }

    const addComent = (value) => {
        setSurvey(prevSurvey => {
            const updatedSurvey = [...prevSurvey, value];
            return updatedSurvey;
        });
        setShouldSubmit(true);
    }

    const handleSubmit = () => {
        FetchData.create('/general/submitSurvey', {
            data: JSON.stringify(survey),
            date: getCurrentDateTime(),
            from: parseInt(id) ?? null
        }).then(res => {
            obtainSurveys();
            setLoading(true);
            setIsComplete(true);
            setFinish(true);
        })
            .finally(() => {
                resetState();
            })
    }

    useEffect(() => {
        if (shouldSubmit) {
            handleSubmit();
            setShouldSubmit(false);  // Reset the flag
        }
        //eslint-disable-next-line
    }, [shouldSubmit]);

    const handleComplete = () => {
        setResponseType(null);
        setIsComplete(false);
        setLoading(false);
    };

    const resetState = () => {
        setComment('');
        setSurvey([]);
        setShouldSubmit(false);
        setResponseType(null);
        setFinish(false);
        setSteps(0);
    }

    const timeoutRef = useRef(null);  // Track the timeout

    useEffect(() => {
        if (steps > 0) {
            if (survey?.length === 1 && !timeoutRef.current) {
                timeoutRef.current = setTimeout(() => {
                    FetchData.create('/data/submitSurvey', {
                        data: JSON.stringify(survey),
                        date: getCurrentDateTime(),
                        from: parseInt(id) ?? null
                    }).then(res => {
                        obtainSurveys();
                        setSurvey([]);
                        setSteps(0);
                        timeoutRef.current = null;
                    });
                }, 30000);
            } else {
                return;
            }
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
        //eslint-disable-next-line
    }, [steps, survey.length]);

    const decodeQuestion = (question) => {
        // Step 1: Replace all &amp; with &
        let partiallyDecoded = question.replace(/&amp;/g, '&');

        // Step 2: Decode remaining HTML entities
        const parser = new DOMParser();
        return parser.parseFromString(partiallyDecoded, 'text/html').documentElement.textContent;
    };

    // Function to check if a button is active
    const isActive = (id) => survey.some(item => item.id === id);

    const showSurvey = () => {
        switch (steps) {
            case 0:
                return (
                    <Box sx={emojiContainer}>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'veryBad', dateTime: getCurrentDateTime() }, 'negative')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/2025/03/Emoji-01.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'poor', dateTime: getCurrentDateTime() }, 'negative')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/2025/03/Emoji-02.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'fine', dateTime: getCurrentDateTime() }, 'positive')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/2025/03/Emoji-03.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'good', dateTime: getCurrentDateTime() }, 'positive')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/2025/03/Emoji-04.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'excellent', dateTime: getCurrentDateTime() }, 'positive')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/2025/03/Emoji-05.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <div height={255}>
                        {
                            responseType === 'positive' &&
                            <div>
                                <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} color={'#00b050'}>ÇFARË NDIKOI POZITIVISHT NË EKSPERIENCËN TUAJ?</Typography>
                                <Box sx={{ gridTemplateRows: '1fr 1fr', gridTemplateColumns: '1fr 1fr', display: 'grid', columnGap: '40px', rowGap: '10px' }}>
                                    {positive.map((data) => (
                                        <Box key={data.surveyId}>
                                            <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(data.id) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: data.id, question: data.question, response: true, dateTime: getCurrentDateTime() })}>
                                                {decodeQuestion(data.question)}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '10px' }}>
                                    <Button sx={{ border: 'solid 2px #00b050', borderRadius: '40px', color: '#000', textTransform: 'none' }} onClick={() => moveSteps()}>
                                        Komenti Juaj ...
                                    </Button>
                                    <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleSubmit()}>
                                        Përfundo vlerësimin
                                    </Button>
                                </Box>
                            </div>
                        }
                        {
                            responseType === 'negative' &&
                            <div>
                                <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} color={'#ff3737'}>ÇFARË DUHET TË PËRMIRËSOJMË?</Typography>
                                <Box sx={{ gridTemplateRows: '1fr 1fr', gridTemplateColumns: '1fr 1fr', display: 'grid', columnGap: '40px', rowGap: '10px' }}>
                                    {negative.map((data) => (
                                        <Box key={data.surveyId}>
                                            <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(data.id) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: data.id, question: data.question, response: true, dateTime: getCurrentDateTime() })}>
                                                {decodeQuestion(data.question)}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '10px' }}>
                                    <Button sx={{ border: 'solid 2px #ff3737', borderRadius: '40px', color: '#000', textTransform: 'none' }} onClick={() => moveSteps()}>
                                        Komenti Juaj ...
                                    </Button>
                                    <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleSubmit()}>
                                        Përfundo vlerësimin
                                    </Button>
                                </Box>
                            </div>
                        }
                    </div>
                );
            case 2:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} textAlign={'center'} color={responseType === 'positive' ? '#00b050' : '#ff3737'}>Komenti Juaj</Typography>
                        <TextField rows={10}
                            fullWidth
                            multiline={true}
                            sx={{
                                fieldset: {
                                    border: 'none',
                                    outline: 'none'
                                },
                                marginTop: '20px',
                                input: {},
                                '& .MuiOutlinedInput-root': { outline: 'none !important' },
                                border: 'solid 2px',
                                borderColor: responseType === 'positive' ? '#00b050' : '#ff3737',
                                borderRadius: '6px'
                            }} onChange={handleComment} />
                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '20px' }}>
                            <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => addComent({ question: "User Comment", response: comment, dateTime: getCurrentDateTime() })}>
                                Përfundo vlerësimin
                            </Button>
                        </Box>
                    </Box>
                );
            default:
                return;
        }
    }

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
            {
                !allowed &&
                <Box height={'100vh'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'} sx={{ border: 'solid 2px', padding: '20px', borderRadius: 1, borderColor: '#d32f2f' }}>
                        <ReportOutlinedIcon color='error' sx={{ fontSize: '100px' }} />
                        <Typography variant="body1">
                            You are not allowed here!
                            <br />
                            Ju nuk lejoheni këtu!
                        </Typography>
                    </Box>
                </Box>
            }
            {allowed &&
                <>
                    {
                        isModalOpen && (
                            <div className="modal">
                                <div className="modal-content">
                                    <h2>Permission Request</h2>
                                    <p>This app would like to enter full-screen mode. Do you allow this?</p>
                                    <button onClick={handleGrantPermission}>Allow</button>
                                    <button onClick={handleDenyPermission}>Deny</button>
                                </div>
                            </div>
                        )
                    }
                    <Box sx={{ height: '80%', width: '80%', marginX: 'auto' }}>
                        {
                            isComplete === true ?
                                (
                                    <Box sx={{ width: '35%', height: '35%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                        <Lottie
                                            speed={10}
                                            animationData={animationData}
                                            onLoopComplete={handleComplete}
                                            loop={isComplete}
                                        />
                                        <Typography variant="h4" textAlign={'center'}>Ju faleminderit!</Typography>
                                    </Box>
                                )
                                :
                                <Box></Box>
                        }
                    </Box>
                    <>
                        {
                            finish === false &&
                            <Fade in={loading === false} timeout={1000}>
                                <Box sx={{ paddingBottom: '50px' }}>
                                    <Box width={300} paddingTop={'50px'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginX: 'auto' }}>
                                        <Box component={'img'} src='https://hyper-active.com/images/HyperActive_dark_Logo.svg' loading='lazy' sx={{ width: '100%', objectFit: 'cover' }} />
                                    </Box>
                                    <Box sx={{ marginTop: '25px' }}>
                                        <Box sx={{ width: '90%', marginX: 'auto' }}>
                                            {showSurvey()}
                                        </Box>
                                    </Box>
                                </Box>
                            </Fade>
                        }
                    </>
                </>
            }
        </Box>
    )
}