import { useState, useEffect } from "react";
import Header from "./Header";
import HeaderMobile from "./MobileHeader";
import { useLocation } from "react-router-dom"

function HeaderHandler() {
  const [width, setWidth] = useState(window.innerWidth);
  const [
    // openCategories, 
    setOpenCategories] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 1000;

  const menu = [
    {
      title: "Karting",
      url: "/activities/karting",
    },
    // {
    //     title: "HyperStrike",
    //     url: "/activities/hyperstrike",
    // },
    {
      title: "Laser Tag",
      url: "/activities/laser",
    },
    {
      title: "Arcade Games",
      url: "/activities/arcade",
    },
    {
      title: "Virtual Reality",
      url: "/activities/virtual",
    },
    // {
    //   title: "Axe Throwing",
    //   url: "/activities/axe",
    // },
    {
      title: "Escape Rooms",
      url: "/activities/escape",
    },
    {
      title: "Bowling",
      url: "/activities/bowling",
    },
    {
      title: "Lodra",
      url: "/activities/eli",
    },
    {
        title: "Sports Bar",
        url: "/activities/recharge",
    },
  ];

  const location = useLocation();

  return (
    <>
      {
        location.pathname.includes('/menu') ? ''
        : 
        isMobile ? (
          <HeaderMobile menu={menu} setOpenCategories={setOpenCategories} />
        ) : (
          <Header menu={menu} setOpenCategories={setOpenCategories} />
        )
      }
    </>
  );
}

export default HeaderHandler;
