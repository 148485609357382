import { useSelector } from "react-redux";
import {
    Link
    // useParams 
} from "react-router-dom";
import style from "./Price.module.css";
// import lng from "../../../utils/language";
import TextWrapper from "../../../components/general/text/TextWrapper";
import { FiShoppingCart } from "react-icons/fi";
// import constants from "../../../utils/consts";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function Price() {
    //   let { type } = useParams();
    const { language } = useSelector((state) => state.language);

    const price = [
        // {
        //     id: "739732",
        //     identifier: "axe",
        //     title: { eng: "Axe Throwing", alb: "Sakica" },
        //     shortDesc: {
        //         eng: "Hours & Dates are subject to weather conditions and can change without notice.",
        //         // alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
        //     },
        //     desc: "Personat nën moshën 18 vjeç nuk mund të luajnë.",
        //     price: [
        //         {
        //             title: "Një Biletë",
        //             price: "15",
        //             time: "30 min",
        //             desc: "Për Lane",
        //         },
        //     ],
        // },
        // {
        //     id: "739734",
        //     identifier: "darts",
        //     title: { eng: "Darts", alb: "Pikado" },
        //     shortDesc: {
        //         eng: "Hours & Dates are subject to weather conditions and can change without notice.",
        //         // alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
        //     },
        //     price: [
        //         {
        //             title: "Një Biletë",
        //             price: "10",
        //             time: "60 min",
        //             desc: "Për Lane",
        //         },
        //     ],
        // },
        {
            id: "739676",
            identifier: "karting",
            title: { eng: "Gokart", alb: "Gokart" },
            shortDesc: {
                eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                // alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            desc: "Mosha minimale për të garuar së bashku me një person përgjegjës në GoKart me dy ulëse është 8+ vjeç.",
            price: [
                {
                    title: "Një Biletë",
                    price: "15",
                    time: "Standart  Race",
                    desc: "Për Person",
                },
            ],
        },
        {
            id: "739728",
            identifier: "bowling",
            title: { eng: "Bowling", alb: "Bowling" },
            shortDesc: {
                eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                // alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            price: [
                {
                    title: "Një Biletë",
                    price: "3.5",
                    time: "30 min",
                    desc: "Për Person",
                },
                {
                    title: "Një Biletë",
                    price: "2.5",
                    time: "30 min",
                    desc: "Për Person",
                    helperText: 'deri ne orën 16:00'
                },
            ],
        },
        {
            id: "739733",
            identifier: "virtual",
            title: { eng: "Escape Room", alb: "Escape Room" },
            shortDesc: {
                eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            price: [
                // {
                //   title: "Një Biletë",
                //   price: "45",
                //   time: "1 person",
                //   desc: "",
                // },
                {
                    title: "Një Biletë",
                    price: "22.5",
                    time: "2 persona",
                    desc: "Për Person",
                },
                {
                    title: "Një Biletë",
                    price: "15",
                    time: "3 persona",
                    desc: "Për Person",
                },
                {
                    title: "Një Biletë",
                    price: "12",
                    time: "4 persona",
                    desc: "Për Person",
                },
                {
                    title: "Një Biletë",
                    price: "10",
                    time: "5 persona",
                    desc: "Për Person",
                },
                {
                    title: "Një Biletë",
                    price: "8",
                    time: "6 persona",
                    desc: "Për Person",
                },
            ],
        },
        {
            id: "739734",
            identifier: "laser",
            title: { eng: "Laser Tag", alb: "Laser Tag" },
            shortDesc: {
                eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                // alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            desc: "Në të njejtin sesion mund të luajnë deri në 20 persona, andaj në arenën e betejës mund të jeni edhe me persona të tjerë.",
            price: [
                {
                    title: "Një Biletë",
                    price: "8",
                    time: "Një lojë",
                    desc: "Për Person",
                },
            ],
        },
        {
            id: "",
            identifier: "vr",
            title: { eng: "Virutal Reality", alb: "Virutal Reality" },
            shortDesc: {
                //   eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                //   alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            desc: "Në të njejtin sesion mund të luajnë deri në 4 persona, andaj në VR mund të jeni edhe me persona të tjerë.",
            price: [
                {
                    title: "VR Holo Gape per 1 personë",
                    price: "5",
                    time: "Një session",
                    desc: "Për Person",
                },
            ],
        },
        {
            id: "",
            identifier: "vr",
            title: { eng: "Billiards", alb: "Bilardo" },
            shortDesc: {
                //   eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                //   alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            // desc: "Në të njejtin sesion mund të luajnë deri në 4 persona, andaj në VR mund të jeni edhe me persona të tjerë.",
            price: [
                {
                    // title: "VR Holo Gape per 1 personë",
                    price: "10",
                    time: "60min",
                    desc: "Për Lojë",
                },
            ],
        },
        {
            id: "",
            identifier: "Shuffle Board",
            title: { eng: "Shuffle Board", alb: "Shuffle Board" },
            shortDesc: {
                //   eng: "Hours & Dates are subject to weather conditions and can change without notice.",
                //   alb: "Orari dhe datat janë subjekt i kushteve të motit dhe mund të ndryshojnë pa paralajmërim.",
            },
            // desc: "Në të njejtin sesion mund të luajnë deri në 4 persona, andaj në VR mund të jeni edhe me persona të tjerë.",
            price: [
                {
                    // title: "VR Holo Gape per 1 personë",
                    price: "10",
                    time: "60min",
                    desc: "Për Lojë",
                },
            ],
        },
    ];
    const color = [
        "#f07379",
        "#faa000",
        "#321eff",
        "#fff",
        "#2D8CFF",
        "#AF46FA",
        "#faa000",
    ];

    return (
        <>
            {/* <Box
                className={style.miniBanner}
                style={{ backgroundImage: `url(${constants.imgPath}simpBanner.png)` }}
            ></Box> */}
            <div className="container">
                <Box sx={{marginTop: '50px'}}>
                    <TextWrapper title="Lista e çmimeve" />
                </Box>
            </div>
            {price.map((i, j) => {
                return (
                    <div className={style.priceLayout} key={j}>
                        <div className="container">
                            <div className={style.spaceBetween}>
                                <h1 style={{ color: color[j] }}>{i.title[language]}</h1>
                                {i.id && (
                                    <Link to={`/reservation/${i.id}`} className={style.iconContainer}>
                                        <FiShoppingCart size={20} />
                                    </Link>
                                )}
                            </div>
                            <h2>Një Biletë</h2>
                            {i.price.map((p, index) => {
                                return (
                                    <div className={style.priceWrapper} key={index}>
                                        <p>{p.time}</p>
                                        <Box
                                            sx={{
                                                minWidth: "calc(100% - 190px) !important",
                                            }}
                                        >
                                            <Box
                                                component={"span"}
                                                sx={{
                                                    width: "90%",
                                                    marginX: "auto",
                                                    border: "solid 1px #FFF",
                                                }}
                                            ></Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'relative'
                                            }}
                                        >
                                            <Typography
                                                component={"p"}
                                                fontSize={20}
                                                fontWeight={"400"}
                                            >
                                                {p.price} &euro;
                                            </Typography>
                                            <Typography component={'span'} fontSize={'13px'}>{p.desc}</Typography>
                                            {
                                                p.helperText &&
                                                <Typography component={'span'} fontSize={'13px'} sx={{ position: 'absolute', top: '50px', width: '114px' }}>{p.helperText}</Typography>
                                            }
                                        </Box>
                                    </div>
                                );
                            })}
                            {i.desc && <p>{i.desc}</p>}
                        </div>
                    </div>
                );
            })}
        </>
    );
}
export default Price;
