import React from "react";
import Select from "react-select";
import style from "./Input.module.css";

function SelectOptions(props) {
//   let getColor = (isValid) => (isValid ? "gray" : "red");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: "10px 50px 10px 10px",
      cursor: "pointer",
      color: "#000",
      fontSize: "14px",
      fontWeight: state.isSelected ? "bold" : "normal",
      backgroundColor: state.isSelected ? "#efefef" : "white",
      margin: "0px",
      width: "100%",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "fit-content",
      padding: "0px",
      margin: "0px",
      boxShadow: "none",
      borderRadius: "30px",
      overflow: "hidden",
      display: "flex",
    }),
    control: () => ({
      display: "flex",
      backgroundColor: "#120f2e",
      padding: "7px",
      borderRadius: "20px",
      outline: "none",
      color: "#fff",
      fontSize: "14px",
      border: "1px solid #120f2e;",
      // none of react-select's styles are passed to <Control />
      // width: 200,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const updateGender = (gender) => {
    props.setFieldValue("gender",gender.value)
  }

  return (
    <div className={style.input}>
      {props.label && <label>{props.label}</label> }
      <Select
        options={props.options}
        styles={customStyles}
        onChange={(gender) => updateGender(gender)}
      />
      {/* {props.errorMessage && props.touched ? (
        <span className={style.error}>{props.errorMessage}</span>
      ) : null} */}
    </div>
  );
}

export default SelectOptions;
