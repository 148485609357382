import { useState } from "react";
import { api } from "../../../api/config";
import { useSelector } from "react-redux";
import style from "./Password.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import lng from "../../../utils/language";
import Wrapper from "../../../components/general/Wrapper";
import Input from "../../../components/form/Input";
import Alert from "../../../components/general/message/AlertMessage";
import LottieMessage from "../../../components/general/message/LottieMessage";

function ForgetPassword() {
  const { language } = useSelector((state) => state.language);
  const [type, setType] = useState("");

  const passwordSchema = Yup.object().shape({
    email: Yup.string()
      .email(lng[language]["invalidMail"])
      .required(lng[language]["required"]),
  });

  return (
    <>
      {type !== "success" ? (
        <Wrapper>
          {" "}
          <Formik
            initialValues={{ email: "" }}
            validationSchema={passwordSchema}
            onSubmit={(values, { setSubmitting }) => {
              api
                .post("auth/forgetPassword", { email: values.email })
                .then((response) => {
                  setType(response.data.severity);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          >
            {({ errors, handleChange, handleSubmit, touched }) => (
              <form onSubmit={handleSubmit} className={style.loginForm}>
                {type === "error" && (
                  <Alert
                    message={lng[language]["forgetPasswordError"]}
                    type="errorMessages"
                  />
                )}
                <div className="mb10">
                  <Input
                    placeholder={lng[language]["email"]}
                    name="email"
                    type="text"
                    onChange={handleChange}
                    errorMessage={errors.email}
                    label={lng[language]["writeMail"]}
                    touched={touched.email}
                  />
                </div>
                <button type="submit" className="btn">
                  {lng[language]["sendLink"]}
                </button>
              </form>
            )}
          </Formik>
        </Wrapper>
      ) : (
        <LottieMessage
          text="Linku për ndryshimin e fjalëkalimit u dërgua në email adresën tuaj!"
          type="mail"
        />
      )}
    </>
  );
}
export default ForgetPassword;
