import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api/config";
import { Decimal } from "decimal.js";
// import { clear } from "tsparticles-engine";
const initialState = {
    // GENERAL
    isLoading: false,
    errorMessage: "",
    // PRICE
    total: 0,
    items: [],
    priceNoDiscount: 0,
    // PLAYERS
    playerInfo: [],
    // COUPONS
    coupon: "",
    discount: 0,
    errorCoupon: "",
    couponState: null,
    appliedCoupon: false,
    isCouponLoading: false
};

export const applyCoupon = createAsyncThunk("cart/applyCoupon", async(data) => {
    const response = await api.post("reservation/coupon", data)
    return response?.data;
}) 

// INITIALIZE CARTING PRODUCTS IF USER IS LOGGED IN
export const initializeCart = createAsyncThunk("cart/initializeCart", async (_, { rejectWithValue }) => {

    let token = localStorage.getItem('token');
try {
    const response = await api.post("cart/initializeCart", {
        token: token
    });
    localStorage.removeItem('cart');
    return response?.data;    
} catch (error) {
    return
}

})

// GET DATA WHEN A GAME IS SELECTED
export const cartData = createAsyncThunk("cart/addToCart", async ({resInfo, slots}, rejectWithValue) => {
    let localStorageCart = JSON.parse(localStorage.getItem("cart"));
    const cartData = {
        type: "pending",
        data: {
            date: resInfo?.selectedDate,
            chargeParams: {
                time: resInfo?.time,
                players: resInfo.adult >= 1 ? resInfo.adult : resInfo.children,
                bonus: 0,
                dateTime:
                    resInfo.selectedDate +
                    "-" +
                    slots[0].selectedSlot[0].timeFrom +
                    ":00",
            },
            slots: slots,
        },
        name: resInfo?.name,
    };
    const response = await api.post("reservation/reserve", cartData);
    if (response?.data?.success === false) {
        return rejectWithValue(response?.data?.success);
    }
    if (!localStorageCart) localStorageCart = [];
    if (localStorageCart.length !== 10) {
        localStorageCart.push({
            name: resInfo?.name,
            reservationIds: response.data.id,
        });
        localStorage.setItem("cart", JSON.stringify(localStorageCart));
        const cardDetails = {
            reservationIds: response.data.id,
            name: resInfo.name,
            unitPrice: response.data.unitPrice,
            price: response.data.price,
            data: slots,
            time: resInfo.time,
            date: resInfo.selectedDate,
            chargeParams: {
                time: resInfo.time,
                playerNo: resInfo.players,
                bonus: 0,
                dateTime:
                    resInfo.selectedDate +
                    "-" +
                    slots[0].selectedSlot[0].timeFrom +
                    ":00",
            },
            success: true
        };
        return { cardDetails };
    } else {
        const cardDetails = {
            success: false
        };
        return { cardDetails };
    }
});

// REMOVE DATA WHEN A GAME IS SELECTED
export const removeCartProducts = createAsyncThunk("/cart/removeFromCart", async (data) => {
    const removeData = {
        type: "cancel",
        ids: data?.product.reservationIds
    };
    const response = await api.post("reservation/reserve", removeData);
    if (response?.data) {
        return data;
    }
})

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        removeAll: () => initialState,
        clearCart: () => initialState,
        addPlayerData: (state, { payload }) => {
            const data = payload;
            return {
                ...state,
                playerInfo: data
            }
        },
        removePlayerData: (state) => {
            return {
                ...state,
                items: initialState.items,
                total: initialState.total,
                coupon: initialState.coupon,
                playerInfo: initialState.playerInfo,
                appliedCoupon: initialState.appliedCoupon,
                priceNoDiscount: initialState.priceNoDiscount,
            }
        },
        removeCoupon: (state) => {
            return {
                ...state,
                coupon: initialState.coupon,
                discount: initialState.discount,
                appliedCoupon: initialState.appliedCoupon,
                errorCoupon: initialState.errorCoupon
            }
        }
    },
    extraReducers: (builder) => {
        // LOAD CART WHEN PAGE OPENS
        builder.addCase(initializeCart.fulfilled, (state, action) => {
            const data = action.payload;
            if (data !== null && data?.success !== false) {
                // STATES AND CONTSANTS
                let localStorageCart = JSON.parse(localStorage.getItem("cart"));
                let priceNoDiscount = initialState.priceNoDiscount;
                let errorMsg = initialState.errorMessage;
                let newItems = [...initialState.items];
                let totalPriceVal = 0;
                // let price = initialState.total;
                if (!localStorageCart) localStorageCart = [];
                if (data?.cart) {
                    // eslint-disable-next-line
                    Object.values(data?.cart).map((products) => {
                        if (localStorageCart.length !== 10) {
                            localStorageCart.push({
                                name: products?.name,
                                reservationIds: products?.reservationIds,
                            })
                        }
                        newItems.push({
                            reservationIds: products.reservationIds,
                            name: products.name,
                            unitPrice: products.unitPrice,
                            price: products.price,
                            data: products.data,
                            time: products.time,
                            date: products.date,
                            chargeParams: products.chargeParams,
                            success: true
                        });
                        priceNoDiscount = new Decimal(priceNoDiscount).add(products.price);
                        if (initialState.appliedCoupon) {
                            let discountItem = products.price - (products.price * initialState.discount) / 100;
                            totalPriceVal = new Decimal(totalPriceVal).add(discountItem);
                        } else {totalPriceVal = new Decimal(totalPriceVal).add(products.price);} 
                    })
                }
                localStorage.setItem("cart", JSON.stringify(localStorageCart));
                return {
                    ...state,
                    items: newItems,
                    priceNoDiscount: priceNoDiscount.toFixed(2),
                    total: totalPriceVal.toFixed(2),
                    isLoading: false,
                    errorMessage: errorMsg,
                };
            } else {
                return {
                    ...state,
                    isLoading: false
                }
            }

        });
        builder.addCase(initializeCart.pending, (state) => {
            return {
                ...state,
                isLoading: true
            }
        });
        builder.addCase(initializeCart.rejected, (state) => {
            return {
                ...state,
                isLoading: false,
                errorMessage: "No Items on cart",
            }
        });

        // ADDING PROCUCTS
        builder.addCase(cartData.fulfilled, (state, action) => {
            let data = action.payload.cardDetails;
            if (action?.payload?.cardDetails?.success) {
                // CURRENT DATA
                let price = state.total;
                let priceNoDiscount = state.priceNoDiscount;
                let totalPriceVal = 0;
                let errorMsg = state.errorMessage;
                let newItems = [...state.items];

                if (state.items.length !== 10) {
                    if (!newItems.find((item) => item.chargeParams.dateTime === data.chargeParams.dateTime)) {
                        newItems.push(data);
                    } else {
                        newItems = [data];
                    }
                } else {
                    errorMsg = "Only 10 Activities can be bought at once"
                }
                priceNoDiscount = new Decimal(priceNoDiscount).add(data.price);
                if (state.appliedCoupon) {
                    let discountItem = data.price - (data.price * state.discount) / 100;
                    totalPriceVal = new Decimal(price).add(discountItem);
                } else totalPriceVal = new Decimal(price).add(data.price);
                return {
                    ...state,
                    items: newItems,
                    priceNoDiscount: priceNoDiscount.toFixed(2),
                    total: totalPriceVal.toFixed(2),
                    isLoading: false,
                    errorMessage: errorMsg,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    errorMessage: "Only 10 Activities can be bought at once",
                };
            }
        });
        builder.addCase(cartData.pending, (state) => {
            return { ...state, isLoading: true };
        });
        builder.addCase(cartData.rejected, (state) => {
            return {
                ...state,
                errorMessage: "Something went wrong. Try again later",
                isLoading: false,
            };
        });

        // REMOVING PRODUCTS
        builder.addCase(removeCartProducts.fulfilled, (state, action) => {
            const { product } = action.payload;
            // CURRENT DATA
            let price = state.total;
            let priceNoDiscount = state.priceNoDiscount;
            let totalPriceVal = 0;
            let errorMsg = state.errorMessage;
            let newItems = [...state.items];
            let localStorageCart = JSON.parse(localStorage.getItem("cart"));
            newItems.splice(newItems.findIndex((arrow) => arrow.reservationIds[0] === product.reservationIds[0]), 1);
            priceNoDiscount = new Decimal(priceNoDiscount).sub(product.price);
            if (state.appliedCoupon) {
                let discountItem = product.price - (product.price * state.discount) / 100;
                totalPriceVal = new Decimal(price).sub(discountItem);
            } else totalPriceVal = new Decimal(price).sub(product.price);
            localStorageCart.splice(localStorageCart.findIndex((arrow) => arrow.reservationIds[0] === product.reservationIds[0]), 1);
            localStorage.setItem("cart", JSON.stringify(localStorageCart));
            return {
                ...state,
                items: newItems,
                priceNoDiscount: priceNoDiscount.toFixed(2),
                total: totalPriceVal.toFixed(2),
                isLoading: false,
                errorMessage: errorMsg,
            };
        })
        builder.addCase(removeCartProducts.pending, (state) => {
            return {
                ...state,
                isLoading: true
            }
        })
        builder.addCase(removeCartProducts.rejected, (state) => {
            return {
                ...state,
                errorMessage: 'Please try again later!',
                isLoading: false
            }
        });

        // APPLYING COUPONS
        builder.addCase(applyCoupon.fulfilled, (state, action) => {
            const data = action.payload;
            const language = localStorage.getItem('lng');
            if (data.success === false) {
                return {
                    ...state,
                    errorCoupon: data.msg[language],
                    isCouponLoading: false,
                    couponState: data.success
                }
            }if (data.success === true) {
                let discountItem = state.total - (state.total * data.coupon.discountPercentage) / 100;
                return {
                    ...state,
                    appliedCoupon: true,
                    priceNoDiscount: state.total,
                    total: discountItem,
                    coupon: data.coupon.coupon,
                    discount: data.coupon.discountPercentage,
                    errorCoupon: data.msg[language],
                    isCouponLoading: false,
                    couponState: data.success
                }
            }
        });
        builder.addCase(applyCoupon.pending, (state, action) => {
            return {
                ...state,
                isCouponLoading: true
            }
        });
        builder.addCase(applyCoupon.rejected, (state, action) => {

            // console.log('fail');

        });
        
    },
});

export const {
    addProcuts,
    clearCart,
    // applyCoupon,
    removeAll,
    addPlayerData,
    removePlayerData,
    removeCoupon
} = cartSlice.actions;

export default cartSlice.reducer;
