import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    majorError: false,
}

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError: (state, action) => {
            return {
                ...state,
                majorError: action.payload
            }
        }
    },
})

export const { setError } = errorSlice.actions

export default errorSlice.reducer