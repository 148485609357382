import React, { useState } from "react";
import { 
    // useDispatch, 
    useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import style from "./Profile.module.css";
import parse from "date-fns/parse";
import lng from "../../../utils/language";
import Grid from "../../../layout/grid/Grid";
import Input from "../../../components/form/Input";
import SelectOptions from "../../../components/form/SelectOptions";
import DateWrapper from "../../../components/form/DatePicker";
import Alert from "../../../components/general/message/AlertMessage";
import Wrapper from "../../../components/general/Wrapper";
// import { Link } from "react-router-dom";
import OptionList from "../../../layout/user/dashboard/OptionsList";
import { api } from "../../../api/config";
// import { updateUser } from "../../../redux/app/auth/actions";

const Profile = () => {
  const { language } = useSelector((state) => state.language);
  const { user } = useSelector((state) => state?.auth);
  const [msg, setMsg] = useState(null);
//   const dispatch = useDispatch();

    // console.log(user);

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
    lastname: Yup.string()
      .min(2, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),

    email: Yup.string()
      .email(lng[language]["invalidMail"])
      .required(lng[language]["required"]),
    mob: Yup.string()
      .required(lng[language]["required"])
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        lng[language]["invalidPhone"]
      ),
    dob: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = parse(originalValue, "dd.MM.yyyy", new Date());
        return result;
      })
      .typeError(lng[language]["invalid"])
      .required(lng[language]["required"])
      .min("1969-11-13", "Date is too early"),
    gender: Yup.mixed().oneOf(["f", "m"], lng[language]["optionsError"]),
    city: Yup.string()
      .min(4, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
    country: Yup.string()
      .min(4, lng[language]["short"])
      .max(50, lng[language]["long"])
      .required(lng[language]["required"]),
  });

  const options = [
    { value: "f", label: "Female" },
    { value: "m", label: "Male" },
  ];
  return (
    <div className={`${style.form}`}>
      <OptionList active="profile" />
      <Wrapper removeLogo={true}>
        <h1>
          {user?.name} {user?.lastname}
        </h1>
        <p>Të dhënat e juaja</p>
        <Formik
          enableReinitialize
          initialValues={{
            name: user?.name,
            gender: "f",
            lastname: user?.lastname,
            email: user?.email,
            mob: user?.phone,
            dob: user?.dob,
            password: "",
            city: user?.city,
            country: user?.country,
          }}
          validationSchema={SignupSchema}
          onChange={(values) => {}}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            //UPDATE USER VALUES
            api
              .post("user/update", values)
              .then((response) => {
                  if(response.data.type === "success"){
                    //Update User in store and localstorage
                    
                    // dispatch(updateUser(response.data.token, response.data.user, response.data.exp));
                  }

                 setMsg({
                  type: response.data.type,
                  message: response.data.msg,
                });
                if (response.data.type !== "success") {
                  setTimeout(() => {
                    setMsg(null);
                  }, "5000");
                  //Scroll to top
                } else {
                  setTimeout(() => {
                    resetForm();
                  }, "10000");
                }
                window.scrollTo(0, 0);
              })
              .catch((error) => {
                console.error(error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {msg?.type === "error" && (
                <Alert message={msg.message} type="errorMessages" />
              )}
                {msg?.type === "success" && (
                <Alert message={msg.message} type="successMessages" />
              )}
              <Grid no="2">
                <Input
                  label={"Emri"}
                  placeholder={lng[language]["name"]}
                  name="name"
                  type="text"
                  onChange={handleChange}
                  errorMessage={errors.name}
                  touched={touched.name}
                  value={values.name}
                />
                <Input
                  label={"Mbiemri"}
                  placeholder={lng[language]["lastname"]}
                  name="lastname"
                  type="text"
                  onChange={handleChange}
                  errorMessage={errors.lastname}
                  touched={touched.lastname}
                  value={values.lastname}
                />
                <Input
                  label={"Email Adresa"}
                  placeholder={lng[language]["email"]}
                  name="email"
                  type="email"
                  onChange={handleChange}
                  errorMessage={errors.email}
                  touched={touched.email}
                  value={values.email}
                />
                <Input
                  label={"Numri kontaktues"}
                  placeholder={lng[language]["mob"]}
                  name="mob"
                  type="text"
                  onChange={handleChange}
                  errorMessage={errors.mob}
                  touched={touched.mob}
                  value={values.mob}
                />
                <Input
                  label={"Qyteti"}
                  placeholder={lng[language]["city"]}
                  type="text"
                  name="city"
                  onChange={handleChange}
                  errorMessage={errors.city}
                  touched={touched.city}
                  value={values.city}
                />
                <Input
                  label={"Shteti"}
                  placeholder={lng[language]["country"]}
                  type="text"
                  name="country"
                  onChange={handleChange}
                  errorMessage={errors.country}
                  touched={touched.country}
                  value={values.country}
                />
                <DateWrapper
                  label={"Data e lindjes"}
                  placeholder={lng[language]["dob"]}
                  name="dob"
                  type="text"
                  onChange={handleChange}
                  errorMessage={errors.dob}
                  touched={touched.dob}
                  setFieldValue={setFieldValue}
                />
                <SelectOptions
                  label={"Gjinia"}
                  options={options}
                  setFieldValue={setFieldValue}
                  errorMessage={errors.gender}
                  touched={touched.gender}
                />
              </Grid>
              <button type="submit" className="btn">
                {lng[language]["change"]}
              </button>
            </form>
          )}
        </Formik>
      </Wrapper>
    </div>
  );
};

export default Profile;
