import { useState, useEffect } from "react";
import Loader from "../../../components/general/loader/Loader";
import style from "./Reservation.module.css";
import StepAdvisor from "../../../layout/reservation/general/steps/StepAdvisor";
import Grid from "../../../layout/grid/Grid";

import PickPlayers from "../../../layout/reservation/general/players/ReservationPickPlayers";
import PickSlots from "../../../layout/reservation/general/slots/PickSlots";

import ShoppingCart from "../../../layout/reservation/ShoppingCart";
// import PickSlots from "../../../layout/reservation/general/slots/PickSlots";
import moment from "moment";
import { api } from "../../../api/config";
import { useParams } from "react-router-dom";
import ShoppingCartList from "../../../layout/reservation/ShoppingCartList";
import MobileShoppingCart from "../../../layout/reservation/MobileShoppingCart";
import PlayersData from "../../../layout/reservation/general/players/PlayersData";
import PickSlotsKarting from "../../../layout/reservation/general/slots/PickSlotsKarting";
import lng from "../../../utils/language";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

function ReservationModule() {
    const params = useParams();
    const { language } = useSelector((state) => state.language);
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reservationInfo, setReservationInfo] = useState({
        adult: 0,
        children: 0,
        otherParams: {
            adult: 0,
            children: 0,
        },
        selectedDate: moment().format("YYYY-MM-DD"),
        players: 0,
        time: 0,
        name: "",
        activeSlot: [],
    });
    const [reservationParams, setReservationParams] = useState([]);
    const [error, setError] = useState(false);

    //Get Players Data
    useEffect(() => {
        setLoading(true);
        api.post("reservation/activityData", { id: params.id })
            .then((res) => {
                if (res.data.success) {
                    setReservationInfo((prevState) => ({
                        ...prevState,
                        time: res?.data?.params[0]?.minTime,
                        adult: Number(res?.data?.params[0]?.minValue) === 1 ? 0 : Number(res?.data?.params[0]?.minValue),
                        min: res?.data?.params[0]?.minTime,
                        max: res?.data?.params[0]?.maxTime,
                        name: res?.data?.params[0]?.name,
                        timeStepValue: res?.data?.params[0]?.timeStepValue,
                        pickPlayerData: res?.data?.params[0]?.pickPlayerData,
                    }));
                    setReservationParams(res?.data?.params);
                }else{
                    setError(true);
                }
            })
            .catch((error) => {
                // console.error(error);
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line
    }, []);

    const updatePage = (nextPage = true) => {
        if (nextPage) setStep(step + 1);
        else setStep(step - 1);
        window.scrollTo(0, 0);
    };

    const stepComponents = [
        <PickPlayers
            setReservationInfo={setReservationInfo}
            reservationInfo={reservationInfo}
            updatePage={updatePage}
            reservationParams={reservationParams}
        />,
        <PickSlots
            setReservationInfo={setReservationInfo}
            reservationInfo={reservationInfo}
            updatePage={updatePage}
            reservationParams={reservationParams}
            type={params.id}
        />,
        <ShoppingCartList />,
    ];

    const stepComponentExtra = [
        <PickPlayers
            setReservationInfo={setReservationInfo}
            reservationInfo={reservationInfo}
            updatePage={updatePage}
            reservationParams={reservationParams}
        />,
        <PlayersData
            setReservationInfo={setReservationInfo}
            reservationInfo={reservationInfo}
            updatePage={updatePage}
            reservationParams={reservationParams}
        />,
        <PickSlotsKarting
            setReservationInfo={setReservationInfo}
            reservationInfo={reservationInfo}
            updatePage={updatePage}
            reservationParams={reservationParams}
            type={params.id}
        />,
        <ShoppingCartList />,
    ];

    const steps = [lng[language]["playerNo"], lng[language]["timeSlot"], lng[language]["payment"]];
    const steps2 = [
        lng[language]["playerNo"],
        lng[language]["playerData"],
        lng[language]["timeSlot"],
        lng[language]["payment"],
    ];

    //GET USER NAMES ON KARTING STEP2
    return (
        <Box className={`container ${style.reservationWrapper}`} sx={{ color: '#000' }}>
            {loading ? (
                <Loader />
            ) : (
                <>
                     

                    {
                        error === false ?
                        (
                            !reservationInfo.pickPlayerData ? (
                                <>
                                    <StepAdvisor setStep={setStep} step={step} stepNames={steps} />
                                    <MobileShoppingCart />
                                    <div className={style.reservationWrapper}>
                                        <Grid no={"Custom2"}>
                                            <div className={style.reservationLayout}>
                                                {stepComponents[step]}
                                            </div>
                                            <ShoppingCart />
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <StepAdvisor setStep={setStep} step={step} stepNames={steps2} />
                                    <MobileShoppingCart />
                                    <div className={style.reservationWrapper}>
                                        <Grid no={"Custom2"}>
                                            <div className={style.reservationLayout}>
                                                {stepComponentExtra[step]}
                                            </div>
                                            <ShoppingCart />
                                        </Grid>
                                    </div>
                                </>
                            )
                        )
                        :
                        <Box paddingTop={{md: 10, xs: 5}} color={'#FFF'}>
                            <Typography variant="h5" fontWeight={'bold'} textAlign={'center'} fontSize={{md: '30px', xs: '20px'}}>Something Went wrong! Please try again later.</Typography>
                        </Box>
                    }
                </>
            )}
        </Box>
    );
}
export default ReservationModule;
