import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { removeToken, setToken } from '../../api/utils';
import { api } from '../../api/config';
import { addPlayerData } from '../cart/Cart';

export const initialState = {
    user: null,
    authenticated: false,
    token: null,
    isLoading: false,
    message: null,
    exp: null,
};

export const validateUser = createAsyncThunk("auth/validate", async (data, thunkAPI) => {
    const response = await api.post("/auth/validate", {
        token: data.token,
        user: data.user,
        exp:data.exp
    });
    if (response?.data?.success) {
        thunkAPI.dispatch(addPlayerData(response?.data?.user));
    }
    return response?.data
});

export const loginUsers = createAsyncThunk("auth/login", async (values, thunkAPI) => {
    const response = await api.post("/auth/login", {
        email: values.email,
        password: values.password,
        externalType:values.externalType
    });

    if (response?.data?.success === true) {
        thunkAPI.dispatch(addPlayerData(response?.data?.user));
        return response?.data
    }else{
        return thunkAPI.rejectWithValue(response?.data)
    }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    
    reducers: {
        // LOGOUT USER
        logOutUser: () => {
            removeToken();
            return initialState;
        },
        // UPDATE USER
        updateUser: (state, action) => {
            return {
                ...state,
                authenticated: true,
                user: action.user,
                token: action.token,
                exp: action.exp,
                isLoading: false,
            }
        },
        // UPDATE TOKEN WHEN IT EXPIRES
        updateToken: (state, action) => {
            return {
                ...state,
                exp: action.payload.exp,
                token: action.payload.token,
                isLoading: false,
            };
        },
        setLoginMessage: (state, {payload}) => {
            return {
                ...state,
                message: payload
            }
        }
    },

    extraReducers: (builder) => {
        // Check if user is loged in
        builder.addCase(validateUser.fulfilled, (state, action) => {
            const validation = action.payload;
            if (validation?.success && validation?.isLogged) {
                return {
                    ...state,
                    user: validation.user,
                    authenticated: true,
                    isLoading: false,
                }                    
            } else {
                return {
                    ...state,
                    isLoading: false,
                } 
            }
        })
        builder.addCase(validateUser.pending, (state, action) => {
            return {
                ...state,
                isLoading: true,
            }
        })
        builder.addCase(validateUser.rejected, (state) => {
            removeToken();
            return {
                ...state,
                user: null,
                authenticated: false,
                token: null,
                isLoading: false,
                message: null,
                exp: null,
            }
        })

        // LOGIN USER
        builder.addCase(loginUsers.fulfilled, (state, {payload}) => {
            console.log(payload.user,
                payload.token,
                payload.exp);
            setToken(
                payload.user,
                payload.token,
                payload.exp,
            );
            return {
                ...state,
                isLoading: false,
                authenticated: true,
                exp: payload.exp,
                user: payload.user,
                token: payload.token,
            }
        });

        builder.addCase(loginUsers.rejected, (state, {payload}) => {
            console.log(payload);
            const language = localStorage.getItem('lng');
            return {
                ...state,
                message: payload?.message[language],
                isLoading: false
            }
        });

        builder.addCase(loginUsers.pending, (state) => {
            return {
                ...state,
                message: null,
                isLoading: true
            }
        });
    }
}
)

export const { logOutUser, updateUser, updateToken, setLoginMessage } = authSlice.actions

export default authSlice.reducer