import { Box } from "@mui/material";
import style from "./StepAdvisor.module.css";

function StepAdvisor({ step, stepNames }) {

    return (
        <div>
            <ul className={style.steps}>
                {stepNames.map((i, j) => {
                    return (
                        <li key={j}>
                            <p>{i}</p>
                            <Box component={'span'} sx={step === j ? { backgroundColor: '#faa000 !important' }: {}} className={step === j ? style.active : style.list}></Box>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
export default StepAdvisor;
