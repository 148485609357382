import React from 'react';
// import { Stepper, Step, StepLabel, Button as MUIButton, Box } from '@mui/material';
// import { Button as AntdButton, message } from 'antd';
function CustomStepper({ getStepContent, activeStep,setActiveStep, steps }) {

    return (
        <div style={{ width: '50%', margin: 'auto', marginTop: '100px' }}>

            {/* <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index} sx={{ '.MuiStepLabel-label': { color: '#FFF' } }}>
                        <StepLabel>{}</StepLabel>
                    </Step>
                ))}
            </Stepper> */}

            <p>{getStepContent(activeStep)}</p>
        </div>
    );
}

export default CustomStepper;