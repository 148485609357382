import style from "./CartProducts.module.css";
import Lottie from "lottie-react";
import loader from "../../utils/lotties/booking.json";
import { useSelector } from "react-redux";
// import Spinner from "../../components/general/loader/Spinner";
import Input from "../../components/form/Input";

import moment from "moment";
import { AiFillCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
// import {
// applyCouponDispatcher,
// removeCartDispatcher,
// removeCoupon,
// } from "../../redux/app/cart/actions";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import lng from "../../utils/language";
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { applyCoupon, removeCartProducts, removeCoupon } from "../../app/cart/Cart";

const skeletonStyle = {
    backgroundColor: 'rgba(129, 129, 129, 0.5)',
    '::after': {
        background: 'linear-gradient( 90deg, transparent, rgba(112, 112, 112, 0.18), transparent )'
    }
}

function CartProducts() {
    const dispatch = useDispatch();
    const {
        isLoading,
        items,
        priceNoDiscount,
        total,
        // COUPONS
        coupon,
        discount,
        errorCoupon,
        appliedCoupon,
        isCouponLoading,
        couponState
    } = useSelector((state) => state.cart);

    const [couponVal, setCoupon] = useState("");
    const location = useLocation();
    const { language } = useSelector((state) => state.language);
    const { authenticated } = useSelector((state) => state.auth);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const removeCart = (reservation, index) => {
        // dispatch(removeProducts({product: reservation ,index: index}));
        dispatch(removeCartProducts({ product: reservation }))
    };

    const discountCoupon = () => {
        dispatch(applyCoupon({ couponCode: couponVal }));
    };

    const changeCoupon = (e) => {
        setCoupon(e.target.value);
    };

    const clearCoupon = () => {
        setCoupon("");
        dispatch(removeCoupon());
    };

    return (
        <div className={style.cartWrapper}>
            <div className={style.emptyCart}>
                {items?.length > 0 ? (
                    <>
                        {isLoading ? (
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Skeleton variant="rounded" width={330} height={75} sx={[skeletonStyle, { marginTop: 1 }]} />
                                <Skeleton variant="rounded" width={330} height={120} sx={[skeletonStyle, { marginTop: 1 }]} />
                                <Skeleton variant="rounded" width={270} height={18} sx={[skeletonStyle, { marginTop: 1 }]} />
                                <Skeleton variant="rounded" width={206} height={44} sx={[skeletonStyle, { marginTop: 1 }]} />
                                <Skeleton variant="rounded" width={70} height={41} sx={[skeletonStyle, { marginTop: '10px', justifyContent: 'center' }]} />
                            </Box>
                        ) : (
                            <>
                                {
                                    items.map((i, j) => (
                                        <div className={style.cartBox} key={j}>
                                            <div>
                                                <p>
                                                    {i.name} {i.time}min,{" "}
                                                    {moment(i.date).format("DD/MM/YYYY")} {i.data[0].time}
                                                </p>
                                                <span>
                                                    {i.data.length} x {i.unitPrice}&euro;
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={style.close}
                                                    onClick={() => removeCart(i, j)}
                                                >
                                                    <AiFillCloseCircle />
                                                </span>
                                                <h1>{i.price} &euro;</h1>
                                            </div>
                                        </div>
                                    ))}
                                <>
                                    <div className={style.cartFooter}>
                                        <p>Total incl.VAT: {priceNoDiscount} &euro;</p>
                                        <p>Discount: {discount}%</p>
                                        <p>
                                            Total: <b> {total} &euro;</b>
                                        </p>
                                    </div>
                                    {appliedCoupon && (
                                        <div className={style.coupon}>
                                            APPLIED COUPON : {coupon}
                                            <span
                                                className={style.close}
                                                onClick={() => clearCoupon()}
                                            >Remove Coupon </span>
                                        </div>
                                    )}
                                    <span>{lng[language]["hurryMessage"]} </span>
                                </>
                                {location.pathname !== "/cart" && (
                                    <>
                                        <Link to="/cart" className="orgBtn">
                                            {lng[language]["proceedCheckout"]}
                                        </Link>
                                        <Link to="/reservation" className={style.more}>
                                            {" "}
                                            {lng[language]["bookOther"]}
                                        </Link>
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Lottie options={defaultOptions} height={250} />
                        <p>{lng[language]["empty"]} </p>
                        <Link to="/reservation" className="btn">
                            {lng[language]["bookNow"]}{" "}
                        </Link>
                    </>
                )}
            </div>
            {items?.length > 0 && !appliedCoupon && authenticated && (
                <div className={style.codeCoupon}>
                    {
                        isCouponLoading === true ?
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                                <Skeleton variant="rounded" width={'100%'} height={41} sx={[skeletonStyle, { marginTop: '10px', justifyContent: 'center' }]} />
                            </Box>
                            :
                            <>
                                <Input
                                    placeholder={"Kuptoni"}
                                    label={"Kuponi i Zbritjes"}
                                    name="code"
                                    type="tel"
                                    value={couponVal}
                                    onChange={changeCoupon}
                                />
                                {
                                    couponState === false &&
                                    <Typography sx={{fontSize: '12px', color: 'rgb(211, 47, 47)', marginTop: '10px !important'}}>{errorCoupon}</Typography>
                                }
                                <button className="btn" onClick={() => discountCoupon()}>
                                    Apliko Kuponin
                                </button>
                            </>
                    }
                </div>
            )}
        </div>
    );
}
export default CartProducts;
