import { Route, Routes } from "react-router-dom";
import Profile from "../pages/private/profile/Profile";
// import Login from "../pages/public/login/Login";
import ForgetPassword from "../pages/public/password/ForgetPassword";
import Register from "../pages/public/register/Register";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

import Homepage from "../pages/public/homepage/Homepage";
import Karting from "../pages/public/activities/Karting";
import Laser from "../pages/public/activities/Laser";
import Arcade from "../pages/public/activities/Arcade";
import Vr from "../pages/public/activities/Vr";
import AxeThrowing from "../pages/public/activities/AxeThrowing";
import Bowling from "../pages/public/activities/Bowling";
import EliPlay from "../pages/public/activities/EliPlay";
import EscapeRoom from "../pages/public/activities/EscapeRoom";

// Reservation Route
import ReservationModule from "../pages/private/reservation/ReservationModule";
import ReservationActivities from "../pages/private/reservation/ReservationActivities";
import Checkout from "../pages/private/reservation/Checkout";
import PaymentVerification from "../pages/private/reservation/PaymentVerification";
import Price from "../pages/public/activities/Price";
import Rules from "../pages/public/activities/Rules";
import RegisterVerification from "../pages/public/register/RegiisterVerification";
import ChangePassword from "../pages/public/password/ChangePassword";
import MyReservation from "../pages/private/dashboard/MyReservation";
import NotFound from "../pages/public/notfound/NotFound";
import TermsConditions from "../pages/public/terms/Terms";
import Recharge from "../pages/public/activities/Recharge";
// import HomeMenu from "../pages/public/menu/HomeMenu";
// import ItemPage from "../pages/public/menu/ItemPage";
import CardTopUp from "../pages/private/cardPopUp/CardTopUp";
import CardHistory from "../pages/private/cardPopUp/CardTopHistory";
import CardRefill from "../pages/private/cardPopUp/CardRefill";
import UserWrapper from "../pages/public/user/UserWrapper";
import FaceBookTerms from "../pages/public/terms/FaceBookTerms";
import ScoreBoard from "../pages/public/scoreboard/ScoreBoard";
import PartyRoom from "../pages/public/rooms/PartyRoom";
import BusniessRoom from "../pages/public/rooms/BusniessRoom";
import BirthdayRoom2 from "../pages/public/rooms/BirthdayRoom2";
import Test from "../pages/public/Test";
// import NewRegister from "../pages/public/register/NewRegister";
// import Heart from "../pages/public/activities/Heart";
import HyperStrike from "../pages/public/activities/HyperStrike";
import RegisterShow from "../pages/public/RegisterShow";
import NewComp from "../pages/public/activities/NewComp";
// import Halloween from "../pages/public/events/Halloween";
import MajorError from "../errors/MajorError";
import TestApi from "../pages/TestApi";
import HyperSurvery from "../pages/public/HyperSurvery";
import Menu from "../pages/public/Menu";
import MenuShow from "../pages/public/MenuShow";

function AllRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/removedata" element={<FaceBookTerms />} />
            {/* GAMES */}
            <Route path="/activities/karting" element={<Karting />} />
            {/* <Route path="/activities/karting" element={<NewComp />} /> */}
            <Route path="/karting/scoreboard" element={<ScoreBoard />} />
            <Route path="/activities/laser" element={<Laser />} />
            <Route path="/activities/hyperstrike" element={<HyperStrike />} />
            <Route path="/activities/arcade" element={<Arcade />} />
            <Route path="/activities/virtual" element={<Vr />} />
            <Route path="/activities/bowling" element={<Bowling />} />
            <Route path="/activities/axe" element={<AxeThrowing />} />
            <Route path="/activities/eli" element={<EliPlay />} />
            <Route path="/activities/escape" element={<EscapeRoom />} />
            <Route path="/activities/laser" element={<NewComp />} />
            
            {/* <Route path="/activities/halloween" element={<Halloween />} /> */}
            <Route path="/hyper/hypersurvey/:id" element={<HyperSurvery />} />

            {/* RECHARGE && PARTY&BUSSINESS ROOMS START */}
                <Route path="/activities/recharge" element={<Recharge />} />
                <Route path="/activities/bussiness" element={<BusniessRoom />} />
                <Route path="/activities/party" element={<PartyRoom />} />
                <Route path="/activities/birthday" element={<BirthdayRoom2 />} />
            {/* RECHARGE && PARTY&BUSSINESS ROOMS END */}

            {/* PRICE&RULES */}
            <Route path="/activities/price" element={<Price />} />
            <Route path="/activities/rules" element={<Rules />} />

            {/* TEST ROUTES */}
            <Route path="/activities/test" element={<Test />} />

            <Route path="/payment/:type" element={<PaymentVerification />} />
            <Route path="/menu/" element={<Menu />} />
            <Route path="/menu/:id" element={<MenuShow />} />
            {/* <Route path="/menu/:id" element={<Menu />} /> */}
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <UserWrapper />
                    </PublicRoute>
                }
            />
            <Route
                path="/register"
                element={
                    <PublicRoute>
                        <Register />
                        {/* <NewRegister /> */}
                    </PublicRoute>
                }
            />
            <Route
                path="/verification/:id"
                element={
                    <PublicRoute>
                        <RegisterVerification />
                    </PublicRoute>
                }
            />
            <Route
                path="/password"
                element={
                    <PublicRoute>
                        <ForgetPassword />
                    </PublicRoute>
                }
            />
            <Route
                path="/password/:token"
                element={
                    <PublicRoute>
                        <ChangePassword />
                    </PublicRoute>
                }
            />
            <Route
                path="/reservation"
                element={<ReservationActivities />}
            />
            <Route
                path="/reservation/:id"
                element={<ReservationModule />}
            />
            <Route
                path="/cart"
                element={<Checkout />} />
            <Route
                path="/user/profile"
                element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                }
            />

            <Route path="/cart" element={<CardTopUp />} />
            <Route path="/card/:id" element={<CardHistory />} />
            <Route path="/card/refill/:id" element={<CardRefill />} />

            <Route
                path="/user/reservation"
                element={
                    <ProtectedRoute>
                        <MyReservation />
                    </ProtectedRoute>
                }
            />
            {/* <Route
                path="/menu/:locationId=3"
                // /menu/?locationId=3&id=50
                element={<HomeMenu />}
            />
            <Route
                path="/menu/:locationId/:id"
                element={<ItemPage />}
            /> */}

            <Route 
                path="/hyper/regsiter"
                element={<RegisterShow />}
            />

            <Route path="/test" element={<TestApi />} />    

            <Route path="/error" element={<MajorError />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    );
}

export default AllRoutes;
