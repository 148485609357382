import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function Menu() {

    const handleClick = (value) => {
        // window.open(`https://hyper-active.com/docs/${value}`, '_blank');
    }

    return (
        <Box height={'100%'} position={'fixed'} width={'100%'}>
            <Box sx={{ position: 'absolute', zIndex: -1, width: 1, height: 1 }}>
                <Box component={'img'} src='https://hyper-active.com/images/2025/03/sportbar_background.svg' width={1} height={1} sx={{ objectFit: 'cover' }} />
            </Box>
            <Grid container spacing={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box paddingTop={2} textAlign={'center'}>
                        <Box component={'img'} src='https://hyper-active.com/images/2025/03/sportbar_logo.svg' width={'50%'} height={1} sx={{ objectFit: 'cover' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{justifyContent: 'center', display: 'flex'}}>
                                <Button variant='contained' LinkComponent={Link} to="./SportsBar_Menu_Finale" sx={{ backgroundColor: '#B5332F', paddingY: 2, paddingX: 4, border: 'solid 2px', borderColor: '#B5332F', width: {xs: '70%', sm: '300px'}, '&:hover': {background: '#FFF', color: '#B5332F'} }} onClick={() => handleClick('')}>
                                    Food Menu
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{justifyContent: 'center', display: 'flex'}}>
                                <Button variant='contained' LinkComponent={Link} to="./Bar_Menu" sx={{ backgroundColor: '#B5332F', paddingY: 2, paddingX: 4, border: 'solid 2px', borderColor: '#B5332F', width: {xs: '70%', sm: '300px'}, '&:hover': {background: '#FFF', color: '#B5332F'} }} onClick={() => handleClick('Bar_Menu.pdf')}>
                                    Cocktail Menu
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}