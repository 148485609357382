import React from 'react'
import style from "./Activities.module.css";
import { useSelector } from 'react-redux';
import RechargeBanner from '../../../layout/activities/RechargeBanner';
import TextWrapper from '../../../components/general/text/TextWrapper';
// import ActivitiesInterest from '../../../components/activities/ActivitiesInterest';
import CarouselGallery from '../../../components/carousel/CarouselGallery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import constants from '../../../utils/consts';
import GridBox from '../../../layout/grid/GridBox';
import GalleryBox from '../../../components/gallery/Gallery';
import TabsComp from '../../../components/general/tabs/TabsComp';
import RaceEventCard from '../../../components/carousel/RaceEventCard';
import Text from '../../../components/text/Text';
import { recharge } from '../../../utils/gameLng';
import Button from '@mui/material/Button';
import Modals from '../../../components/modal/Modals';
import TextField from '@mui/material/TextField'
import lng from '../../../utils/language';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FetchData from '../../../api/FetchData';
import { partyRoom } from '../../../utils/roomsLng';
import Videos from '../../../components/general/videos/Videos';
import WaitingLoader from '../../../components/general/loader/WaitingLoader';
import FoodTab from '../../../components/general/tabs/FoodTab';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

const defaultState = {
    from: 'Sports Bar',
    fullName: '',
    phoneNumber: '',
    guests: '',
    match: {}
}
const defaultErrorState = {
    fullNameError: false,
    phoneNumberError: false,
    guestsError: false
}
export default function Recharge() {
    const { language } = useSelector((state) => state.language);
    const end = React.useRef(null)
    const res = React.useRef(null)
    const [mailModalOpen, setMailModalOpen] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState({
        // DATA
        from: 'Sports Bar',
        fullName: '',
        // email: '',
        phoneNumber: '',
        guests: '',
        match: {}
    });
    const [confirm, setConfirm] = React.useState({
        open: false,
        msg: '',
        severity: 'none'
    })
    const [userInfoErrors, setUserInfoErrors] = React.useState({
        // ERRORS
        fullNameError: false,
        phoneNumberError: false,
        guestsError: false
    })
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const loadData = () => {
        FetchData.getAll('/general/sports')
            .then(res => {
                setData(res.data.data)
                setLoading(false);
            })
            .catch(err => {
            })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(userInfoErrors);
        const confirmation = validation.includes(true);

        if (!confirmation && userInfo.fullName !== "" && userInfo.phoneNumber !== "" && userInfo.guests !== "") {
            FetchData.create('data/matchEvent', userInfo)
                .then((res) => {
                    if (res.data.type === "success") {
                        setUserInfo(defaultState);
                        setUserInfoErrors(defaultErrorState)
                        setConfirm({
                            open: true,
                            msg: lng[language]['matchRequestSuccess'],
                            severity: 'success'
                        })
                    } else {
                        setConfirm({
                            open: true,
                            msg: lng[language]['matchRequestError'],
                            severity: 'error'
                        })
                    }
                    setTimeout(() => {
                        setConfirm({
                            open: false,
                            msg: ''
                        })
                        handleClose();
                    }, 3000)
                })
                .catch((err) => {
                })
        } else {

        }
    }
    const handleClose = () => {
        setMailModalOpen(false)
    }
    React.useEffect(() => {
        loadData();
    }, [])
    const tabs = {
        eng: [
            {
                tabHeader: 'Soups and salads',
                tabData: [
                    {
                        title: 'Soup of the day',
                        type: 'Served with croutons, garlic bread or crispy filo bread',
                        price: 2.5
                    },
                    {
                        title: 'Chicken Caesar salad',
                        type: `Grilled chicken breast, lettuce, croutons  
                        and parmesan shavings with Caesar dressing`,
                        price: 4.9
                    },
                    {
                        title: 'Chef’s salad',
                        type: `Lettuce, croutons, cherry tomatoes, white cheese, 
                        cucumbers, red onions and sweetcorn with lemon sauce`,
                        price: 4.2
                    },
                    {
                        title: 'Beetroot salad',
                        type: `Beetroot, white cheese, rocket salad, caramelized walnuts, 
                        orange slices, olive oil and balsamic vinegar`,
                        price: 3.5
                    },

                ]
            },

            {
                tabHeader: 'Burgers',
                tabData: [
                    {
                        title: 'Classic beef burger',
                        type: `Served on a burger bun with smashed ground beef, 
                        cheddar, pickles, crispy onions, garlic mayo and fries`,
                        price: 4.9
                    },
                    {
                        title: 'Ultimate beef burger',
                        type: `Served on a burger bun with smashed ground beef, 
                        blue cheese, cheddar, homemade pepper jam, 
                        caramelized onions, coleslaw, garlic mayo and fries`,
                        price: 5.9
                    },
                    {
                        title: 'Chicken burger',
                        type: `Served on a burger bun with crunchy chicken, jalapeños, 
                        coleslaw, homemade pepper jam, cheddar, garlic mayo and fries`,
                        price: 4.5
                    },
                    {
                        title: 'BBQ chicken burger',
                        type: `Served on a burger bun with pulled chicken, jalapeños,
                        homemade BBQ sauce, coleslaw, garlic mayo and fries`,
                        price: 4.2
                    },
                    {
                        title: 'Veggie burger',
                        type: `Served on a burger bun with grilled halloumi cheese, 
                        roasted vegetables, cheddar, garlic mayo, coleslaw and fries`,
                        price: 4.5
                    },
                    {
                        title: 'New York style hot dog',
                        type: `Served on a hot dog bun with beef sausage, 
                        crispy onions, spicy mustard and sauerkraut`,
                        price: 3.5
                    },
                ]
            },

            {
                tabHeader: 'Pasta',
                tabData: [
                    {
                        title: 'Linguine Napoli',
                        type: `Tomato sauce ,cherry tomato, parmigiano`,
                        price: 4.5
                    },
                    {
                        title: 'Linguine Spianch',
                        type: `Spianch, gorgonzola, parmigiano`,
                        price: 5.5
                    },
                    {
                        title: 'Linguine chicken and funghi',
                        type: `Chicken , shittake mushroom , truffle oil and parmigiano`,
                        price: 5.9
                    },
                    {
                        title: 'Linguine with shrimp',
                        type: `Shrimp. Cherry tomatoes,zucchini, peperoncini`,
                        price: 8.20
                    }
                ]
            },

            {
                tabHeader: 'Sharing',
                tabData: [
                    {
                        title: 'Cheese nachos',
                        type: `Tortilla chips blended with cheddar, jalapeños, 
                        guacamole and chipotle sauce on the side`,
                        price: 4.9
                    },
                    {
                        title: 'BBQ chicken nachos',
                        type: `Tortilla chips blended with pulled chicken, 
                        homemade BBQ sauce, red peppers, jalapeños, cheddar, 
                        sweetcorn, guacamole and chipotle sauce on the side`,
                        price: 5.9
                    },
                    {
                        title: 'Ultimate nachos',
                        type: `Tortilla chips blended with pulled beef, cheddar, 
                        jalapeños, red peppers, sweetcorn, guacamole 
                        and chipotle sauce on the side`,
                        price: 6.9
                    },
                    {
                        title: 'Chicken strips',
                        type: `Crispy chicken with sriracha mayo on the side`,
                        price: 4.9
                    },
                    {
                        title: 'Truffle oil arancini',
                        type: `Arborio rice, parmesan, truffle oil 
                        with sriracha mayo on the side`,
                        price: 6.2
                    },
                    {
                        title: 'Calamari',
                        type: `Crispy calamari with tartar sauce on the sidee`,
                        price: 6.5
                    },
                    {
                        title: 'Tempura prawns',
                        type: `Crispy prawns with sriracha mayo on the side`,
                        price: 8.9
                    },
                    {
                        title: 'Hot platter',
                        type: `Mushrooms, arancini, chili garlic wings, chicken strips 
                        with creamy sweetcorn and tensuyu sauce on the side`,
                        price: 15.5
                    },
                    {
                        title: 'Cold platter',
                        type: `Halloumi & tomato bruschetta, parmesan, white cheese,
                        spicy ‘suxhuk’, prosciutto, caramelized walnuts, pears, 
                        pickles and olives with pepper jam on the side`,
                        price: 11.5
                    },

                ]
            },

            {
                tabHeader: 'Wings',
                tabData: [
                    {
                        title: 'Buffalo chicken wings',
                        type: `Authentic Buffalo chicken wings, homemade BBQ sauce,
                        blue cheese sauce and celery on the side `,
                        price: 5.9
                    },
                    {
                        title: 'Buffalo cauliflower wings',
                        type: `Buffalo sauce coated cauliflower, homemade BBQ sauce
                        with blue cheese sauce and celery on the side`,
                        price: 5.2
                    },
                    {
                        title: 'Chili garlic wings',
                        type: `Chicken wings coated in spicy garlic sauce
                        with blue cheese sauce and celery on the side`,
                        price: 5.9
                    },
                    {
                        title: 'Honey BBQ chicken wings',
                        type: `Chicken wings coated in honey and BBQ homemade sauce 
                        with blue cheese sauce and celery on the side`,
                        price: 5.9
                    },
                ]
            },

            {
                tabHeader: 'Main Dishes',
                tabData: [
                    {
                        title: 'Ribeye steak',
                        type: `Served with potatoes and pepper sauce`,
                        price: 14.9
                    },
                    {
                        title: 'Surf & Turf steak',
                        type: `Served with fried prawns, potatoes and chimichurri sauce`,
                        price: 18.9
                    },
                    {
                        title: 'Chicken parmigiana',
                        type: `Served with salad`,
                        price: 6.9
                    },
                    {
                        title: 'Salmon',
                        type: `Served with creamy spinach sauce`,
                        price: 17.9
                    },
                ]
            },

            {
                tabHeader: 'Pizza',
                tabData: [
                    {
                        title: 'Margherita',
                        type: `Tomato sauce, mozzarella, basil`,
                        price: 4.9
                    },
                    {
                        title: 'Supreme',
                        type: `Tomato sauce, mozzarella, spicy ‘suxhuk’,
                        green peppers, black olives, red onions`,
                        price: 5.9
                    },
                    {
                        title: 'Blue cheese',
                        type: `Tomato sauce, mozzarella, 
                        blue cheese, spinach, walnuts, pears`,
                        price: 6.5
                    },
                    {
                        title: 'Potato',
                        type: `Tomato sauce, potato slices, parmesan, 
                        onions, olive oil, rosemary `,
                        price: 5.5
                    },
                    {
                        title: 'Mushroom',
                        type: `Tomato sauce, mozzarella, mushrooms, truffle oil`,
                        price: 5.9
                    },
                    {
                        title: 'Rucola',
                        type: `Tomato sauce, mozzarella, prosciutto, 
                        rocket salad, parmesan`,
                        price: 6.9
                    },
                    {
                        title: 'Pepperoni',
                        type: `Tomato sauce, mozzarella, spicy ‘suxhuk’`,
                        price: 5.9
                    },
                    {
                        title: 'BBQ chicken',
                        type: `Tomato sauce, mozzarella, pulled BBQ chicken,
                        spinach, red onions, cherry tomatoes`,
                        price: 6.5
                    },
                ]
            },

            {
                tabHeader: 'Desserts',
                tabData: [
                    {
                        title: 'Brownies',
                        type: `Served with vanilla ice cream`,
                        price: 4
                    },
                    {
                        title: 'San Sebastian Cheesecake',
                        type: `Served with chocolate`,
                        price: 4
                    },
                    {
                        title: 'Churros',
                        type: `Served with chocolate`,
                        price: 3.5
                    },
                    {
                        title: 'Apple Pie',
                        type: `Served with vanilla ice cream`,
                        price: 3.5
                    },
                ]
            },

            {
                tabHeader: 'Side dishes',
                tabData: [
                    {
                        title: 'French fries',
                        price: 2.5
                    },
                    {
                        title: 'Creamy corn',
                        price: 3.2
                    },
                    {
                        title: 'Crispy fried zucchini',
                        price: 2
                    },
                    {
                        title: 'Parmesan potato truffle oil',
                        type: `Served with garlic mayo`,
                        price: 3.5
                    },
                    {
                        title: 'Coleslaw',
                        price: 0.7
                    },

                ]
            },

            {
                tabHeader: 'Extra sauce',
                tabData: [
                    {
                        title: 'Garlic mayo',
                        price: 0.5
                    },
                    {
                        title: 'Chipottle mayo',
                        price: 0.5
                    },
                    {
                        title: 'Sriracha mayo',
                        price: 0.5
                    },
                    {
                        title: 'Spicy mustard',
                        price: 0.5
                    },
                    {
                        title: 'Homemade BBQ sauce',
                        price: 0.5
                    },
                    {
                        title: 'Homemade honey BBQ sauce',
                        price: 0.5
                    },
                    {
                        title: 'Blue cheese sauce',
                        price: 0.5
                    },
                    {
                        title: 'Louisiana hot chili sauce',
                        price: 0.5
                    },
                    {
                        title: 'Pepper jam',
                        price: 0.5
                    },
                ]
            },
        ],
        alb: [
            {
                tabHeader: 'Supa dhe sallata',
                tabData: [
                    {
                        title: 'Supa e ditës',
                        type: 'Shërbehet me krutona, bukë me hudhër ose bukë me brum të petëzuar',
                        price: 2.5
                    },
                    {
                        title: 'Sallatë Cezar me pulë',
                        type: `Pulë e pjekur në skarë, sallatë e gjelbër, krutona 
                        dhe rriska parmezani me salcë cezar`,
                        price: 4.9
                    },
                    {
                        title: 'Sallatë shtëpie',
                        type: `Sallatë e gjelbër, krutona, domatina, djath, tranguj, 
                        qepë e kuqe dhe misër me salcë limoni`,
                        price: 4.2
                    },
                    {
                        title: 'Beetroot salad',
                        type: `Beetroot, white cheese, rocket salad, caramelized walnuts, 
                        orange slices, olive oil and balsamic vinegar`,
                        price: 3.5
                    },

                ]
            },

            {
                tabHeader: 'Burgers',
                tabData: [
                    {
                        title: 'Classic beef burger',
                        type: `Served on a burger bun with smashed ground beef, 
                        cheddar, pickles, crispy onions, garlic mayo and fries`,
                        price: 4.9
                    },
                    {
                        title: 'Ultimate beef burger',
                        type: `Served on a burger bun with smashed ground beef, 
                        blue cheese, cheddar, homemade pepper jam, 
                        caramelized onions, coleslaw, garlic mayo and fries`,
                        price: 5.9
                    },
                    {
                        title: 'Chicken burger',
                        type: `Served on a burger bun with crunchy chicken, jalapeños, 
                        coleslaw, homemade pepper jam, cheddar, garlic mayo and fries`,
                        price: 4.5
                    },
                    {
                        title: 'BBQ chicken burger',
                        type: `Served on a burger bun with pulled chicken, jalapeños,
                        homemade BBQ sauce, coleslaw, garlic mayo and fries`,
                        price: 4.2
                    },
                    {
                        title: 'Veggie burger',
                        type: `Served on a burger bun with grilled halloumi cheese, 
                        roasted vegetables, cheddar, garlic mayo, coleslaw and fries`,
                        price: 4.5
                    },
                    {
                        title: 'New York style hot dog',
                        type: `Served on a hot dog bun with beef sausage, 
                        crispy onions, spicy mustard and sauerkraut`,
                        price: 3.5
                    },
                ]
            },

            {
                tabHeader: 'Pasta',
                tabData: [
                    {
                        title: 'Linguini Napoli',
                        type: `Salce domatesh , domatina, parmesan`,
                        price: 4.5
                    },
                    {
                        title: 'Linguini Spianc',
                        type: `TSpianc, gorgonzola, parmesan`,
                        price: 5.5
                    },
                    {
                        title: 'Linguini pule dhe  funghi ',
                        type: `Copza pule , kepurdha shittake, vaj tartufi dhe parmesan`,
                        price: 5.9
                    },
                    {
                        title: 'Linguini me karkaleca',
                        type: `Karkaleca, domatina, fije kungulleshe, peperoncini`,
                        price: 8.20
                    }
                ]
            },

            {
                tabHeader: 'Sharing',
                tabData: [
                    {
                        title: 'Cheese nachos',
                        type: `Tortilla chips blended with cheddar, jalapeños, 
                        guacamole and chipotle sauce on the side`,
                        price: 4.9
                    },
                    {
                        title: 'BBQ chicken nachos',
                        type: `Tortilla chips blended with pulled chicken, 
                        homemade BBQ sauce, red peppers, jalapeños, cheddar, 
                        sweetcorn, guacamole and chipotle sauce on the side`,
                        price: 5.9
                    },
                    {
                        title: 'Ultimate nachos',
                        type: `Tortilla chips blended with pulled beef, cheddar, 
                        jalapeños, red peppers, sweetcorn, guacamole 
                        and chipotle sauce on the side`,
                        price: 6.9
                    },
                    {
                        title: 'Chicken strips',
                        type: `Crispy chicken with sriracha mayo on the side`,
                        price: 4.9
                    },
                    {
                        title: 'Truffle oil arancini',
                        type: `Arborio rice, parmesan, truffle oil 
                        with sriracha mayo on the side`,
                        price: 6.2
                    },
                    {
                        title: 'Calamari',
                        type: `Crispy calamari with tartar sauce on the sidee`,
                        price: 6.5
                    },
                    {
                        title: 'Tempura prawns',
                        type: `Crispy prawns with sriracha mayo on the side`,
                        price: 8.9
                    },
                    {
                        title: 'Hot platter',
                        type: `Mushrooms, arancini, chili garlic wings, chicken strips 
                        with creamy sweetcorn and tensuyu sauce on the side`,
                        price: 15.5
                    },
                    {
                        title: 'Cold platter',
                        type: `Halloumi & tomato bruschetta, parmesan, white cheese,
                        spicy ‘suxhuk’, prosciutto, caramelized walnuts, pears, 
                        pickles and olives with pepper jam on the side`,
                        price: 11.5
                    },

                ]
            },

            {
                tabHeader: 'Wings',
                tabData: [
                    {
                        title: 'Buffalo chicken wings',
                        type: `Authentic Buffalo chicken wings, homemade BBQ sauce,
                        blue cheese sauce and celery on the side `,
                        price: 5.9
                    },
                    {
                        title: 'Buffalo cauliflower wings',
                        type: `Buffalo sauce coated cauliflower, homemade BBQ sauce
                        with blue cheese sauce and celery on the side`,
                        price: 5.2
                    },
                    {
                        title: 'Chili garlic wings',
                        type: `Chicken wings coated in spicy garlic sauce
                        with blue cheese sauce and celery on the side`,
                        price: 5.9
                    },
                    {
                        title: 'Honey BBQ chicken wings',
                        type: `Chicken wings coated in honey and BBQ homemade sauce 
                        with blue cheese sauce and celery on the side`,
                        price: 5.9
                    },
                ]
            },

            {
                tabHeader: 'Main Dishes',
                tabData: [
                    {
                        title: 'Ribeye steak',
                        type: `Served with potatoes and pepper sauce`,
                        price: 14.9
                    },
                    {
                        title: 'Surf & Turf steak',
                        type: `Served with fried prawns, potatoes and chimichurri sauce`,
                        price: 18.9
                    },
                    {
                        title: 'Chicken parmigiana',
                        type: `Served with salad`,
                        price: 6.9
                    },
                    {
                        title: 'Salmon',
                        type: `Served with creamy spinach sauce`,
                        price: 17.9
                    },
                ]
            },

            {
                tabHeader: 'Pizza',
                tabData: [
                    {
                        title: 'Margherita',
                        type: `Tomato sauce, mozzarella, basil`,
                        price: 4.9
                    },
                    {
                        title: 'Supreme',
                        type: `Tomato sauce, mozzarella, spicy ‘suxhuk’,
                        green peppers, black olives, red onions`,
                        price: 5.9
                    },
                    {
                        title: 'Blue cheese',
                        type: `Tomato sauce, mozzarella, 
                        blue cheese, spinach, walnuts, pears`,
                        price: 6.5
                    },
                    {
                        title: 'Potato',
                        type: `Tomato sauce, potato slices, parmesan, 
                        onions, olive oil, rosemary `,
                        price: 5.5
                    },
                    {
                        title: 'Mushroom',
                        type: `Tomato sauce, mozzarella, mushrooms, truffle oil`,
                        price: 5.9
                    },
                    {
                        title: 'Rucola',
                        type: `Tomato sauce, mozzarella, prosciutto, 
                        rocket salad, parmesan`,
                        price: 6.9
                    },
                    {
                        title: 'Pepperoni',
                        type: `Tomato sauce, mozzarella, spicy ‘suxhuk’`,
                        price: 5.9
                    },
                    {
                        title: 'BBQ chicken',
                        type: `Tomato sauce, mozzarella, pulled BBQ chicken,
                        spinach, red onions, cherry tomatoes`,
                        price: 6.5
                    },
                ]
            },

            {
                tabHeader: 'Desserts',
                tabData: [
                    {
                        title: 'Brownies',
                        type: `Served with vanilla ice cream`,
                        price: 4
                    },
                    {
                        title: 'San Sebastian Cheesecake',
                        type: `Served with chocolate`,
                        price: 4
                    },
                    {
                        title: 'Churros',
                        type: `Served with chocolate`,
                        price: 3.5
                    },
                    {
                        title: 'Apple Pie',
                        type: `Served with vanilla ice cream`,
                        price: 3.5
                    },
                ]
            },

            {
                tabHeader: 'Side dishes',
                tabData: [
                    {
                        title: 'French fries',
                        price: 2.5
                    },
                    {
                        title: 'Creamy corn',
                        price: 3.2
                    },
                    {
                        title: 'Crispy fried zucchini',
                        price: 2
                    },
                    {
                        title: 'Parmesan potato truffle oil',
                        type: `Served with garlic mayo`,
                        price: 3.5
                    },
                    {
                        title: 'Coleslaw',
                        price: 0.7
                    },

                ]
            },

            {
                tabHeader: 'Extra sauce',
                tabData: [
                    {
                        title: 'Garlic mayo',
                        price: 0.5
                    },
                    {
                        title: 'Chipottle mayo',
                        price: 0.5
                    },
                    {
                        title: 'Sriracha mayo',
                        price: 0.5
                    },
                    {
                        title: 'Spicy mustard',
                        price: 0.5
                    },
                    {
                        title: 'Homemade BBQ sauce',
                        price: 0.5
                    },
                    {
                        title: 'Homemade honey BBQ sauce',
                        price: 0.5
                    },
                    {
                        title: 'Blue cheese sauce',
                        price: 0.5
                    },
                    {
                        title: 'Louisiana hot chili sauce',
                        price: 0.5
                    },
                    {
                        title: 'Pepper jam',
                        price: 0.5
                    },
                ]
            },
        ]
    }
    const drinks = {
        eng: [
            {
                tabHeader: 'COCKTAILS',
                tabData: [
                    {
                        // imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        imageUrl: '',
                        title: "Angelo Azuro",
                        type: "Gin Gordons , Vodka Smirnoff , Captain Morgan white rum , Bluecuaracao,Syrup,Lime juice",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Tequila Sunrise",
                        type: "Orange juice , Tequila Sierra , Grenadine",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Amareto Sour",
                        type: "Disarono , Passionfruit puree , Lime juice",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Margarita Classic",
                        type: "Tequila Sierra , Lime juice , Triplesec",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Tango Rum",
                        type: "Captain Morgan Gold , Lime juice , Pineapple Juice , Orange juice , Grenadine",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Mai Tai",
                        type: "Captain Morgan white , Lime juice , Grenadine , Almond Syrup , Captain Morgan dark rum",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Monkey Sour",
                        type: "Gin Monkey , Lime juice , Passion fruit puree",
                        price: 10.00
                    },
                    {
                        imageUrl: '',
                        title: "Margarita (slush)",
                        type: "",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Mojito (slush)",
                        type: "",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Vodka Sour",
                        type: "Vodka Smirnoff , Lime juice , Simple syrup , Passionfruit puree",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Negroni",
                        type: "Gin Gordons , Martini Roso , Campari",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Old Fashioned",
                        type: "Jameson Whiskey , Simple syrup , Angostura bitters",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Whisky Sour",
                        type: "Jameson Whiskey , Lime juice , Simple syrup foam maker",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Pina Colada",
                        type: "Malibu , Qumesht , Pineapple juice",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Vodka Martini",
                        type: "Vodka Smirnoff , Martini dry , Olive",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Espresso Martini",
                        type: "Vodka Smirnoff , Kahlua , Espresso",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Aperol Spritz",
                        type: "Aperol , Soda water , Prosecco",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Caipirinha",
                        type: "Cashaca , Lime juice , Simple syrup",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Hyper Drive Non-alcoholic",
                        type: "Vanilla syrup , Lime juice , Pineapple juice , Energy drink",
                        price: 4.50
                    },
                ]
            },

            {
                tabHeader: 'BOTTLES',
                tabData: [
                    {
                        imageUrl: '',
                        title: "Birra Peja Lager",
                        type: "250ml 5%",
                        price: 2.50
                    },
                    {
                        imageUrl: '',
                        title: "Birra Peja Crudo",
                        type: "330ml 5%",
                        price: 2.50
                    },
                    {
                        imageUrl: '',
                        title: "Budweiser",
                        type: "330ml 5%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Peroni",
                        type: "330ml 4.7%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Peroni Nastro Azuro",
                        type: "330ml 5%",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Franziskaner Weissbier",
                        type: "500ml 5%",
                        price: 5.50
                    },
                    {
                        imageUrl: '',
                        title: "Grolsch",
                        type: "450ml 5%",
                        price: 4.50
                    },
                    {
                        imageUrl: '',
                        title: "Corona",
                        type: "355ml 4.5%",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Super Bock",
                        type: "330ml 5%",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Bud",
                        type: "330ml 5%",
                        price: 4.00
                    },
                    {
                        imageUrl: '',
                        title: "Paulaner",
                        type: "330ml 5.5%",
                        price: 4.00
                    },
                    {
                        imageUrl: '',
                        title: "Heineken Zero",
                        type: "250ml 0%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Peroni Zero",
                        type: "330ml 0%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Ginger Beer",
                        type: "200ml 0%",
                        price: 2.50
                    },
                ]
            },

            {
                tabHeader: 'ON TAP',
                tabData: [
                    {
                        imageUrl: '',
                        title: "Birra Peja draft",
                        type: "330ml",
                        price: 2.50
                    },
                    {
                        imageUrl: '',
                        title: "Birra Peja draft",
                        type: "500ml",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Krombacher draft",
                        type: "330ml",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Krombacher draft",
                        type: "500ml",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Chardonnay tap",
                        type: "175ml",
                        price: 4.50
                    },
                    {
                        imageUrl: '',
                        title: "Primitivo Puglia tap",
                        type: "175ml",
                        price: 4.50
                    },
                    {
                        imageUrl: '',
                        title: "Prosecco frizante tap",
                        type: "175ml",
                        price: 4.50
                    },
                ]
            },

            {
                tabHeader: 'SOFT DRINKS',
                tabData: [
                    {
                        imageUrl: '',
                        title: 'Monster',
                        type: `500ml`,
                        price: 2.5
                    },
                    {
                        imageUrl: '',
                        title: 'San Benedetto IceT peach',
                        type: `330ml`,
                        price: 1.5
                    },
                    {
                        imageUrl: '',
                        title: 'San Benedetto IceT lemon',
                        type: `330ml`,
                        price: 1.5
                    },
                    {
                        imageUrl: '',
                        title: 'Coca Cola, Cocke Zero, Fanta, ',
                        type: `330ml`,
                        price: 1.5
                    },
                    {
                        imageUrl: '',
                        title: 'Bitter Lemon, Sprite',
                        type: `330ml`,
                        price: 1.5
                    },
                    {
                        imageUrl: '',
                        title: 'Portokall, Pjeshkë, Visnje, ACE',
                        type: `330ml`,
                        price: 1.5
                    },
                    {
                        imageUrl: '',
                        title: 'Karrotë, Cranberry, Ananas',
                        type: `330ml`,
                        price: 1.5
                    },
                    {
                        imageUrl: '',
                        title: 'Fresh Juice',
                        type: `300ml`,
                        price: 3
                    },
                    {
                        imageUrl: '',
                        title: 'Ujë Rugove 25cl',
                        type: `250ml`,
                        price: 1
                    },
                    {
                        imageUrl: '',
                        title: 'Ujë Rugove 75cl',
                        type: `750ml`,
                        price: 2
                    },
                    {
                        imageUrl: '',
                        title: 'Ujë Tepelene i Gazuar 25cl',
                        type: `250ml`,
                        price: 1
                    },
                    {
                        imageUrl: '',
                        title: 'Ujë Tepelene i Gazuar 75cl',
                        type: `750ml`,
                        price: 2
                    },
                ]
            },
        ],
        alb: [
            {
                tabHeader: 'COCKTAILS',
                tabData: [
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Angelo Azuro",
                        type: "Gin Gordons , Vodka Smirnoff , Captain Morgan white rum , Bluecuaracao,Syrup,Lime juice",
                        price: 6.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Tequila Sunrise",
                        type: "Orange juice , Tequila Sierra , Grenadine",
                        price: 5.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Amareto Sour",
                        type: "Disarono , Passionfruit puree , Lime juice",
                        price: 6.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Margarita Classic",
                        type: "Tequila Sierra , Lime juice , Triplesec",
                        price: 5.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Tango Rum",
                        type: "Captain Morgan Gold , Lime juice , Pineapple Juice , Orange juice , Grenadine",
                        price: 6.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Mai Tai",
                        type: "Captain Morgan white , Lime juice , Grenadine , Almond Syrup , Captain Morgan dark rum",
                        price: 5.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Monkey Sour",
                        type: "Gin Monkey , Lime juice , Passion fruit puree",
                        price: 10.00
                    },
                    {
                        imageUrl: 'https://hyper-active.com/assets/images/f3fd9c9ee3f1f559e8f657e76a67e736.png',
                        title: "Margarita (slush)",
                        type: "",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Mojito (slush)",
                        type: "",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Vodka Sour",
                        type: "Vodka Smirnoff , Lime juice , Simple syrup , Passionfruit puree",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Negroni",
                        type: "Gin Gordons , Martini Roso , Campari",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Old Fashioned",
                        type: "Jameson Whiskey , Simple syrup , Angostura bitters",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Whisky Sour",
                        type: "Jameson Whiskey , Lime juice , Simple syrup foam maker",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Pina Colada",
                        type: "Malibu , Qumesht , Pineapple juice",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Vodka Martini",
                        type: "Vodka Smirnoff , Martini dry , Olive",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Espresso Martini",
                        type: "Vodka Smirnoff , Kahlua , Espresso",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Aperol Spritz",
                        type: "Aperol , Soda water , Prosecco",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Caipirinha",
                        type: "Cashaca , Lime juice , Simple syrup",
                        price: 6.00
                    },
                    {
                        imageUrl: '',
                        title: "Hyper Drive Non-alcoholic",
                        type: "Vanilla syrup , Lime juice , Pineapple juice , Energy drink",
                        price: 4.50
                    },
                ]
            },
            {
                tabHeader: 'BOTTLES',
                tabData: [
                    {
                        imageUrl: '',
                        title: "Birra Peja Lager",
                        type: "250ml 5%",
                        price: 2.50
                    },
                    {
                        imageUrl: '',
                        title: "Birra Peja Crudo",
                        type: "330ml 5%",
                        price: 2.50
                    },
                    {
                        imageUrl: '',
                        title: "Budweiser",
                        type: "330ml 5%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Peroni",
                        type: "330ml 4.7%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Peroni Nastro Azuro",
                        type: "330ml 5%",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Franziskaner Weissbier",
                        type: "500ml 5%",
                        price: 5.50
                    },
                    {
                        imageUrl: '',
                        title: "Grolsch",
                        type: "450ml 5%",
                        price: 4.50
                    },
                    {
                        imageUrl: '',
                        title: "Corona",
                        type: "355ml 4.5%",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Super Bock",
                        type: "330ml 5%",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Bud",
                        type: "330ml 5%",
                        price: 4.00
                    },
                    {
                        imageUrl: '',
                        title: "Paulaner",
                        type: "330ml 5.5%",
                        price: 4.00
                    },
                    {
                        imageUrl: '',
                        title: "Heineken Zero",
                        type: "250ml 0%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Peroni Zero",
                        type: "330ml 0%",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Ginger Beer",
                        type: "200ml 0%",
                        price: 2.50
                    },
                ]
            },
            {
                tabHeader: 'On TAP',
                tabData: [
                    {
                        imageUrl: '',
                        title: "Birra Peja draft",
                        type: "330ml",
                        price: 2.50
                    },
                    {
                        imageUrl: '',
                        title: "Birra Peja draft",
                        type: "500ml",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Krombacher draft",
                        type: "330ml",
                        price: 3.50
                    },
                    {
                        imageUrl: '',
                        title: "Krombacher draft",
                        type: "500ml",
                        price: 5.00
                    },
                    {
                        imageUrl: '',
                        title: "Chardonnay tap",
                        type: "175ml",
                        price: 4.50
                    },
                    {
                        imageUrl: '',
                        title: "Primitivo Puglia tap",
                        type: "175ml",
                        price: 4.50
                    },
                    {
                        imageUrl: '',
                        title: "Prosecco frizante tap",
                        type: "175ml",
                        price: 4.50
                    },
                ]
            },
            {
                tabHeader: 'SOFT DRINKS',
                tabData: [
                    {
                        imageUrl: '',
                        title: "Red Bull",
                        type: "250ml",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "San Benedetto IceT peach",
                        type: "330ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "San Benedetto IceT lemon",
                        type: "330ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Coca Cola , Cocke Zero , Fanta",
                        type: "330ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Bitter Lemon , Sprite",
                        type: "330ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Portokall , Pjeshkë , Vishnje , ACE",
                        type: "330ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Cranberry , Ananas",
                        type: "330ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Fresh Juice",
                        type: "300ml",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Ujë Rugove",
                        type: "250ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Ujë Rugove",
                        type: "750ml",
                        price: 3.00
                    },
                    {
                        imageUrl: '',
                        title: "Ujë Tepelene i Gazuar",
                        type: "250ml",
                        price: 1.50
                    },
                    {
                        imageUrl: '',
                        title: "Ujë Tepelene i Gazuar",
                        type: "750ml",
                        price: 3.00
                    }
                ]
            }
        ]
    }
    const rechargeLng = {
        eng: {
            interest: [
                {
                    interest: "Përgaditja",
                    //   ref: ready,
                },
                {
                    interest: "Galeria",
                    //   ref: gallery,
                },
                {
                    interest: "Llojet e betejave",
                    //   ref: games,
                },
                {
                    interest: "Rregullat",
                    //   ref: rules,
                },
            ],
            banner: `Come for the game, stay for the entertainment. With our giant LED screen measuring 320 inches and broadcasting in 4K Ultra HD, or one of our 70 high-definition TVs, you'll get a front-row seat to all your favorite sports while enjoying great food and drinks.

            Whether you're a die-hard sports fan or just looking for a lively atmosphere to hang out with friends and family, we've got you covered. With multiple screens broadcasting live sports events, you won't miss a single moment of the action. And if you need a break from the game, you can challenge your friends to a game of Axe throwing or AR darts.`,
        },
        alb: {
            interest: [
                {
                    interest: "Përgaditja",
                    //   ref: ready,
                },
                {
                    interest: "Galeria",
                    //   ref: gallery,
                },
                {
                    interest: "Llojet e betejave",
                    //   ref: games,
                },
                {
                    interest: "Rregullat",
                    //   ref: rules,
                },
            ],
            banner: `Nejat me shoqni e familje n’Recharge bëhen ma interesant, prej lojërave sportive deri në gjuajtjen me sakicë, Ar pikado e deri në super menu t’ushqimit e pijeve.

            Me ekranin tonë gjigant LED që mat 320 inç dhe transmeton në 4K Ultra HD, ose një nga 70 televizorët tanë me definicion të lartë që shfaqin ngjarje sportive drejtpërdrejt, nuk do të humbisni asnjë minutë nga aksioni. Dhe nëse keni nevojë për një pushim nga loja, mund t'i sfidoni shoqninë tuaj në një lojë gjujtje me sakicë dhe pikado

            Menuja e Recharge është e mbushur me një shumëllojshmëri të gjerë opsionesh të shijshme dhe bari përmban një përzgjedhje të gjerë të birrave, verërave dhe koktejve për të shuar etjen tuaj.`,
        }
    }
    const gallery = [
        "https://hyper-active.com/images/recharge/gallery/clusteredGallery/BLL_6260-1.jpg",
        "https://hyper-active.com/images/recharge/gallery/clusteredGallery/BLL_6283-2.jpg",
        "https://hyper-active.com/images/recharge/gallery/clusteredGallery/BLL_6090-3.jpg",
        "https://hyper-active.com/images/recharge/gallery/clusteredGallery/BLL_5966-4.jpg",
        "https://hyper-active.com/images/recharge/gallery/clusteredGallery/BLL_5805-5.jpg",
        "https://hyper-active.com/images/recharge/gallery/clusteredGallery/BLL_5828-6.jpg",
    ];
    const gallery2 = [
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5720-1.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5725-2.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5743-3.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5741-4.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5776-5.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5778-6.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5802-7.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5796-8.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5790-9.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5831-10.jpg",
        "https://hyper-active.com/images/recharge/gallery/carousel/BLL_5717-11.jpg",
    ];
    const foodGallery = [
        "https://hyper-active.com/images/recharge/gallery/food/1.jpg",
        "https://hyper-active.com/images/recharge/gallery/food/2.jpg",
        "https://hyper-active.com/images/recharge/gallery/food/3.jpg",
        "https://hyper-active.com/images/recharge/gallery/food/4.jpg",
        "https://hyper-active.com/images/recharge/gallery/food/5.jpg",
        "https://hyper-active.com/images/recharge/gallery/food/6.jpg"
    ];
    const cocktailGallery = [
        "https://hyper-active.com/images/recharge/gallery/cocktailsGallery-1.png",
        "https://hyper-active.com/images/recharge/gallery/cocktailsGallery-2.png",
        "https://hyper-active.com/images/recharge/gallery/cocktailsGallery-3.png",
        "https://hyper-active.com/images/recharge/gallery/cocktailsGallery-4.png",
        "https://hyper-active.com/images/recharge/gallery/cocktailsGallery-5.png",
        "https://hyper-active.com/images/recharge/gallery/cocktailsGallery-6.png"
    ];
    const champions = {
        eng: [
            {
                images: "simRacing.png",
                body_header: 'Racing Sims',
                body_desc: "Get ready to experience all the tracks of the world, all types of races as a professional driver 🏁",
                // link: '/reservation/739732'
            },
            {
                images: "pikado.png",
                body_header: 'AR Darts',
                body_desc: "AR Darts is the ultimate modern version of the traditional game of darts. Using augmented reality technology, you can throw virtual darts at a digital board, customize your playing environment, and track your stats. Pick the game: Bullseye, Disco Wall, Killer, Halve it, Robin Hood",
                link: '/reservation/739727'
            },
            {
                images: "ShuffleBoard.png",
                body_header: 'Shuffle Board',
                body_desc: "Dive into the ultimate pub game where the goal is to score points by expertly sliding your pucks to the opposite end of the shuffleboard table. But beware – a single miscalculation and your opponent could slide you right out of contention in this captivating game.",
                // link: null
            },
            {
                images: "Biliard.png",
                body_header: 'Bilardo',
                body_desc: "Let the smooth strokes on the green baize set the tone for an unforgettable evening. Because, let’s face it, nights out get better with a bit of snooker thrill. Are you up for the challenge?",
                link: null
            },
        ],
        alb: [
            {
                images: "simRacing.png",
                body_header: 'Racing Sims',
                body_desc: "Bohuni gati me i përjetu krejt pistat e botës, krejt llojet e garave si me kon shofer profesionist 🏁",
                // link: '/reservation/739732'
            },
            {
                images: "pikado.png",
                body_header: 'AR Darts',
                body_desc: "AR Pikado është versioni i fundit modern i lojës tradicionale të shigjetave. Duke përdorur teknologjinë e realitetit të shtuar, ju mund të hidhni shigjeta virtuale në një tabelë dixhitale, dhe të gjurmoni statistikat tuaja.",
                link: '/reservation/739727'
            },
            {
                images: "ShuffleBoard.png",
                body_header: 'Shuffle Board',
                body_desc: "Një përvojë e parë në llojin e tij të realitetit virtual, ku mund të luani lojëra individuale ose bowling në grup. Teknologjia e realitetit të shtuar ju mundëson të shfaqni një korsi të animuar bowling në mjedisin tuaj fizik.",
                // link: null
            },
            {
                images: "Biliard.png",
                body_header: 'Bilardo',
                body_desc: "AR Pikado është versioni i fundit modern i lojës tradicionale të shigjetave. Duke përdorur teknologjinë e realitetit të shtuar, ju mund të hidhni shigjeta virtuale në një tabelë dixhitale, dhe të gjurmoni statistikat tuaja.",
                link: null
            },
        ]
    };
    const defaultSpacing = {
        paddingY: '80px'
    }
    const defaultBottomSpacing = {
        paddingBottom: '80px'
    }
    const inputs = [
        {
            autoSelect: false,
            type: 'text',
            require: true,
            value: userInfo.fullName,
            error: userInfoErrors.fullNameError,
            label: recharge[language]['fullName'],
            helperText: recharge[language]['fullNameHelperText'],
            placeholder: 'John Doe',
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                    setUserInfoErrors({ ...userInfoErrors, fullNameError: true })
                } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                    setUserInfoErrors({ ...userInfoErrors, fullNameError: false })
                }
                setUserInfo({ ...userInfo, fullName: value })
            },
        },
        {
            autoSelect: false,
            type: 'tel',
            label: 'Telephone',
            require: true,
            placeholder: '+383 44 000 000',
            value: userInfo.phoneNumber,
            error: userInfoErrors.phoneNumberError,
            helperText: recharge[language]['phoneHelperText'],
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)) {
                    setUserInfoErrors({ ...userInfoErrors, phoneNumberError: true })
                } else if (value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/) || value !== "") {
                    setUserInfoErrors({ ...userInfoErrors, phoneNumberError: false })
                }
                setUserInfo({ ...userInfo, phoneNumber: value })
            },
        },
        {

            autoSelect: true,
            value: userInfo.guests,
            label: recharge[language]['guestsHelperText'],
            helperText: recharge[language]['guests'],
            error: setUserInfoErrors.guestsError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value === "") {
                    setUserInfoErrors({ ...userInfoErrors, guestsError: true })
                } else {
                    setUserInfoErrors({ ...userInfoErrors, guestsError: false })
                }
                setUserInfo({ ...userInfo, guests: value })
            },
        },
    ]
    const resInfo = {
        borderRadius: '40px',
        padding: '10px 20px',
        backgroundColor: '#1B1B73'
    }
    return (
        loading === false ?
            <Box className={style.detailsWrapper}>
                <Box sx={defaultBottomSpacing}>
                    <RechargeBanner
                        title="Recharge"
                        desc={rechargeLng[language].banner}
                        image="https://hyper-active.com/images/recharge/banner/banner.png"
                        btnText={lng[language]['bookNow']}
                        click={() => res.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'center'
                        })}
                    />
                </Box>

                <Box className={[style.wrapper, "container"]} sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }}>
                    <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Videos id={'0cgehLvR17g'} />
                </Box>

                <Box className="container" sx={defaultSpacing}>
                    <Text desc={recharge[language]['rechargeDesc']} descStyling={{ textAlign: 'center', fontSize: '24px', width: { md: '844px', xs: '100%' }, marginX: 'auto' }} />
                </Box>

                <Box sx={[{ marginTop: 10 }, defaultSpacing]}>
                    <CarouselGallery gallery={gallery2} radius={5} imgHeight={'500px'} itemIndeL={2} itemIndexM={2} ignorePath={true} />
                </Box>

                <Box className='container'>
                    <Box >
                        <Box>
                            <Text
                                header={recharge[language]['ignoreIfYouCanHeader']}
                                desc={recharge[language]['ignoreIfYouCanDesc']}
                                headerStyle={{
                                    fontSize: '52px',
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}
                                descStyling={{
                                    fontSize: {
                                        sm: '35px',
                                        xs: '22px'
                                    },
                                    fontWeight: 500,
                                    textAlign: 'center'
                                }}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            marginTop: 8,
                            flexDirection: {
                                sm: 'row',
                                xs: 'column'
                            },
                            textAlign: 'center'
                        }}>
                            <Typography variant="h1" color='#F03C44' fontWeight={'bold'}>320"</Typography>
                            <Typography variant="h1" color='#F03C44' fontWeight={'bold'}>4K</Typography>
                            <Typography variant="h1" color='#F03C44' fontWeight={'bold'}>UHD</Typography>
                        </Box>
                    </Box>

                    {/* BANNER */}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{
                            paddingTop: '100px',
                            paddingBottom: '80px'
                        }}>
                            <Box
                                component={'img'}
                                src={'https://hyper-active.com/images/recharge/gallery/crowd.png'}
                                alt={'banner'}
                                sx={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </Box>
                        <Text
                            desc={recharge[language]['upperGallery']}
                            descStyling={{
                                fontSize: {
                                    sm: '25px',
                                    xs: '16px'
                                },
                                whiteSpace: 'pre-line',
                                fontWeight: 'regular',
                                textAlign: 'center'
                            }}
                        />
                        <Button
                            sx={{
                                marginX: 'auto',
                                borderRadius: '30px',
                                paddingY: '10px',
                                paddingX: '25px',
                                marginTop: '100px',
                                backgroundColor: '#321EFF',
                                transition: '.3s all',
                                '&:hover': {
                                    background: '#FFF',
                                    color: '#321EFF'
                                }
                            }}
                            variant='contained'
                            onClick={() =>
                                end.current.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest',
                                    inline: 'center'
                                })
                            }
                        >
                            {recharge[language]['timeCheck']}
                        </Button>
                    </Box>

                    {/* Combine */}
                    <Box
                        sx={{ marginTop: 12 }}
                        ref={res}
                    >

                        <Box marginBottom={'80px'}>
                            <Typography variant="h1" align='center' color="#F03C44" fontFamily={"'Dancing Script', cursive !important"}>{recharge[language]['reserveHeader']}</Typography>
                        </Box>

                        <Box>
                            <RaceEventCard
                                arr={champions[language]}
                                customButton={{
                                    backgroundColor: '#321EFF',
                                    '&:hover': {
                                        color: '#321EFF',
                                        backgroundColor: '#FFF',
                                    },

                                }}
                                type={true}
                            />
                        </Box>
                    </Box>
                    {/* GALLERY */}
                    <Box sx={{ paddingY: '150px' }}>
                        <Box>
                            <Text
                                header={partyRoom[language]['gallery']}
                                containerStyling={{
                                    marginBottom: '50px'
                                }}
                            />
                        </Box>
                        <Box>
                            <GridBox
                                gap={2}
                                gridTemplateColumns={{
                                    xs: 'repeat(2, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(4, 1fr)'
                                }}
                                gridTemplateRows={{
                                    xs: 'repeat(3, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(2, 1fr)'
                                }}
                            >
                                <GalleryBox galleryImages={gallery} ignorePath={true} />
                            </GridBox>
                        </Box>
                    </Box>

                    {/* Food */}
                    <Box sx={{ marginTop: 15 }}>
                        <Box>
                            <Typography variant="h1" align='center' color="#F03C44" fontFamily={"'Dancing Script', cursive !important"}>{recharge[language]['food']}</Typography>
                            <TextWrapper
                                desc={recharge[language]['menuDesc']}
                            />
                        </Box>
                        <Box>
                            <GridBox
                                gap={2}
                                gridTemplateColumns={{
                                    xs: 'repeat(2, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(4, 1fr)'
                                }}
                                gridTemplateRows={{
                                    xs: 'repeat(3, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(2, 1fr)'
                                }}
                            >
                                <GalleryBox galleryImages={foodGallery} ignorePath={true} />
                            </GridBox>
                        </Box>
                    </Box>

                    <Box sx={{ marginTop: '100px' }}>
                        <FoodTab test={tabs[language]} />
                    </Box>

                    {/* COCKTAILS */}
                    <Box sx={{ marginTop: 15 }}>
                        <Box>
                            <Typography variant="h1" align='center' color="#F03C44" fontFamily={"'Dancing Script', cursive !important"}>Cocktails</Typography>
                        </Box>
                        <Box sx={{ marginTop: 10 }}>
                            <GridBox
                                gap={2}
                                gridTemplateColumns={
                                    {
                                        xs: 'repeat(2, 1fr)',
                                        sm: 'repeat(3, 1fr)',
                                        md: 'repeat(4, 1fr)'
                                    }
                                }
                                gridTemplateRows={{
                                    xs: 'repeat(3, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(2, 1fr)'
                                }}
                            >
                                <GalleryBox galleryImages={cocktailGallery} ignorePath={true} />
                            </GridBox>
                        </Box>
                    </Box>

                    <Box sx={{ marginY: '100px' }}>
                        <TabsComp
                            test={drinks[language]}
                        // style={{
                        //     '.tabItemCocktailContainer': {
                        //         gridGap: '10px'
                        //     },
                        //     '.cocktailHeader': {
                        //         width: '100%',
                        //         display: 'flex',
                        //         justifyContent: 'center',
                        //         alignItems: 'center',
                        //     },
                        //     '.tabItemCocktail': {
                        //         display: 'grid',
                        //         gridTemplateRows: 'auto auto auto auto',
                        //         borderRadius: '5px',
                        //         '.price': {
                        //             width: '100%',
                        //             height: '40px',
                        //             display: 'flex',
                        //             alignItems: 'center',
                        //             position: 'relative',
                        //             justifyContent: 'center',
                        //             '&::after': {
                        //                 right: 0,
                        //                 zIndex: '-1',
                        //                 width: '60%',
                        //                 height: '40px',
                        //                 content: "''",
                        //                 position: 'absolute',
                        //                 borderTopLeftRadius: '20px',
                        //                 background: 'rgb(240, 60, 68)',
                        //                 borderBottomLeftRadius: '20px',
                        //             }
                        //         }
                        //     }
                        // }}

                        />
                    </Box>
                </Box>

                <Box sx={{ paddingY: '80px' }}>
                    <Typography variant="h1" sx={{
                        fontSize: { sm: '6rem', xs: '55px' },
                        marginBottom: { sm: '150px', xs: '50px' },
                        borderBottom: { sm: 'none', xs: 'solid 3px' },
                        paddingBottom: { sm: 'none', xs: '10px' }
                    }} textAlign={'center'} fontWeight={'800'} color="#F03C44" fontFamily={"'Dancing Script',cursive !important"} marginBottom={'150px'} ref={end}>Sports schedule</Typography>
                    {
                        data &&
                        Object.entries(data).map((matches, matchIndex) => (
                            <Box key={matchIndex} className='container'>
                                <Typography variant="h1" textAlign={'center'} fontWeight={'800'} color="#F03C44" sx={{ fontSize: { sm: '6rem', xs: '55px' } }}>{matches[0]}</Typography>
                                <Box>
                                    {
                                        Object.entries(matches[1]).map((day, dayInex) => (
                                            <Box key={dayInex} marginY={'50px'}>
                                                <Typography variant="h4" sx={{ fontSize: { sm: '55px', xs: '40px' } }} textAlign={'center'} fontWeight={'normal'} color='#FFF'>{lng[language][day[0]]}</Typography>
                                                <Box>
                                                    {
                                                        day[1].map((data, index) => (
                                                            <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }} marginY={'70px'}>
                                                                <Box sx={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: data.secondTeam ? {
                                                                        md: '1fr 160px 1fr',
                                                                        xs: '1fr'
                                                                    } : '1fr',
                                                                    justifyContent: 'space-between',
                                                                    gridGap: {
                                                                        sm: '0',
                                                                        xs: '15px'
                                                                    }
                                                                }}
                                                                >
                                                                    {
                                                                        data.firstTeam &&
                                                                        <Typography variant="h3" sx={[{ fontSize: { sm: '35px', xs: '25px' }, textAlign: 'center' }, resInfo]}>{data.firstTeam}</Typography>
                                                                    }
                                                                    {
                                                                        data.time &&
                                                                        <Typography variant="h3" sx={[{ fontSize: { sm: '35px', xs: '25px' }, textAlign: 'center' }, !data.secondTeam ? { width: 'fit-content', marginX: 'auto', marginTop: '20px', background: 'transparent' } : {}]}>{data.time}</Typography>
                                                                    }
                                                                    {
                                                                        data.secondTeam &&
                                                                        <Typography variant="h3" sx={[{ fontSize: { sm: '35px', xs: '25px' }, textAlign: 'center' }, resInfo]}>{data.secondTeam}</Typography>
                                                                    }
                                                                </Box>
                                                                <Button
                                                                    variant='contained'
                                                                    sx={[{
                                                                        marginX: 'auto',
                                                                        marginTop: '50px',
                                                                        borderRadius: '40px',
                                                                        padding: '10px 30px',
                                                                        border: 'solid 2px #F03C44',
                                                                        backgroundColor: 'transparent',
                                                                        '&:hover': {
                                                                            color: '#F03C44',
                                                                            borderColor: '#FFF',
                                                                            backgroundColor: '#FFF'
                                                                        }
                                                                    }, !data.secosecondTeam ? { marginTop: '20px' } : {}]}
                                                                    onClick={() => { setUserInfo({ ...userInfo, match: data }); setMailModalOpen(true); }}
                                                                >
                                                                    {lng[language]['bookNow']}
                                                                </Button>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>

                            </Box>
                        ))
                    }
                </Box>

                <Modals open={mailModalOpen} handleClose={handleClose}>
                    <Box component={'form'} sx={{ color: '#FFF', display: 'flex', flexDirection: 'column', position: 'relative' }} onSubmit={(e) => handleSubmit(e)}>
                        <Box onClick={handleClose} sx={{ color: '#FFF', cursor: 'pointer', position: 'absolute', top: '-20px', right: '-20px', border: 'solid 1px #FFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CloseIcon />
                        </Box>
                        <Text
                            header={'Sports Bar'}
                            headerStyle={{
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}
                        />
                        {
                            inputs.map((data, index) => (
                                data.autoSelect === false &&
                                <Box marginY={'10px'} key={index}>
                                    <InputLabel shrink sx={{ color: '#FFF', fontSize: '20px' }} >{data.label}</InputLabel>
                                    <TextField
                                        min="1"
                                        fullWidth
                                        type={data.type}
                                        // required={data.require}
                                        required={false}
                                        value={data.value}
                                        error={data.error}
                                        onChange={(e) => {
                                            data.multiCall(e);
                                        }}
                                        placeholder={data.placeholder}
                                        inputProps={data.type === "number" ? { min: 1, max: 10 } : {}}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                backgroundColor: '#E3E3E3',
                                                borderRadius: '20px',
                                            },
                                            '.MuiInputBase-input': {
                                                paddingY: 1.6
                                            }
                                        }
                                        }
                                    />
                                </Box>
                            ))
                        }
                        {
                            inputs.map((data, index) => (
                                data.autoSelect === true &&
                                <Box marginY={'10px'} key={index}>
                                    <InputLabel shrink id={data.id} sx={{ color: '#FFF', fontSize: '20px' }}>{data.label}</InputLabel>
                                    <FormControl fullWidth sx={{ color: '#FFF' }}>
                                        <Select
                                            value={data.value}
                                            onChange={(e) => {
                                                data.multiCall(e);
                                            }}
                                            sx={{
                                                '.MuiSelect-select ': {
                                                    backgroundColor: '#E3E3E3',
                                                    borderRadius: '20px !important',
                                                },
                                                'MuiSelect-select:focus': {
                                                    borderRadius: '20px',
                                                },
                                                '.MuiInputBase-input': {
                                                    paddingY: 1.6
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            ))
                        }
                        <Button type="submit" variant='contained' sx={{ marginX: 'auto', width: 'fit-content', marginTop: '20px', paddingY: '10px', paddingX: '25px', borderRadius: '30px', backgroundColor: '#321EFF' }}>
                            {lng[language]['bookNow']}
                        </Button>
                        {
                            confirm.open &&
                            <Alert variant="filled" severity={confirm.severity} sx={{marginTop: '20px'}}>
                                {confirm.msg}
                            </Alert>
                        }
                    </Box>
                </Modals>
            </Box>
            :
            <WaitingLoader open={loading} />
    )
}