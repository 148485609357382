import Box from "@mui/material/Box";
import style from "./Message.module.css";
import { useDispatch } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { setLoginMessage } from "../../../app/auth/Auth";

function Alert(props) {
  const dispatch = useDispatch();

  const closeMessage = () => {
    dispatch(setLoginMessage(false));
  };
  return (
    <Box className={style[props.type]} sx={{display: 'flex', justifyContent: 'center', fontSize: '13px', positon: 'relative'}}>
      {props.message}
      <Box sx={{position: 'absolute', right: 5, top: '20%', cursor: 'pointer'}}>
        <GrFormClose size={20} color={"white"}  onClick={() => closeMessage()} />
      </Box>
    </Box>
  );
}
export default Alert;
