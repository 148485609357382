import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CustomStepper from '../layout/reservation/general/steps/CustomStepper';
import { Button } from 'antd';
import { message } from 'antd';
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom';
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import Devider from '@mui/material/Divider';
export default function TestApi() {

    const steps = ['Numri i pjesëmarrësve', 'Sloti kohor', 'Pagesa'];
    const [activeStep, setActiveStep] = useState(0);

    const stepper = () => {
        switch (activeStep) {
            case 0:
                // SET THE NUMBER OF PLAYERS
                return (
                    <Box sx={{ color: '#000', width: '50%' }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box>
                                <ArrowCircleLeftSharpIcon sx={{width: '50px', height: '50px', cursor: 'pointer'}} onClick={handleBack} disabled={activeStep === 0} />
                            </Box>
                            <Box sx={{marginLeft: '10px'}}>
                                <Typography variant="h5" fontWeight={'bold'}>Numri i pjesëmarrësve</Typography>
                                <Typography variant="caption" fontWeight={'bold'}>Listën e cmimeve mund ta gjeni këtu <Box component={Link} to="../activities/price" sx={{color: '#F03C44', textDecoration: 'none'}}>Kliko këtu</Box></Typography>
                            </Box>
                        </Box>

                        <Box sx={{marginTop: '50px'}}>
                            <Devider sx={{borderColor: '#918DB8', borderStyle: 'dashed'}} />
                            <Box sx={{paddingY: '25px'}}>
                                <Box>
                                    Numri i pjesëmarrësve
                                </Box>

                                <Box>
                                    
                                </Box>

                            </Box>
                            <Devider sx={{borderColor: '#918DB8', borderStyle: 'dashed'}} />
                        </Box>

                    </Box>
                );
            case 1:
                return (
                    <Box sx={{ color: '#FFF' }}>

                    </Box>
                );

            case 2:
                return (
                    <Box sx={{ color: '#FFF' }}>
                        tset3
                    </Box>
                );
        
            default:
                break;
        }
    }

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            message.success("All steps completed!");
        }
    };
    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box>
            <CustomStepper getStepContent={stepper} activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
            <div>
                <Button type="primary" onClick={handleReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={handleNext} disabled={activeStep === steps.length}>
                    Next
                </Button>
            </div>
        </Box>
    )
}