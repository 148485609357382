import { useParams } from "react-router-dom";
// import success from "../../../utils/lotties/101253-successful.json";
// import error from "../../../utils/lotties/19230-payment-failed.json";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { clearCartDispatcher } from "../../../redux/app/cart/actions";
import LottieMessage from "../../../components/general/message/LottieMessage";
// import ReactPixel from "../../../utils/pixel/reactPixel";
import { removeAll } from "../../../app/cart/Cart";
import { trackCustomEvent } from "../../../utils/pixel/facebookPixel";

function PaymentVerification() {
  let { type } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    //Clear cart
    if (localStorage.getItem("cart")) dispatch(removeAll());
    
    if (type === "success") {
        trackCustomEvent('Purchase', {type: "success", date: new Date(),});
    //   ReactPixel.track("Purchase", {
    //     type: "success",
    //     date: new Date(),
    //   });
    }
    // eslint-disable-next-line
  }, []);

//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: type === "success" ? success : error,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };

  return (
    <div className=" container">
      {type === "success" ? (
        <LottieMessage text="Rezervimi u konfirmua!" type="success" />
      ) : (
        <LottieMessage
          text="Pagesa nuk u realizua, rezervimi dështoi!"
          type="error"
        />
      )}
    </div>
  );
}
export default PaymentVerification;