import Grid from "../../../layout/grid/Grid";
import style from "./RadiantBanner.module.css";
// import constants from "../../../utils/consts";
import Box from "@mui/material/Box";

function AppBanner(props) {
  return (
    <Box className={style.appBanner} sx={{background: props.specialColor ? props.specialColor : '', color: '#FFF'}}>
      <Grid no={"1"}>
        <div className={style.appInfo}>
          <h1>
          {props.header && props.header}
          </h1>
          <p>
            {props.desc && props.desc}
          </p>
        </div>
        <Box className={style.appImage}>
          <img src={`https://hyper-active.com/images/${props.imgUrl}`} alt={"App Icon"} />
        </Box>
      </Grid>
    </Box>
  );
}
export default AppBanner;
