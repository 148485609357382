import style from "./CardTopUp.module.css";

import { api } from "../../../api/config";
import { useEffect, useState } from "react";
import TopUpCard from "../../../components/card/TopUpCard";
import { Link } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";

function CardHistory({ data }) {
  const [
    // cards, 
    setCards] = useState([]);

  useEffect(() => {
    api
      .get("card/getCard")
      .then((res) => {
        // console.log("RES DATAs", res);

        if (!res.data.error) {
          setCards(res.data.cards);
        }
        //Check message types
        // if (res.data.success) {
        //   setCards((prevState) => ({
        //     ...prevState,
        //     time: res.data.params[0].minTime,
        //     adult: Number(res?.data.params[0].minValue) === 1 ?  0 : Number(res?.data.params[0].minValue),
        //     min: res.data.params[0].minTime,
        //     max: res.data.params[0].maxTime,
        //     name: res.data.params[0].name,
        //     timeStepValue: res.data.params[0].timeStepValue,
        //     pickPlayerData: res.data.params[0].pickPlayerData,
        //   }));

        //   setReservationParams(res?.data.params);
        //   setLoading(false);
        // } else setLoading(true);
      })
      .catch((error) => {
        console.error(error);
      });
      //eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <Link to="/reservation" className="bckButton">
        <MdOutlineArrowBackIos color="#fff" />
      </Link>
      <div className={style.singleCard}>
        <TopUpCard id={2} balance={"25.50"} name={"TEST"} showLink={false} />
      </div>
      <div>
        <h2>Historiku i shpenzimeve</h2>
        <ul className={style.cardHistory}>
          <li>
            <p>Go Kart Hyperdrive</p>
            <span>15 &euro; </span>
          </li>
          <li>
            <p>Go Kart Hyperdrive</p>
            <span>15 &euro; </span>
          </li>
          <li>
            <p>Go Kart Hyperdrive</p>
            <span>15 &euro; </span>
          </li>
          <li>
            <p>Go Kart Hyperdrive</p>
            <span>15 &euro; </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CardHistory;
