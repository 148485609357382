// CONFIGURE FOR REDUX-TOOLKIT
import { configureStore } from '@reduxjs/toolkit';

// REDUCERS
import cartReducer  from './cart/Cart';
import authReducer  from './auth/Auth';
import languageReducer  from './language/Language';
import errorReducer from './error/Error'

export const store = configureStore({
    reducer: {
        cart: cartReducer,
        auth: authReducer,
        language: languageReducer,
        error: errorReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})