import Box from '@mui/material/Box';
import { api } from '../../api/config';
import Button from '@mui/material/Button'
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function MenuShow() {
    const { id } = useParams();

    const [files, setFiles] = useState([]);

    const data = () => {
        api.post('/general/checkFiles', {id: id})
        .then(res => {
            setFiles(res.data);
        })
        .catch(err => {  })
        .finally(() => {  })
    }

    useEffect(() => {
        data();
        //eslint-disable-next-line
    }, [id])

    return (
        <Box sx={{zIndex: -1, width: 1, height: 1, display: 'grid', gridTemplateRows: '1fr', rowGap: '2px' }}>
            <Box sx={{position: 'fixed', top: '10px', left: '10px'}}>
                <Button variant='contained' sx={{backgroundColor: '#cd0001', border: '1px solid #FFF', minWidth: '48px','&:hover': {background: '#FFF', color: '#cd0001'}}} LinkComponent={Link} to="../menu">
                    <ArrowBackIcon />
                </Button>
            </Box>
            {
                files.length > 0 ?
                files.map((data, index) => (
                    <Box component={'img'} key={index} src={`https://hyper-active.com/docs/${id}/${data}`} width={1} height={1} sx={{ objectFit: 'cover' }} />
                ))
                :
                'Something went wrong'
            }
        </Box>
    )
}
