import style from "./ActivitiesInterest.module.css";
// import Grid from "../../layout/grid/Grid";

function ActivitiesInterest(props) {
  return (
    <div className={style.interestWrapper}>
      {props.header && <h2>{props.header}</h2>}
      {props.title && <p>{props.title}</p>}
      <div className={style.activitiesInterest}>
        {Object.entries(props.interests).map(([index, data]) => (
          <div id={index} onClick={() => 
            data.ref ? data.ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center'
            }) : ''
        } className={style.round} key={index} style={{backgroundColor: props.color ? props.color : "#120f2e"}}>{data.interest}</div>
        ))}
      </div>
    </div>
  );
}
export default ActivitiesInterest;
