import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import lng from "../../../utils/language";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
import SingleCarousel from "../../../components/carousel/SingleCarousel";
import CustomCard from "../../../components/activities/CustomCard";
// import RadiantBanner from "../../../components/general/banner/RadiantBanner";
import SpeedScales from "../../../components/activities/SpeedScales";
import VideoWrapper from "../../../components/general/videos/VideoWrapper";
// import MonthlyChampions from "../../../components/activities/MonthlyChampions";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
import ReserveText from "../../../components/reservation/ReserveText";
import GeneralLayout from "../../../layout/activities/GeneralLayout";
import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import RandomCards from "../../../components/card/RandomCards";
import GalleryBox from "../../../components/gallery/Gallery";
import GridBox from "../../../layout/grid/GridBox";
import { karting } from "../../../utils/gameLng";
import FetchData from "../../../api/FetchData";
import RaceEventCard from "../../../components/carousel/RaceEventCard";
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
import AddBanner from "../../../components/general/banner/AddBanner";
import Text from "../../../components/text/Text";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import BannerCard from "../../../components/card/BannerCard";
import { FaInfinity } from "react-icons/fa6";

function Karting() {
    const { language } = useSelector((state) => state.language);
    const myRef = useRef(null);
    const raceTrack = useRef(null)
    const cars = useRef(null)
    const gallery = useRef(null)
    const birthday = useRef(null)

    const [kartingData, setKartingData] = useState();
    const [loading, setLoading] = useState(true)

    const load = () => {
        FetchData.create('/general/page', {
            id: 1,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setKartingData(res.data.data)
                setLoading(false);
            })
            .catch(err => {
                // console.log('Error', err);
            })
    }

    useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])

    const containerSpacing = {
        paddingY: '80px'
    }

    const interests = {
        eng: {
            interest: [
                {
                    interest: "Gallery",
                    ref: gallery
                },

                {
                    interest: "Speed",
                    ref: myRef
                },
                {
                    interest: "Track",
                    ref: raceTrack
                },
                {
                    interest: "Go-Kart",
                    ref: cars
                },
                {
                    interest: "Book Your Birthday!",
                    ref: birthday
                },
            ]
        },
        alb: {
            interest: [
                {
                    interest: "Galeria",
                    ref: gallery
                },
                {
                    interest: "Shpejtësia",
                    ref: myRef
                },
                {
                    interest: "Pista",
                    ref: raceTrack
                },
                {
                    interest: "Vetura Go-Kart",
                    ref: cars
                },
                {
                    interest: "Festo ditëlindjen",
                    ref: birthday
                },
            ]
        }
    }

    const numData = {
        eng: {
            data: [
                {
                    numTitle: "500m",
                    desc: "Track",
                    type: "red",
                },
                {
                    numTitle: "10",
                    desc: "Karts for race",
                    type: "blue",
                },
                {
                    numTitle: "8min",
                    desc: "Race",
                    type: "darkBlue",
                },
                {
                    numTitle: "500",
                    desc: "Races per Day",
                    type: "yellow",
                },
                {
                    numTitle: <FaInfinity />,
                    desc: "Adrenaline Rush",
                    type: "purple",
                },
            ]
        },
        alb: {
            data: [
                {
                    numTitle: "500m",
                    desc: "Pistë",
                    type: "red",
                },
                {
                    numTitle: "10",
                    desc: "Vetura për garë",
                    type: "blue",
                },
                {
                    numTitle: "8min",
                    desc: "Garë",
                    type: "darkBlue",
                },
                {
                    numTitle: "500",
                    desc: "Gara në ditë",
                    type: "yellow",
                },
                {
                    numTitle: <FaInfinity />,
                    desc: "Adreanlinë",
                    type: "purple",
                },
            ]
        }
    }

    return (
        <Box className={style.detailsWrapper}>
            {
                loading === false
                    ?
                    kartingData &&
                    <>
                        <Box className="container">
                            {
                                kartingData[1] &&
                                <ActivitiesBanner
                                    title={kartingData[1].body_header}
                                    desc={kartingData[1].body_desc}
                                    image={constants.imgPath + kartingData[1].images}
                                    linkPrice="karting"
                                    vidId="ZmkoDcjWrM8"
                                    id={kartingData[1].link}
                                    prices={true}
                                />
                            }
                            <Box
                    sx={{
                        marginTop: {
                            xs: "100px",
                            sm: 20,
                            md: 0,
                        },
                    }}
                >
                    <ActivitiesInterest
                                interests={interests[language].interest}
                            />
                </Box>

                            

                            <Box className={style.wrapper}
                                sx={{
                                    height: {
                                        md: '500px',
                                        sm: '416px',
                                        xs: '209px'
                                    },
                                    position: 'relative'
                                }}
                            >
                                <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                                <Videos id={'ZmkoDcjWrM8'} />
                            </Box>


                            <Box className={style.wrapper} sx={{ paddingBottom: '100px' }}>
                                <BannerCard
                                    //DATA 
                                    header={karting[language]['promoBannerTitle']}
                                    desc={karting[language]['promoBannerDesc']}
                                    button="/reservation/739676"
                                    buttonTxt={karting[language]['promoBannerButtonText']}
                                    imgUrl="https://hyper-active.com/images/hyperdrive/other/DoubleRace.png"
                                    secondImg="https://hyper-active.com/images/hyperdrive/other/Group 2485.png"
                                    buttonLink={'/reservation/739676'}
                                    // STYLING
                                    containetrStyle={{
                                        display: 'grid',
                                        gridTemplateColumns: {
                                            sm: '1fr 1fr',
                                            xs: '1fr'
                                        },
                                        position: 'relative',
                                        height: {
                                            sm: 'auto',
                                            xs: '350px'
                                        }
                                    }}
                                    mainTextContainerStyle={{
                                        zIndex: '2',
                                        padding: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: {
                                            md: '80%',
                                            xs: '100%'
                                        },
                                        marginY: 'auto',
                                        justifyContent: {
                                            sm: 'unset',
                                            xs: 'center'
                                        },
                                        alignItems: {
                                            sm: 'unset',
                                            xs: 'center'
                                        },
                                    }}
                                    headerStyle={{
                                        fontWeight: 'bold',
                                        width: {
                                            md: '285px',
                                            sx: '100%'
                                        },
                                        fontSize: {
                                            md: '49px',
                                            xs: '30px'
                                        },
                                        marginBottom: '20px'
                                    }}
                                    descStyle={{
                                        fontSize: '18px',
                                        whiteSpace: 'pre-line'
                                    }}
                                    buttonStyle={{
                                        color: '#000',
                                        marginTop: '50px',
                                        fontWeight: 'bold',
                                        width: 'fit-content',
                                        backgroundColor: '#FABF00',
                                        border: 'solid 2px #FABF00',
                                        borderRadius: '40px',
                                        paddingY: '15px',
                                        paddingX: '30px',
                                        '&:hover': {
                                            color: '#FABF00',
                                            backgroundColor: '#000'
                                        }
                                    }}

                                    imgBannerContainerStyle={{
                                        position: {
                                            sm: 'relative',
                                            xs: 'absolute'
                                        },
                                        opacity: {
                                            sm: '1',
                                            xs: '0.5'
                                        },
                                        height: {
                                            sm: 'auto',
                                            xs: '105%'
                                        }
                                    }}
                                    imgUrlStyle={{

                                    }}
                                    secondImgStyle={{
                                        position: 'absolute',
                                        height: 'unset',
                                        left: 0,
                                        bottom: {
                                            sm: 0,
                                            xs: '-5%'
                                        }
                                    }}
                                />
                            </Box>

                            {
                                kartingData[10] &&
                                <Box sx={containerSpacing}>
                                    <Text
                                        containerStyling={{
                                            marginBottom: '80px'
                                        }}
                                        header={lng[language]["racesAndEvents"]}
                                        desc={lng[language]["racesAndEventsDesc"]}
                                        headerStyle={{
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            marginBottom: '30px'
                                        }}
                                        descStyling={{
                                            textAlign: 'center',
                                            marginX: 'auto',
                                            width: {
                                                md: '673px',
                                                xs: '100%'
                                            }
                                        }}
                                    />
                                    <RaceEventCard
                                        arr={kartingData[10]}
                                        compStyling={{
                                            '.MuiTypography-body1': {

                                            }
                                        }}
                                        customButton={{
                                            backgroundColor: '#321EFF',
                                            '&:hover': {
                                                color: '#321EFF',
                                                backgroundColor: '#FFF',
                                            }
                                        }}
                                    />
                                </Box>
                            }
                            {
                                kartingData[7] &&
                                <Box className={["container"]} sx={containerSpacing} ref={gallery}>
                                    <Text
                                        containerStyling={{
                                            marginBottom: '50px'
                                        }}
                                        header={kartingData[7].wrapper_header}
                                    />
                                    <GridBox
                                        gap={2}
                                        gridTemplateColumns={
                                            {
                                                xs: 'repeat(2, 1fr)',
                                                sm: 'repeat(3, 1fr)',
                                                md: 'repeat(4, 1fr)'
                                            }
                                        }
                                        gridTemplateRows={{
                                            xs: 'repeat(3, 1fr)',
                                            sm: 'repeat(3, 1fr)',
                                            md: 'repeat(2, 1fr)'
                                        }}
                                    >
                                        <GalleryBox galleryImages={kartingData[7].images} />
                                    </GridBox>
                                </Box>
                            }

                            <Box sx={containerSpacing} ref={myRef}>
                                <Text
                                    containerStyling={{
                                        marginBottom: '50px'
                                    }}
                                    descStyling={{
                                        textAlign: 'center',
                                        marginX: 'auto',
                                        width: {
                                            md: '90%',
                                            xs: '100%'
                                        }
                                    }}

                                    header={karting[language]['speedScaleTitle']}
                                    desc={karting[language]['speedScale']}
                                />
                                <SpeedScales />
                            </Box>

                            <Box sx={containerSpacing} ref={raceTrack}>
                                <Text
                                    containerStyling={{
                                        marginBottom: '50px'
                                    }}
                                    descStyling={{
                                        textAlign: 'center',
                                        marginX: 'auto',
                                        width: {
                                            md: '800px',
                                            xs: '100%'
                                        }
                                    }}
                                    header={karting[language]["rides"]}
                                    desc={karting[language]["ridesDesc"]}
                                />
                                <CustomCard
                                    title={karting[language]['checkTheTrack']}
                                    numData={numData[language].data}
                                    mainImg={constants.imgPath + "KartingPista.png"}
                                />
                            </Box>

                            {
                                kartingData[6] &&
                                kartingData[6][0] &&
                                <Box sx={containerSpacing} ref={cars}>
                                    <Text
                                        containerStyling={{
                                            marginBottom: '50px'
                                        }}
                                        descStyling={{
                                            textAlign: 'center',
                                            marginX: 'auto',
                                            width: {
                                                md: '90%',
                                                xs: '100%'
                                            }
                                        }}
                                        header={kartingData[6][0].wrapper_header}
                                        desc={kartingData[6][0].wrapper_desc}
                                    />
                                    <SingleCarousel images={kartingData[6][0].images} />
                                </Box>
                            }
                        </Box>

                        <Box sx={containerSpacing}>
                            <AddBanner
                                descStyle={{
                                    width: {
                                        sm: '470px',
                                        xs: '100%'
                                    }
                                }}
                                header={lng[language]['cardAddHeader']}
                                desc={lng[language]['cardAddDesc']}
                                imageUrl={"https://hyper-active.com/images/content/GiftCard.png"}
                            />
                        </Box>

                        <Box className={["container"]}>

                            <Box sx={containerSpacing}>
                                <Text
                                    containerStyling={{ marginBottom: '50px' }}
                                    header={karting[language]['safetyVid']}
                                />
                                <VideoWrapper
                                    imgUrl={'https://hyper-active.com/images/hyperdrive/other/Mask Group 83.png'}
                                    CompetitionRulesTitle={karting[language]['safetyVidDesc']}
                                    videoUrl={'https://www.youtube.com/watch?v=YYVVRoIWAVI'}
                                />
                            </Box>

                            {
                                kartingData[11] &&
                                <Box sx={containerSpacing}>

                                    <TextWrapper
                                        title={kartingData[11].wrapper_header}
                                    />
                                    <VideoWrapper
                                        imgUrl={constants.imgPath + kartingData[11].images}
                                        CompetitionRulesTitle={kartingData[11].body_header}
                                        CompetitionRulesDesc={kartingData[11].body_desc}
                                        videoUrl={kartingData[11].link}
                                    />
                                </Box>

                            }
                            {
                                kartingData[9] &&
                                <Box sx={containerSpacing}>
                                    <GeneralLayout data={kartingData[9]} 
                                        specialStyling={{
                                            backgroundColor: 'transparent'
                                        }}
                                        txtContainerStyling={{
                                            height: {
                                                md: '290px',
                                                xs: '80%'
                                            },
                                            marginY: 'auto',
                                        }} 
                                    />
                                </Box>
                            }

                        </Box>

                        <Box sx={containerSpacing} ref={birthday}>
                            <PartyBanner
                                header={karting[language]['partyKarting']}
                                desc={karting[language]['partyKartingDesc']}
                                buttonTxt={lng[language]['hyperBirthdayBtn']}
                                link={"/activities/party"}
                                imageUrl={"https://hyper-active.com/images/hyperdrive/other/bIRTHDAY.png"}
                                containerStyle={{
                                    width: { sm: "454px", xs: '90%' },
                                    color: "#FFF",
                                    background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                                    padding: 3,
                                    borderRadius: "40px",
                                    display: "grid",
                                    gridTemplateRows: "1fr 70px 60px",
                                    rowGap: "20px",
                                }}
                            />
                        </Box>

                        <Box className={['container']}>
                                <Box csx={containerSpacing}>
                                    <AppBanner
                                        header={lng[language]['hyperAppHeader']}
                                        desc={lng[language]['hyperAppDesc']}
                                        imgUrl={'../images/6582cf3c6d165.webp'}
                                    />
                                </Box>

                            <ReserveText header={karting[language]['rezRace']}
                                // desc=''
                                id={739676}
                            />

                            <Box paddingBottom={5} sx={containerSpacing}>
                                <Box sx={{'h1': {textAlign: 'center'}}}>
                                    <TextWrapper title={lng[language]['gameSuggestion']} />
                                </Box>
                                <RandomCards removeGame='HyperDrive' />
                            </Box>
                        </Box>
                    </>
                    :
                    <WaitingLoader open={loading} />
            }
        </Box>
    );
}
export default Karting;
