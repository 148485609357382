import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import lng from "../../../utils/language";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
import Grid from "../../../layout/grid/Grid";
import BigCards from "../../../components/card/BigCard";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
import React, { useRef } from "react";
import { Box } from "@mui/material";
import RandomCards from "../../../components/card/RandomCards";
import GalleryBox from "../../../components/gallery/Gallery";
import GridBox from "../../../layout/grid/GridBox";
import GeneralLayout from "../../../layout/activities/GeneralLayout";
// import { useState } from "react";
import { vr } from "../../../utils/gameLng";
import FetchData from "../../../api/FetchData";
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
import Text from "../../../components/text/Text";
import CarouselGallery from "../../../components/carousel/CarouselGallery";

function Virtual() {
    const { language } = useSelector((state) => state.language);

    const games = useRef(null);
    // const gamePreparation = useRef(null);
    // const rules = useRef(null);
    const gallery = useRef(null);

    // const reverse = (state, setstate) => {
    //     setstate(!state);
    // }
    const [gameData, setGameData] = React.useState();
    const [loading, setLoading] = React.useState(true)

    const load = () => {
        FetchData.create('/general/page', {
            id: 4,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setGameData(res.data.data)
                setLoading(false);
            })
            .catch(err => {
            })
    }

    React.useEffect(() => {
        load()
        // eslint-disable-next-line
    }, [language])

    const containerSpacing = {
        paddingY: '80px'
    }

    const containerBottomSpacing = {
        paddingBottom: '80px'
    }

    const interest = {
        eng: {
            interest: [
                {
                    interest: 'Games',
                    ref: games
                },
                {
                    interest: 'Gallery',
                    ref: gallery
                },
            ]
        },
        alb: {
            interest: [
                {
                    interest: 'Lojërat',
                    ref: games
                },
                {
                    interest: 'Galeria',
                    ref: gallery
                },
            ]
        }
    }

    return (
        loading === false ?
            <Box className={style.detailsWrapper}>
                <Box className="container">
                    {
                        gameData[1] &&
                        <ActivitiesBanner
                            title={gameData[1].body_header}
                            desc={gameData[1].body_desc}
                            image={constants.imgPath + gameData[1].images}
                            linkPrice="karting"
                            vidId="GWG6PBDRO7A"
                            id={gameData[1].link}
                            prices={true}
                        />
                    }
                   
                    <Box sx={[containerBottomSpacing,{marginTop: {
                            xs: "100px",
                            sm: 20,
                            md: 0,
                        },}]}>
                        <ActivitiesInterest interests={interest[language].interest } />
                    </Box>

                    <Box
                        sx={[{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }, containerSpacing]}
                    >
                        <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                        <Videos id={'GWG6PBDRO7A'} />
                    </Box>

                    <Box sx={containerSpacing} ref={games}>
                        <Text
                            containerStyling={{
                                marginBottom: '80px'
                            }}
                            header={lng[language]['gameTypes']}
                            desc={vr[language]['gameDesc']}
                        />
                        {
                            gameData[8] &&
                            <Grid no={2}>
                                {gameData[8].map((i, j) => {
                                    return <BigCards data={i} key={j} />;
                                })}
                            </Grid>
                        }
                    </Box>
                </Box>

                {
                    gameData[6] &&
                    <Box sx={{ marginTop: 10 }} ref={gallery}>
                        <CarouselGallery gallery={gameData[6][0]['images']} radius={5} />
                    </Box>
                }

                <Box className="container">
                    {
                        gameData[7] &&
                        <Box className="container" sx={{ marginTop: 10 }} ref={gallery}>
                            <TextWrapper
                                title={gameData[7].wrapper_header}
                            />
                            <GridBox
                                gap={2}
                                gridTemplateColumns={
                                    {
                                        xs: 'repeat(2, 1fr)',
                                        sm: 'repeat(3, 1fr)',
                                        md: 'repeat(4, 1fr)'
                                    }
                                }
                                gridTemplateRows={{
                                    xs: 'repeat(3, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(2, 1fr)'
                                }}
                            >
                                <GalleryBox galleryImages={gameData[7].images} />
                            </GridBox>
                        </Box>
                    }
                </Box>
                <Box className="container">
                    {
                        gameData[9] &&
                        <Box className={style.wrapper}>
                            <GeneralLayout data={gameData[9]} specialStyling={{
                                backgroundColor: 'transparent',
                                height: '420px'
                            }}
                                txtContainerStyling={{
                                    height: {
                                        md: '290px',
                                        xs: '50%'
                                    },
                                    marginY: 'auto',
                                    '.MuiTypography-p': {
                                        fontSize: '16px'
                                    }
                                }} />
                        </Box>
                    }
                    {
                        gameData[3] &&
                        <Box className={style.wrapper} sx={{paddingTop: '50px'}}>
                            <AppBanner
                                header={lng[language]['hyperAppHeader']}
                                desc={lng[language]['hyperAppDesc']}
                                imgUrl={'../images/6582cf3c6d165.webp'}
                            />
                        </Box>
                    }
                    <Box paddingBottom={5} className={style.wrapper} sx={containerSpacing}>
                        
                        <Box sx={{ marginBottom: 5, 'h1':{ textAlign: 'center !important'}}}>
                            <TextWrapper title={lng[language]['gameSuggestion']} />
                        </Box>
                        <RandomCards removeGame='Virtual Reality' />
                    </Box>
                    <TextWrapper
                        desc={vr[language]['noRes']}
                    />
                </Box>

            </Box>
            :
            <WaitingLoader open={loading} />
    );
}
export default Virtual;