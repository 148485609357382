// import style from "./CardTopUp.module.css";
import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { api } from "../../../api/config";
import TopUpCard from "../../../components/card/TopUpCard";
import {MdOutlineArrowBackIos} from "react-icons/md";

function CardTopUp() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    api
      .get("card/getCard")
      .then((res) => {
        // console.log("RES DATAs", res);


        if (!res.data.error) {
          setCards(res.data.cards);
        }
        //Check message types
        // if (res.data.success) {
        //   setCards((prevState) => ({
        //     ...prevState,
        //     time: res.data.params[0].minTime,
        //     adult: Number(res?.data.params[0].minValue) === 1 ?  0 : Number(res?.data.params[0].minValue),
        //     min: res.data.params[0].minTime,
        //     max: res.data.params[0].maxTime,
        //     name: res.data.params[0].name,
        //     timeStepValue: res.data.params[0].timeStepValue,
        //     pickPlayerData: res.data.params[0].pickPlayerData,
        //   }));

        //   setReservationParams(res?.data.params);
        //   setLoading(false);
        // } else setLoading(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

  return (
    <>
      <div className="container">
        <Link to="/reservation" className="bckButton">
          <MdOutlineArrowBackIos color="#fff"/>
        </Link>
      </div>
      <div className="pbt50 subContainer">
        <Swiper
          navigation
          pagination={false}
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          slidesPerView={3}
          centeredSlides
          style={{ height: "500px" }}
        >
          {cards.map((i, j) => {
            return (
              <SwiperSlide key={j}>
                <TopUpCard
                  id={i.cardId}
                  balance={i.CashBalance}
                  name={i.contractName}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default CardTopUp;
