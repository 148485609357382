/* global fbq */
export const initFacebookPixel = (pixelId) => {
    if (!pixelId) {
        // console.error('Pixel Id is required');
        return;
    }

    // Prevent re-initialization of the Pixel script
    if (window.fbq) {
        // console.log('Facebook Pixel is already initialized');
        return;
    }
    (function (f, b, e, v, n, t, s) {
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    fbq("init", pixelId);
    fbq("track", "PageView");
};

export const trackCustomEvent = (eventName, params = {}) => {
    if (typeof fbq === "function") {
        // console.log(`Tracking custom event: ${eventName}`, params); // Add log for debugging
        fbq("track", eventName, params);
    } else {
        // console.warn('Facebook Pixel is not initialized');
    }
}