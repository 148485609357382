import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, ButtonGroup, Typography } from '@mui/material';

export default function TabsComp(props) {

    const [value, setValue] = React.useState(0);
  
    const handleChange = (event) => {
      setValue(event);
    };

    return (
      <Box sx={[{width: '100%', typography: 'body1'}, props.style]}>
            <ButtonGroup 
                variant="contained" 
                aria-label="outlined primary button group" 
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box component={'ul'} sx={{
                    // display: 'grid',
                    // gridTemplateColumns: {
                    //     xl: 'repeat(6, 1fr)'
                    // },
                    // justifyContent: 'center',
                    // alignItems: 'center'
                    justifyContent: 'center',
                    paddingX: '0',
                    display: 'flex',
                    overflow: 'scroll',
                    paddingBottom: '30px',
                }}>
                {
                    props.test &&
                    props.test.map((data, index) => (
                        //eslint-disable-next-line
                        <Box component='li' key={index} marginLeft={index === 0 ? '0' : '10px'} marginRight={index == props.test.length ? '0' : '10px'} sx={{listStyle: 'none', justifySelf: 'center', minWidth: 'fit-content'}}>
                            <Button 
                                value={index} 
                                onClick={(e) => handleChange(e.target.value)}
                                //eslint-disable-next-line
                                aria-selected={value == index  ? true : false}
                                sx={{
                                    backgroundColor: 'transparent',
                                    borderRadius: '20px !important',
                                    border: 'solid 1px #F03C44 !important',
                                    "&:hover": {
                                        backgroundColor: '#F03C44'
                                    },
                                    "&[aria-selected=true]": {
                                        backgroundColor: '#F03C44'
                                    }
                                }}
                            >
                                {data.tabHeader}
                            </Button>
                        </Box>
                    ))
                }
                </Box>
                {
                    props.test &&
                    props.test.map((data, index) => (
                        //eslint-disable-next-line
                        <Box component='li' key={index} sx={{display: value == index ? 'block' : 'none', marginTop: '30px'}}>
                            <Box
                                className='tabItemCocktailContainer'
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        md: 'repeat(4, 1fr)',
                                        sm: 'repeat(3, 1fr)',
                                        xs: 'repeat(2, 1fr)'
                                    }
                                }}
                            >
                                {
                                    data.tabData.map((data, index) => (
                                        <Box 
                                            className='tabItemCocktail'
                                            key={index} 
                                            component={'div'}
                                            sx={{
                                                border: '1px solid #fff',
                                                height: '180px',
                                                gap: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {/* {data.imageUrl && <Box sx={{width: '100%', objectFit: 'contain', height: '100%'}} component={'img'} src={data.imageUrl}></Box>} */}
                                            {data.title && <Typography className='cocktailHeader' fontWeight={700}>{data.title}</Typography>}
                                            {data.type && <Typography className='coctailDesc' component={'p'} textAlign={'center'} sx={{whiteSpace: 'pre-line'}} variant='body2'>{data.type}</Typography>}
                                            {data.price && <Typography className='price'>&euro;{data.price}</Typography>}
                                        </Box>       
                                    ))
                                }
                            </Box>
                        </Box>
                    ))
                }
            </ButtonGroup>
      </Box>
    );
}