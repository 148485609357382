import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import FetchData from '../api/FetchData';
export default function MajorError() {

    const handleRetry = () => {FetchData.getAll('/auth/createToken')}

    return (
        <Box height={'100vh'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>

            <Box>
                <Typography variant="h4" textAlign={'center'} color={'#FFF'} marginBottom={'20px'}>
                    Uebfaqja Hyperactive është aktualisht nën mirëmbajtje! <br />
                    Do të kthehemi së shpejti.
                </Typography>
                <Typography variant="h4" textAlign={'center'} color={'#FFF'} marginBottom={'20px'}>
                    Hyperactive Web page is currently under maintenance! <br />
                    We'll be back soon.
                </Typography>
            </Box>

            <Box>
                <Button variant='contained' sx={{background: '#FFF', color: '#af46fa', ':hover': {background: '#af46fa', color: '#FFF'}, borderRadius: '16px', marginBottom: '20px'}} LinkComponent={Link} to='/' onClick={() => handleRetry()}>
                    Retry Connection
                </Button>
            </Box>

            <Box>
                <Box component={'img'} src='https://hyper-active.com/images/2024/pageUnderMaintnance.svg' />
            </Box>

        </Box>
    )
}